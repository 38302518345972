import { ProDescriptions } from '@ant-design/pro-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { API_URL, Rresource_Path } from '../../constants';
import { Checkbox, Form, Input, Modal, SelectProps } from 'antd';
import { ChangePasswordInput, Configuration, ConfigurationParameters, IdentityUserDto, IdentityUserUpdateDto, PartyApi, PartyDto, UserApi } from '../../api/najd';
import { RuleObject } from 'antd/lib/form';


interface UserAccountFormProps {
  party_id: any;
  open: boolean;
  onCreate: () => void;
  onCancel: () => void;
}

interface Values {
  title: string;
  description: string;
  modifier: string;
}


export const UserAccountForm: React.FC<UserAccountFormProps> = ({ party_id, open, onCreate, onCancel }) => {
  const [formData, setUserData] = useState<IdentityUserDto>({});
  const [form] = Form.useForm();
  const auth = useAuth();
  const { t } = useTranslation();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const partyApi = new PartyApi(configuration);
  const userApi = new UserApi(configuration);


  useEffect(() => {
    const fetchUserData = async () => {
      userApi.userGet(party_id)
        .then(response => {
          setUserData(response.data);
        })
        .catch(error => {
          // Handle the API error
          console.error(error);
          setUserData({});
        });

    };
    fetchUserData();
  }, [party_id]); // Add refreshTrigger as a dependency

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);

  const userNameValidToUse = async (_: RuleObject, value: string) => {
    try {
      if (value === null || value.length < 6) {
        return Promise.reject(t('Not Valid'));
      }
      const response = await partyApi.partyIsUserNameValidToUse(value, party_id);
      const isValid = response.data;
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Already taken'));
      }
    } catch (error) {
      return Promise.reject(t('Failed to validate'));
    }
  };
  return (
    <Modal

      open={open}
      title={t("User Account")}
      okText={t("Submit")}
      cancelText={t("Cancel")}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {

            partyApi.partyDeleteUser(party_id)
              .then((reesult) => {

                partyApi.partySetUserAcccount(party_id, values.userName)
                  .then(response => {
                    form.resetFields();
                    onCreate();
                  })
                  .catch(error => {
                    // Handle the API error
                    console.error(error);
                  });

              })
              .catch((info) => {

                partyApi.partySetUserAcccount(party_id, values.userName)
                  .then(response => {
                    form.resetFields();
                    onCreate();
                  })
                  .catch(error => {
                    // Handle the API error
                    console.error(error);
                  });


              });

          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={formData}
      >
        <Form.Item name="id" label={t("id")} hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="userName" label={t("Username")}
          rules={[
            { required: true, message: t('Required field') ?? '' },
            { validator: userNameValidToUse },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};