import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./i18n";
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import arEa from 'antd/locale/ar_EG';
import { API_URL, Auth_client_id } from './constants';

const oidcConfig = {
  userStore: new WebStorageStateStore({
    store: localStorage
  }),
  authority: API_URL,//caltalogs
  client_id: Auth_client_id,
  redirect_uri: window.location.origin,// + '/signin-callback',
  post_logout_redirect_uri: window.location.origin, // (string): The OIDC post-logout redirect URI.
  scope: "offline_access openid email phone address roles profile App",
  // automaticSilentRenew: true,
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <ConfigProvider locale={enUS}
        theme={{
          token: {
            colorPrimary: '#397551',
          },
        }}>
        <App />
      </ConfigProvider>
    </AuthProvider>
  // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
