import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch, Upload, message } from 'antd';
import { API_URL, API_URL_Upload, Rresource_Path } from "../../constants";
import { useAuth } from "react-oidc-context";
import { CityApi, Configuration, ConfigurationParameters, CountryApi, LocationApi, LocationDto, PartyApi, PartyDto, ProfileApi, ProfileDto } from "../../api/najd";
import { UploadOutlined } from "@ant-design/icons";
import { RuleObject } from "antd/es/form";
import dayjs from "dayjs";

interface FormDrawerProps {
  party_id: string;
}

export const ProfileParty: React.FC<FormDrawerProps> = ({ party_id }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth.user?.access_token;

  const [formData, setUserData] = useState<PartyDto>({});
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  const [cityOptions, setCityOptions] = useState<SelectProps['options']>([]);
  const [countryOptions, setCountryOptions] = useState<SelectProps['options']>([]);
  const [locationOptions, setLocationOptions] = useState<LocationDto[]>([]);
  const [citylocationOptions, setCityLocationOptions] = useState<SelectProps['options']>([]);

  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);

  const locationApi = new LocationApi(configuration);
  const partyApi = new PartyApi(configuration);
  const countryApi = new CountryApi(configuration);
  const cityApi = new CityApi(configuration);

  /***start get options */
  useEffect(() => {
    fetchCities()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setCityOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
    fetchCountries()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ fullName, id }) => (
          {
            label: fullName,
            value: id
          }
        ));
        setCountryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    // Fetch the select options from the API
    fetchLocationOptions()
      .then((response) => {
        setCityLocationOptions(response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        )));
        setLocationOptions(response ?? []);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

  }, []); // Run this effect only once when the component mounts

  const fetchCities = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return cityApi.cityGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const handleCityChange = (value: string) => {
    const filteredList = locationOptions?.filter((x: LocationDto) => x.city_Id === value);
    console.log('==> handleValuesChange', filteredList);
    setCityLocationOptions(filteredList.map(({ name, id }) => (
      {
        label: name,
        value: id
      }
    )));
  };
  const fetchCountries = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return countryApi.countryGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchLocationOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationApi.locationGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */
  useEffect(() => {
    console.log(token);
    // setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await partyApi.partyGet(party_id);
        var rowData: any = response.data;
        if (rowData.birthDate != null)
          rowData.birthDate = dayjs(rowData.birthDate);

        setUserData(response.data);
        // setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []); // Add refreshTrigger as a dependency

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {

      values.file1 = normlizeFileVariable(values.file1);
      values.file2 = normlizeFileVariable(values.file2);
      values.file3 = normlizeFileVariable(values.file3);
      values.file4 = normlizeFileVariable(values.file4);

      if (values.birthDate != null)
        values.birthDate = dayjs(values?.birthDate).format("YYYY-MM-DD")

      partyApi.partyUpdate(party_id, values)
        .then(response => {
          // Handle the API response
          message.success(t('Submit Successfully'));
          console.log(response.data);
        })
        .catch(error => {
          // Handle the API error
          console.error(error);
          message.error(error);
        });

    });
  };

  function normlizeFileVariable(value: null | string | any): string | null {
    if (value === null) {
      return null;
    } else if (typeof value === "string") {
      // Variable is a string
      return value;
    } else if (typeof value === "object") {
      // Variable is an object
      return value.name;
    } else {
      return value;
    }
  }

  const renderFileLink = (filePath: any) => {
    if (filePath != null && filePath != '') {
      return (
        <a href={Rresource_Path + filePath} target="_blank" rel="noopener noreferrer">
          {t('Download File')}
        </a>
      );
    }
    return null;
  };
  const validatePhoneNumber = async (_: RuleObject, value: string) => {
    try {
      const response = await partyApi.partyIsValidPhoneToUse(value, formData.id);
      const isValid = response.data;
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Already Taken'));
      }
    } catch (error) {
      return Promise.reject(t('Failed to validate'));
    }
  };
  const validateIdentifier = async (_: RuleObject, value: string) => {
    try {
      const response = await partyApi.partyIsValidIdentifierToUse(value, formData.id);
      const isValid = response.data;
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Already taken'));
      }
    } catch (error) {
      return Promise.reject(t('Failed to validate'));
    }
  };

  const getValueFromEvent = (e: any) => {
    if (e && e.file) {
      return e.file;
    }
    return e && e.target && e.target.value;
  };

  const beforeUpload = (file: File) => {
    // Generate a timestamp for the file name
    const timestamp = Date.now();
    const newFileName = `${timestamp}${file.name.substring(file.name.lastIndexOf('.'))}`;

    // Modify the file object to use the new file name
    Object.defineProperty(file, 'name', {
      writable: true,
      value: newFileName,
    });

    // Add the timestamp to the form values
    const formValues = {
      file,
      timestamp,
    };

    // Update the form values before submitting
    form.setFieldsValue(formValues);

    return true;
  };

  return (
    <Form layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={formData} >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="id" label={t("ID")} hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="serial" label="Serial" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="partyTypeCode" label="PartyTypeCode" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="code" label="Code" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="name" label={t("Name")} rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="middleName" label={t("Middle Name")} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="lastName" label={t("Last Name")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="phoneNumber"
            label={t("Phone Number")}
            rules={[
              {
                required: true, message: t('Required field') ?? '',
                pattern: new RegExp(/^[0-9]+$/)
              },
              { validator: validatePhoneNumber },
            ]} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="email" label={t("Email")} rules={[{ type: 'email', message: t('is not a valid email') ?? '' }]} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="country_Id"
            label={t("Nationality")}
          >
            <Select
              options={countryOptions} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="identifier" label={t("Identifier")}
            rules={[
              { required: true, message: t('Required field') ?? '' },
              { validator: validateIdentifier },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="birthDate" label={t("Birth Date")} >
            {/* <ConfigProvider locale={locale}> */}
            <DatePicker format={"YYYY-MM-DD"} />
            {/* </ConfigProvider> */}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="relative" label={t("Relative")} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="relativePhoneNumber" label={t("Relative Phone Number")} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="relativeIdentifier" label={t("Relative Identifier")} >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="city_Id"
            label={t("City")}
            rules={[{ required: false, message: 'Please select one or more' }]}
          >
            <Select
              options={cityOptions} onChange={handleCityChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="location_Id"
            label={t("Locations")}
            rules={[{ required: false, message: 'Please select one or more' }]}
          >
            <Select
              options={citylocationOptions} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="status"
            label={t("Status")}
            rules={[{ required: false, message: 'Please select one or more' }]}
          >
            <Select
              options={[
                { value: 'تسجيل جديد', label: t('تسجيل جديد') },
                { value: 'مفعل', label: t('مفعل') },
                { value: 'موافقة', label: t('موافقة') },
                { value: 'طلب تغير مركز صجي', label: t('طلب تغير مركز صجي') },
                { value: 'ايقاف', label: t('ايقاف') },
              ]} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="address" label={t("Address")}>
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            name='file1'
            label={t('Salary Statement')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: false, message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file1)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='file2'
            label={t('Rental Contract')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: false, message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file2)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='file3'
            label={t('Patient Identity')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: false, message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file3)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='file4'
            label={t('Medical Record')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: false, message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file4)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form >
  );
};