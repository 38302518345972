/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { AsnaniDto } from '../../../com/najd/models';
// @ts-ignore
import { AsnaniItemDto } from '../../../com/najd/models';
// @ts-ignore
import { CreateAsnaniDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfAsnaniDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfAsnaniItemDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
// @ts-ignore
import { UpdateAsnaniDto } from '../../../com/najd/models';
// @ts-ignore
import { UpdateAsnaniItemDto } from '../../../com/najd/models';
// @ts-ignore
import { UploadReportAsnaniDto } from '../../../com/najd/models';
/**
 * AsnaniApi - axios parameter creator
 * @export
 */
export const AsnaniApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAsnaniDto} [createAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniCreate: async (createAsnaniDto?: CreateAsnaniDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/asnani`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAsnaniDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/asnani`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniDeleteItem: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/asnani/delete-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('asnaniGet', 'id', id)
            const localVarPath = `/api/asnani/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [asnaniId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniGetItemList: async (filterKey?: string, fromDate?: string, toDate?: string, asnaniId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/asnani/get-item-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filterKey !== undefined) {
                localVarQueryParameter['FilterKey'] = filterKey;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (asnaniId !== undefined) {
                localVarQueryParameter['Asnani_Id'] = asnaniId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [locationId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniGetList: async (locationId?: string, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/asnani`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} asnaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniSubmitReport: async (asnaniId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'asnaniId' is not null or undefined
            assertParamExists('asnaniSubmitReport', 'asnaniId', asnaniId)
            const localVarPath = `/api/asnani/submit-report/{asnani_id}`
                .replace(`{${"asnani_id"}}`, encodeURIComponent(String(asnaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAsnaniDto} [updateAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniUpdate: async (id: string, updateAsnaniDto?: UpdateAsnaniDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('asnaniUpdate', 'id', id)
            const localVarPath = `/api/asnani/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAsnaniDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UploadReportAsnaniDto} [uploadReportAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniUploadReport: async (id: string, uploadReportAsnaniDto?: UploadReportAsnaniDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('asnaniUploadReport', 'id', id)
            const localVarPath = `/api/asnani/upload-report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadReportAsnaniDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} asnaniItemId 
         * @param {UpdateAsnaniItemDto} [updateAsnaniItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniUploadReportItem: async (asnaniItemId: string, updateAsnaniItemDto?: UpdateAsnaniItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'asnaniItemId' is not null or undefined
            assertParamExists('asnaniUploadReportItem', 'asnaniItemId', asnaniItemId)
            const localVarPath = `/api/asnani/upload-report-item/{asnaniItem_Id}`
                .replace(`{${"asnaniItem_Id"}}`, encodeURIComponent(String(asnaniItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAsnaniItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsnaniApi - functional programming interface
 * @export
 */
export const AsnaniApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AsnaniApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAsnaniDto} [createAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniCreate(createAsnaniDto?: CreateAsnaniDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsnaniDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniCreate(createAsnaniDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniDeleteItem(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniDeleteItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsnaniDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [asnaniId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniGetItemList(filterKey?: string, fromDate?: string, toDate?: string, asnaniId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfAsnaniItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniGetItemList(filterKey, fromDate, toDate, asnaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [locationId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniGetList(locationId?: string, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfAsnaniDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniGetList(locationId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} asnaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniSubmitReport(asnaniId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsnaniDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniSubmitReport(asnaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAsnaniDto} [updateAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniUpdate(id: string, updateAsnaniDto?: UpdateAsnaniDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsnaniDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniUpdate(id, updateAsnaniDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UploadReportAsnaniDto} [uploadReportAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniUploadReport(id: string, uploadReportAsnaniDto?: UploadReportAsnaniDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsnaniDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniUploadReport(id, uploadReportAsnaniDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} asnaniItemId 
         * @param {UpdateAsnaniItemDto} [updateAsnaniItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async asnaniUploadReportItem(asnaniItemId: string, updateAsnaniItemDto?: UpdateAsnaniItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsnaniItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.asnaniUploadReportItem(asnaniItemId, updateAsnaniItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AsnaniApi - factory interface
 * @export
 */
export const AsnaniApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AsnaniApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAsnaniDto} [createAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniCreate(createAsnaniDto?: CreateAsnaniDto, options?: any): AxiosPromise<AsnaniDto> {
            return localVarFp.asnaniCreate(createAsnaniDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.asnaniDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniDeleteItem(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.asnaniDeleteItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniGet(id: string, options?: any): AxiosPromise<AsnaniDto> {
            return localVarFp.asnaniGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [asnaniId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniGetItemList(filterKey?: string, fromDate?: string, toDate?: string, asnaniId?: string, options?: any): AxiosPromise<ListResultDtoOfAsnaniItemDto> {
            return localVarFp.asnaniGetItemList(filterKey, fromDate, toDate, asnaniId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [locationId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniGetList(locationId?: string, year?: number, options?: any): AxiosPromise<ListResultDtoOfAsnaniDto> {
            return localVarFp.asnaniGetList(locationId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} asnaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniSubmitReport(asnaniId: string, options?: any): AxiosPromise<AsnaniDto> {
            return localVarFp.asnaniSubmitReport(asnaniId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAsnaniDto} [updateAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniUpdate(id: string, updateAsnaniDto?: UpdateAsnaniDto, options?: any): AxiosPromise<AsnaniDto> {
            return localVarFp.asnaniUpdate(id, updateAsnaniDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UploadReportAsnaniDto} [uploadReportAsnaniDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniUploadReport(id: string, uploadReportAsnaniDto?: UploadReportAsnaniDto, options?: any): AxiosPromise<AsnaniDto> {
            return localVarFp.asnaniUploadReport(id, uploadReportAsnaniDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} asnaniItemId 
         * @param {UpdateAsnaniItemDto} [updateAsnaniItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        asnaniUploadReportItem(asnaniItemId: string, updateAsnaniItemDto?: UpdateAsnaniItemDto, options?: any): AxiosPromise<AsnaniItemDto> {
            return localVarFp.asnaniUploadReportItem(asnaniItemId, updateAsnaniItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsnaniApi - object-oriented interface
 * @export
 * @class AsnaniApi
 * @extends {BaseAPI}
 */
export class AsnaniApi extends BaseAPI {
    /**
     * 
     * @param {CreateAsnaniDto} [createAsnaniDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniCreate(createAsnaniDto?: CreateAsnaniDto, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniCreate(createAsnaniDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniDelete(id?: string, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniDeleteItem(id?: string, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniDeleteItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniGet(id: string, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filterKey] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [asnaniId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniGetItemList(filterKey?: string, fromDate?: string, toDate?: string, asnaniId?: string, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniGetItemList(filterKey, fromDate, toDate, asnaniId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [locationId] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniGetList(locationId?: string, year?: number, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniGetList(locationId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} asnaniId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniSubmitReport(asnaniId: string, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniSubmitReport(asnaniId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAsnaniDto} [updateAsnaniDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniUpdate(id: string, updateAsnaniDto?: UpdateAsnaniDto, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniUpdate(id, updateAsnaniDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UploadReportAsnaniDto} [uploadReportAsnaniDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniUploadReport(id: string, uploadReportAsnaniDto?: UploadReportAsnaniDto, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniUploadReport(id, uploadReportAsnaniDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} asnaniItemId 
     * @param {UpdateAsnaniItemDto} [updateAsnaniItemDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsnaniApi
     */
    public asnaniUploadReportItem(asnaniItemId: string, updateAsnaniItemDto?: UpdateAsnaniItemDto, options?: AxiosRequestConfig) {
        return AsnaniApiFp(this.configuration).asnaniUploadReportItem(asnaniItemId, updateAsnaniItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}
