import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { DatePicker, Radio, SelectProps, Space } from "antd";
import { ChartStaticsDto, CityApi, CityDto, Configuration, ConfigurationParameters, DocumentApi, ItemApi, LocationApi, LocationDto, LocationGroupApi, ReportApi, ReportFilterDto, SubdivisionApi, SubdivisionDto } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

export default () => {

  const { t, i18n } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  // const [LocationData, setLocationData] = useState<CityDto[]>([]);
  const [groupBy, setGroupBy] = useState<string>('Subdivision');
  const [date_range_by, setdate_range_by] = useState<string>('post_to_supplier_time');
  const auth = useAuth();
  const [locationGrouplist, setlocationGrouplist] = useState<SelectProps['options']>([]);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);
  const subdivisionApi = new CityApi(configuration);
  const locationGroupApi = new LocationGroupApi(configuration);

  /***end get options */

  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };

  const [dataGrouped, setdataGrouped] = useState<GroupedDataItem[]>([]);
  type GroupedDataItem = {
    date: string,
    data: {
      [subdivision: string]: number
    }
  };

  type GroupedData = GroupedDataItem[];
  /***start get options */
  // useEffect(() => {
  //   // Fetch the select options from the API
  //   fetchOptions()
  //     .then((response) => {
  //       setLocationData(response ?? [])
  //     })
  //     .catch((error) => {
  //       console.error('Failed to fetch select options:', error);
  //     });
  // }, []); // Run this effect only once when the component mounts

  // const fetchOptions = () => {
  //   // Implement your API call here
  //   // Return a Promise that resolves with the options data
  //   return subdivisionApi.cityGetList()
  //     .then((response) => response.data)
  //     .then((data) => data.items);
  // };
  /***end get options */

  useEffect(() => {
    if (selectedDateRange != null) {
      getData();
    }

  }, [selectedDateRange, groupBy,date_range_by]); // Run this effect only once when the component mounts

  useEffect(() => {
   
    fetchlocationGroups()
    .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, code }) => (
          {
            label: name,
            value: code
          }
        ));
        setlocationGrouplist(mapedOptions);
      })
    .catch((error) => {
        console.error('Failed to fetch select options:', error);
        setlocationGrouplist([]);
    });

    
  }, []);

  
  const fetchlocationGroups = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationGroupApi.locationGroupGetList()
        .then((response) => response.data)
        .then((data) => data.items);
};

  const getData = () => {
    const reportFilterDto: ReportFilterDto = {
      fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
      toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD'),
      dateRangeBy: date_range_by
    };
    reportApi.reportPatientSalesByCityV2(
      reportFilterDto
    )
      .then((response) => {
        setData(response.data);
        var dd = groupByDateAndSubdivision(response.data);
        console.log(dd);
        setdataGrouped(dd);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };


  function groupByDateAndSubdivision(records: ChartStaticsDto[]): GroupedData {
    const tempData: {
      [date: string]: {
        [subdivision: string]: number
      }
    } = {};

    for (const record of records) {
      const date = record.date;
      const subdivision = record.name || 'Unknown';  // Fallback to 'Unknown' if subdivision_Name is null or undefined

      if (!tempData[date!]) {
        tempData[date!] = {};
      }

      if (!tempData[date!][subdivision]) {
        tempData[date!][subdivision] = 0;
      }

      tempData[date!][subdivision] += record.value!;
    }

    const groupedData: GroupedData = Object.entries(tempData).map(([date, data]) => ({
      date,
      data
    }));

    return groupedData;
  }

  const COLORS = [colorPrimary, colorSecondory, '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F02242', '#075242', '#375242'];
  function getName(name: string): any {
    if (groupBy === 'LocationGroup') {
      const filteredItems = locationGrouplist!.filter(x => x.value === name);
      if(filteredItems.length > 0)
        return filteredItems![0].label;
      return '';
    } else {
      return name;
    }
  }
  return (
    <div style={{ width: '100%', height: 600 }}>
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            direction={i18n.dir()}
            style={{ minWidth: '300px' }}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />

          {/* <Radio.Group defaultValue={groupBy} buttonStyle="solid" >
            <Radio.Button value="Subdivision" onClick={() => { setGroupBy("Subdivision"); }}>{t("Subdivision")}</Radio.Button>
            <Radio.Button value="City" onClick={() => { setGroupBy("City"); }}>{t("City")}</Radio.Button>
            <Radio.Button value="LocationGroup" onClick={() => { setGroupBy("LocationGroup"); }}>{t("Location")}</Radio.Button>
          </Radio.Group> */}
          <Radio.Group defaultValue={date_range_by} buttonStyle="solid" >
            <Radio.Button value="post_to_supplier_time" onClick={() => { setdate_range_by("post_to_supplier_time"); }}>{t("حسب تاريخ امر الشراء")}</Radio.Button>
            <Radio.Button value="status_time" onClick={() => { setdate_range_by("status_time"); }}>{t("حسب تاريخ التسليم")}</Radio.Button>
          </Radio.Group>
        </Space>
      </div>
      <div style={{ width: '100%', height: 500 }}>
        <h2 style={{ textAlign: "center" }}>عدد المرضى المستلمين لكل مركز</h2>
        {data.length > 0 ?
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={dataGrouped}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dateStr" />
              <YAxis />
              <Tooltip />
              {data.map((entry, index) => (
                <Bar name={`${getName(entry.name ?? '')}`} dataKey={`data.${entry.name}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <Legend />
            </BarChart>
          </ResponsiveContainer>
          : <></>
        }
      </div>
    </div>

  );

};