import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import { AsnaniApi, AsnaniDto, Configuration, ConfigurationParameters, CreateAsnaniDto, DocumentStatusDetailsReportDto, DocumentSuppliersReportDto, ItemApi, LocationApi, LocationDto, PartyApi, PartyDto, ReportApi, ReportFilterDto, UploadReportAsnaniDto } from "../../api/najd";
import { API_URL, API_URL_Upload } from "../../constants";
import dayjs from "dayjs";
import { useAuth } from "react-oidc-context";
import { Button, Input, Modal, Popconfirm, Select, SelectProps, Space, Table, Tag, Upload } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { getFullDateTime } from "../../helper";
import internal from "stream";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { ReportUploadItems } from "./ReportUploadItems";


interface ReportUploadProps {
    partyInfo: PartyDto;
}
export const ReportUpload: React.FC<ReportUploadProps> = ({ partyInfo }) => {
    const { t, i18n } = useTranslation();
    const [data_table, setdata_table] = useState<AsnaniDto[]>([]);
    const [locations, setlocations] = useState<LocationDto[]>([]);
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [select_id, set_select_id] = useState('');

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const itemApi = new ItemApi(configuration);
    const locationApi = new LocationApi(configuration);
    const partyApi = new PartyApi(configuration);
    const asnaniApi = new AsnaniApi(configuration);
    const [locationOptions, setLocationOptions] = useState<SelectProps['options']>([]);
    const [location_IdFilter, setlocation_IdFilter] = useState<string>(partyInfo.location_Id ?? '');
    const [openEdit, setOpenEdit] = useState(false);
    const [uploadedFile, setUploadedFile] = useState('');

    useEffect(() => {
        locationApi.locationGetList(undefined, 'asnani')
            .then((response) => {
                setLocationOptions(response.data.items!.map(({ name, id }) => (
                    {
                        label: name,
                        value: id
                    }
                )));
                setlocations(response.data.items ?? []);
                getDataTable();
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    }, []);
    useEffect(() => {
        getDataTable();
    }, [location_IdFilter]);

    const getDataTable = () => {
        if (location_IdFilter === null || location_IdFilter === undefined || location_IdFilter === '') {
            setdata_table([]);
        } else {
            asnaniApi.asnaniGetList(
                location_IdFilter,
                2024
            )
                .then((response) => {
                    setdata_table(response.data.items ?? []);
                })
                .catch((error) => {
                    console.error('Failed to fetch select options:', error);
                });
        }
    };
    function calculateDuration(startDate: Date, endDate: Date, status: any): any {
        if (status === 'تم التسليم') {
            return <span></span>;
        }
        const timeDifference = endDate.getTime() - startDate.getTime();
        const days_allow = 1;
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let color = days <= days_allow ? 'red' : 'green';
        return <Tag color={color} >
            {` ${days} يوم : ${hours} ساعة`}
        </Tag>;
    }
    const columns: ColumnsType<AsnaniDto> = [
        {
            title: 'السنة',
            dataIndex: 'year',
            key: 'year',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'الشهر',
            dataIndex: 'month',
            key: 'month',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return (
                    String(record.remark)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.year)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
                );
            },
        },
        {
            title: 'الحالة',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => <span> {record.status ?? 'لم يرفع'}</span>
        },
        {
            title: 'تعليق',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: 'تاريخ التسليم',
            dataIndex: 'postDate',
            key: 'postDate',
            render: ((date: string) => getFullDateTime(date))
        },
        {
            title: 'باقي لتسليم التقرير',
            dataIndex: 'id',
            key: 'id',
            render: ((_, record) => calculateDuration(new Date(), new Date(record.year ?? 0, record.month ?? 0 + 1, 1,), record.status)),
        },
        {
            title: t('Action'),
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    {/* <Button type="primary" icon={<EditOutlined />} title={t('Details') ?? ''} onClick={() => { handleRowClick(record) }} /> */}
                    <Button type="default" icon={<EditOutlined />}
                        key={'con-btn-' + record.id}
                        onClick={() => handelCreateOrEdit(record)}
                        hidden={new Date().getMonth() < record.month! ? true : false}
                    />
                    <span
                        hidden={record.status === 'تم التسليم' || new Date().getMonth() < record.month! ? true : false}>
                        <Upload
                            action={API_URL_Upload}
                            beforeUpload={beforeUpload}
                            onChange={(info: any) => {
                                if (info.file.status !== 'uploading') {
                                    console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                    if (record.id == null || record.id == undefined) {
                                        const createAsnaniDto: CreateAsnaniDto = {
                                            year: record.year,
                                            month: record.month,
                                            location_Id: location_IdFilter
                                        };
                                        asnaniApi.asnaniCreate(createAsnaniDto)
                                            .then(response => {
                                                // Handle the API response            
                                                console.log(response.data);
                                                set_select_id(response.data.id!);
                                                uploadFilePost(response.data.id!, uploadedFile);
                                                getDataTable();
                                            })
                                            .catch(error => {
                                                // Handle the API error
                                                console.error(error);
                                            });
                                    } else {
                                        set_select_id(record.id);
                                        uploadFilePost(record.id, uploadedFile);
                                    }
                                } else if (info.file.status === 'error') {
                                    // message.error(`${info.file.name} file upload failed.`);
                                }

                            }}
                            maxCount={1}
                            method="post">
                            <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
                        </Upload>

                    </span>
                    <Popconfirm
                        title={t("Are you sure")}
                        onConfirm={() => handelSubmitReport(record.id!)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" icon={<EditOutlined />}
                            key={'submit-btn-' + record.id}
                            hidden={record.id == null || record.status === 'تم التسليم' ? true : false}
                        >
                            تسليم التقرير
                        </Button>
                    </Popconfirm>
                    {/* <Button type="default" title={t('PDF') ?? ''} icon={<EditOutlined />}
                        key={'pdf-btn-' + record.id} onClick={() => {
                            const url: string = `${Report_URL}/report/ExportDoc?document_id=${record.id ?? ''}&format=pdf&fileName=Request-${record.serial ?? 0}`;
                            const newTab = window.open(url, '_blank');
                            if (newTab) {
                                newTab.focus();
                            } else {
                                // If the new tab was blocked by the browser's popup blocker,
                                // you can provide a fallback or display an error message.
                                console.error('Failed to open new tab. Please allow popups for this site.');
                            }

                        }} /> */}
                </Space>
            ),
        },
    ];
    const uploadFilePost = (id: string, fileName: string) => {
        console.log('Clicked cancel button');
        const uploadReportAsnaniDto: UploadReportAsnaniDto = {
            file: fileName,
        };
        asnaniApi.asnaniUploadReport(id, uploadReportAsnaniDto)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                getDataTable();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };
    const beforeUpload = (file: File) => {
        // Generate a timestamp for the file name
        const timestamp = Date.now();
        const newFileName = `${timestamp}${file.name.substring(file.name.lastIndexOf('.'))}`;

        // Modify the file object to use the new file name
        Object.defineProperty(file, 'name', {
            writable: true,
            value: newFileName,
        });

        // Add the timestamp to the form values
        const formValues = {
            file,
            timestamp,
        };

        // Update the form values before submitting
        setUploadedFile(newFileName);

        return true;
    };
    const handelCreateOrEdit = (record: AsnaniDto) => {
        console.log('Clicked cancel button');
        if (record.id == null || record.id == undefined) {
            const createAsnaniDto: CreateAsnaniDto = {
                year: record.year,
                month: record.month,
                location_Id: location_IdFilter
            };
            asnaniApi.asnaniCreate(createAsnaniDto)
                .then(response => {
                    // Handle the API response            
                    console.log(response.data);
                    set_select_id(response.data.id!);
                    getDataTable();
                    setOpenEdit(true);
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                });
        } else {
            set_select_id(record.id);
            setOpenEdit(true);
        }
    };

    const handelSubmitReport = (id: string) => {
        asnaniApi.asnaniSubmitReport(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                getDataTable();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };
    const handleLocationChange = (value: string) => {
        setlocation_IdFilter(value);
    };
    const operations = <>
        <Select
            showSearch={true}
            style={{ width: 400 }}
            onChange={handleLocationChange}
            direction={i18n.dir()}
            allowClear={false}
            options={locationOptions}
            value={location_IdFilter}
            placeholder={"اختر المركز الرئيسي"}
        />
    </>;

    return (
        <PageContainer
            header={{
                title: t('Report Upload'),
                ghost: true,
                breadcrumb: {
                    items: [
                        {
                            path: '/',
                            title: t("Asnani"),
                        },
                        {
                            path: '/report_upload',
                            title: t('Report Upload'),
                        },
                    ],
                },
                extra: [
                    <a key="3" type="primary"
                        href="../asnani_import_report.xlsx"
                    >
                        {t("تنزيل نموذج ملف الاكسل")}
                    </a>,
                ],
            }}
        >
            <ProCard
            >

                <div style={{ width: '100%', height: 1100 }}>
                    <div style={{ width: '100%' }}>

                        {/* <Tabs
             defaultActiveKey='تسجيل جديد'
             type="card"
             items={tab_items}
             onChange={onChangeTab} /> */}
                        <Input.Search
                            placeholder={t('Search') ?? ''}
                            onSearch={(value) => {
                                setSearchText(value);
                            }}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                        ></Input.Search>
                        {/* <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            style={{ minWidth: '300px' }}
            direction={i18n.dir()}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />
        </Space> */}
                        {operations}
                    </div>
                    <br />

                    <div style={{ width: '100%', height: 500 }}>
                        <Table
                            direction={i18n.dir()} columns={columns} dataSource={data_table} />
                    </div>
                </div>
                <Modal
                    open={openEdit}
                    centered
                    width={Math.max(1200, window.innerWidth - 300)}
                    title={t('تفاصيل التقرير الشهري')}
                    // okText="حفظ"
                    // cancelText="Cancel"
                    okButtonProps={{ disabled: true }}
                    cancelButtonProps={{ disabled: true }}
                    footer={[

                    ]}
                    onCancel={() => {
                        setOpenEdit(false);
                    }}
                >
                    <ReportUploadItems
                        id={select_id}
                        partyInfo={partyInfo}></ReportUploadItems>

                </Modal>
            </ProCard>
        </PageContainer>
    );
};
