import React, { useEffect, useState } from "react";
import { API_URL, Report_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Button, Checkbox, DatePicker, Radio, Select, SelectProps, Space, message } from "antd";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportFilterDto, ItemApi, ReportApi, SubdivisionApi, CategoryApi, ItemDto, LocationGroupApi } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CheckboxChangeEvent } from "antd/es/checkbox";
const { RangePicker } = DatePicker;

export default () => {

  const { t, i18n } = useTranslation();
  const [items, setitems] = useState<SelectProps['options']>([]);
  const [categories, setCategory] = useState<SelectProps['options']>([]);
  const [date_range_by, setdate_range_by] = useState<string>('post_to_supplier_time');
  const auth = useAuth();
  const [locationGrouplist, setlocationGrouplist] = useState<SelectProps['options']>([]);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const itemApi = new ItemApi(configuration);
  const categoryApi = new CategoryApi(configuration);
  const subdivisionApi = new SubdivisionApi(configuration);
  const reportApi = new ReportApi(configuration);
  const locationGroupApi = new LocationGroupApi(configuration);

  function generateStringList(objects: ItemDto[]): string[] {
    return objects.map((item) => (item.description == null || item.description === '' || item.description == undefined ) ? (item.name || '') : (item.description) );
  }
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        const response_normlize = generateStringList(response ?? []);
        const uniqueStringList: string[] = [];
        response_normlize.forEach((value) => {
          if (!uniqueStringList.includes(value)) {
            uniqueStringList.push(value);
          }
        });

        var mapedOptions: SelectProps['options'] = uniqueStringList?.map((item) => (
          {
            label: item,
            value: item
          }
        ));
        console.log("active item count : ", mapedOptions?.length)
        setitems(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []); // Run this effect only once when the component mounts
  useEffect(() => {
    // Fetch the select options from the API
    fetchCategoryOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id, active, code }) => (
          {
            label: `${code} - ${name} ${active == true ? t("") : "/ (" + t("InActive") + ")"} `,
            value: id
          }
        ));
        console.log("active item count : ", mapedOptions?.length)
        setCategory(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
      
      fetchlocationGroups()
      .then((response) => {
          var mapedOptions: SelectProps['options'] = response?.map(({ name, code }) => (
            {
              label: name,
              value: code
            }
          ));
          setlocationGrouplist(mapedOptions);
        })
      .catch((error) => {
          console.error('Failed to fetch select options:', error);
          setlocationGrouplist([]);
      });
  
  }, []);
  
  const fetchlocationGroups = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationGroupApi.locationGroupGetList()
        .then((response) => response.data)
        .then((data) => data.items);
};
  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return itemApi.itemGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchCategoryOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList('item')
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchOptions2 = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return subdivisionApi.subdivisionGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  const [selectedItem, setselectedItem] = useState<string | null>(null);
  const [selectedCategory, setselectedCategory] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);
  const [group_by_location, setgroup_by_location] = useState<boolean>(true);
  const [group_by_month, setgroup_by_month] = useState<boolean>(true);

  const handleItemChange = (value: string) => {
    setselectedCategory(null);
    setselectedItem(value);
  };
  const handleCategoryChange = (value: string) => {
    setselectedItem(null);
    setselectedCategory(value);
  };
  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };


  const onClickExport = () => {
    if (selectedDateRange != null ) {

      const reportFilterDto = {
        item_Id: selectedItem,
        category_Id: selectedCategory,
        locationGroup: selectedLocation,
        fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
        toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD'),
        report_name: 'تقرير اجمالي احصائي الاصناف حسب السنة'
      };
      const url: string = `${Report_URL}/Report/ExportItemSalesSummaryDoc?from_date=${reportFilterDto.fromDate ?? ''}&to_date=${reportFilterDto.toDate ?? ''}&category_id=${reportFilterDto.category_Id ?? ''}&group_by_location=${group_by_location == true ? '1' : '0'}&group_by_month=${group_by_month == true ? '1' : '0'}&location_code=${reportFilterDto.locationGroup ?? ''}&item=${reportFilterDto.item_Id ?? ''}&report_name=${reportFilterDto.report_name ?? ''}&date_range_by=${date_range_by ?? ''}&format=pdf&fileName=report`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        // If the new tab was blocked by the browser's popup blocker,
        // you can provide a fallback or display an error message.
        console.error('Failed to open new tab. Please allow popups for this site.');
      }

    }else{
      message.error(t(' الفترة حقل اجباري'));
    }
  };
  
  
  const ongroup_by_location = (e: CheckboxChangeEvent) => {
    setgroup_by_location(e.target.checked);
  };
  const onsetgroup_by_month = (e: CheckboxChangeEvent) => {
    setgroup_by_month(e.target.checked);
  };
  return (
    <div style={{ width: '100%', height: 700 }}>
      <div style={{ width: '100%' }}>
          <h3>{t('تقرير اجمالي احصائي الاصناف حسب السنة')}</h3>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Item')}</Title>
          <Select
            showSearch
            allowClear={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleItemChange}
            options={items}
            placeholder={t("الكل")}
            style={{ minWidth: '600px' }}
            value={selectedItem}
          />
          <Title level={5}>{t('الفئة')}</Title>
          <Select
            showSearch
            allowClear={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleCategoryChange}
            options={categories}
            placeholder={t("الكل")}
            style={{ minWidth: '600px' }}
            value={selectedCategory}
          />
          <Title level={5}>{t('Care Center')}</Title>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleLocationChange}
            options={locationGrouplist}
            placeholder={t("الكل")}
            style={{ minWidth: '600px' }}
            allowClear
          />

          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            direction={i18n.dir()}
            style={{ minWidth: '300px' }}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />
          {/* <Checkbox value={group_by_location} checked={group_by_location} onChange={ongroup_by_location}>{t("التجميع حسب المركز")}</Checkbox> */}
          {/* <Checkbox value={group_by_month} checked={group_by_month} onChange={onsetgroup_by_month}>{t("التجميع حسب الشهر")}</Checkbox> */}
          <Radio.Group defaultValue={date_range_by} buttonStyle="solid" >
            <Radio.Button value="post_to_supplier_time" onClick={() => { setdate_range_by("post_to_supplier_time"); }}>{t("حسب تاريخ امر الشراء")}</Radio.Button>
            <Radio.Button value="status_time" onClick={() => { setdate_range_by("status_time"); }}>{t("حسب تاريخ التسليم")}</Radio.Button>
          </Radio.Group>
          <Button key="3" type="primary" onClick={onClickExport}>
            {t("استخراج التقرير")}
          </Button>
        </Space>
      </div>
    </div>

  );

};