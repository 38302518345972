import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Checkbox, DatePicker, Radio, Space } from "antd";
import { ChartStaticsDto, Configuration, ConfigurationParameters, DocumentApi, ItemApi, ReportApi, ReportFilterDto } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
const { RangePicker } = DatePicker;

export default () => {

  const { t, i18n } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const auth = useAuth();
  const [showByDetails, setshowByDetails] = useState<boolean>(true);

  const [groupBy, setGroupBy] = useState<string>('LocationGroup');
  const [date_range_by, setdate_range_by] = useState<string>('post_to_supplier_time');

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);

  /***end get options */

  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };

  useEffect(() => {
    if (selectedDateRange != null) {
      getData();
    }
  }, [selectedDateRange, groupBy, showByDetails,date_range_by]); // Run this effect only once when the component mounts

  const getData = () => {
    const reportFilterDto: ReportFilterDto = {
      fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
      toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD'),
      groupBy: groupBy,
      dateRangeBy:date_range_by
    };
    if (showByDetails == true) {

      reportApi.reportRequestSalesReportByLinesQty(
        reportFilterDto
      )
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error('Failed to fetch select options:', error);
        });
    } else {
      reportApi.reportRequestSalesReport(
        reportFilterDto
      )
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error('Failed to fetch select options:', error);
        });
    }

  };

  const COLORS = [colorPrimary, colorSecondory, '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F02242', '#075242', '#375242'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul style={{overflow:"scroll", height: "200px"}}>
        {
          payload.map((entry: any, index: any) => (
            <li key={`item-${index}`} style={{ color: entry.color, fontWeight: "bold" }}>{getName(entry.payload.name)} - ({entry.payload.value})</li>
          ))
        }
      </ul>
    );
  }

  const onChangeshowByDetails = (e: CheckboxChangeEvent) => {
    setshowByDetails(e.target.checked);
  };
  function getName(name: string): string {
    return name;
    // if (groupBy === 'LocationGroup') {
    //   const filteredItems = locationGroup.filter(x => x.value === name);
    //   if(filteredItems.length > 0)
    //     return filteredItems[0].label;
    //   return '';
    // } else {
    //   return name;
    // }
  }
  return (
    <div style={{ width: '100%', height: 800 }}>
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            direction={i18n.dir()}
            style={{ minWidth: '300px' }}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />
          <Checkbox value={showByDetails} checked={showByDetails} onChange={onChangeshowByDetails}>{t("تفصيلي حسب كمية الاصناف و الخدمات")}</Checkbox>
          <Radio.Group defaultValue={date_range_by} buttonStyle="solid" >
            <Radio.Button value="post_to_supplier_time" onClick={() => { setdate_range_by("post_to_supplier_time"); }}>{t("حسب تاريخ امر الشراء")}</Radio.Button>
            <Radio.Button value="status_time" onClick={() => { setdate_range_by("status_time"); }}>{t("حسب تاريخ التسليم")}</Radio.Button>
          </Radio.Group>
          {/* <Radio.Group defaultValue={groupBy} buttonStyle="solid" >
            <Radio.Button value="Subdivision" onClick={() => { setGroupBy("Subdivision"); }}>{t("Subdivision")}</Radio.Button>
            <Radio.Button value="City" onClick={() => { setGroupBy("City"); }}>{t("City")}</Radio.Button>
            <Radio.Button value="LocationGroups" onClick={() => { setGroupBy("LocationGroup"); }}>{t("Location")}</Radio.Button>
          </Radio.Group> */}

        </Space>
      </div>
      <div style={{ width: '100%', height: 600}}>
        <h2 style={{ textAlign: "center" }}>احصائي بعدد الطلبات او كميات الاصناف حسب المنطقة خلال فترة</h2>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart
          // width={400} height={400}
          >
            <Pie
              data={data}
              // cx={200}
              // cy={200}
              labelLine={false}
              label={renderCustomizedLabel}
              // label
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${(data[index].name)?.toString()}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            {/* <Legend /> */}
            <Legend align="left" content={renderLegend} />

          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>

  );

};