import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch } from 'antd';
import { API_URL } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Configuration, IdentityUserDto, UserApi } from "../../api/najd";

interface UserFormDrawerProps {
  formData?: IdentityUserDto; // Prop to receive the rowData
  editMode: boolean; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}

export const UsersForm: React.FC<UserFormDrawerProps> = ({ formData, onSubmit, onChange, onClose, editMode }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);

  const [options, setOptions] = useState<SelectProps['options']>([]);
  const token = auth.user?.access_token;
  const configuration = new Configuration({
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  });
  const userApi = new UserApi(configuration);
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: name
          }
        ));
        setOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []); // Run this effect only once when the component mounts

  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return userApi.userGetAssignableRoles()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      onSubmit(values);
    });
  };

  return (
    <Form layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={formData}
    >
      <Form.Item name="id" hidden={true}><Input /></Form.Item>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="userName"
            label={t("User Name")}
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="password"
            label={t("Password")}
            rules={[{ required: !editMode, message: t('Required field') ?? '' }]}>
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="name"
            label={t("Name")} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="surname" label={t("Surname")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="email"
            label={t("Email")}
            rules={[{ required: true, message: t('Required field') ?? '', type: 'email' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item name="phoneNumber" label={t("Phone Number")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="roleNames"
            label={t("User Roles")}
            rules={[{ type: 'array' }]}>
            <Select mode="multiple"
              options={options} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="isActive" label={t("Active")} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form>);
};