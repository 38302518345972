export const LANGUAGES = [
  { label: "العربية", code: "ar" },
  { label: "English", code: "en" },
];

export const colorPrimary = "#397551";
export const colorSecondory = "#93c8a1";
export const enalbleOtp = true;

// export const Auth_client_id = "App_react"
// export const API_URL = "https://localhost:44362"
// export const API_URL_Upload = "https://localhost:44362/FileUpload/upload"
// export const Rresource_Path = "https://localhost:44362/uploads/"
// export const Report_URL = "https://localhost:7234"

// export const Auth_client_id="App_react_prod"s
// export const API_URL="https://health-api.alaliwi.com"
// export const API_URL_Upload="https://health-api.alaliwi.com/FileUpload/upload"
// export const Rresource_Path="https://health-api.alaliwi.com/uploads/"
// export const Report_URL="http://88.99.98.220:8082"

export const Auth_client_id="App_react_wecare"
export const API_URL="https://api.wecare360.org"
export const API_URL_Upload="https://api.wecare360.org/FileUpload/upload"
export const Rresource_Path="https://api.wecare360.org//uploads/"
export const Report_URL="http://88.99.98.220:8082"

// interface MenuItem {
//   path: string;
//   name: string;
//   icon?: React.ReactNode;
//   children?: MenuItem[]; // Optional children property for nested menu items
// }


export const phoneRegExp = new RegExp(/^[\d]{0,10}$/);

export const locationGroup = [
  { value: "01", label: "مركز الرعاية الصحية المنزلية بالمستشفى الجامعي بجدة" },
  { value: "02", label: "مركز الرعاية الصحية المنزلية بالمدينة المنورة" },
  { value: "03", label: "مركز الرعاية الصحية المنزلية بعسير ونجران" },
  { value: "04", label: "مركز الطب المنزلي بالباحة." },
  { value: "05", label: "مركز الرعاية الصحية المنزلية بمدينة الملك فهد الطبية بالرياض" },
  { value: "06", label: "مركز الرعاية الصحية المنزلية بتبوك" },
  { value: "07", label: "مركز الرعاية الصحية المنزلية بحائل" },
  { value: "08", label: "مركز الرعاية الصحية المنزلية بجازان" },
  { value: "09", label: "مركز الرعاية الصحية المنزلية بمستشفى الملك فهد للقوات المسلحة بجدة" },
  { value: "10", label: "مركز الرعاية الصحية المنزلية بمستشفى األطفال بمحافظة الطائف" },
  { value: "11", label: "مركز الرعاية الصحية المنزلية بالمركز الطبي لمجمع الملك فيصل بالطائف" },
  { value: "12", label: "برنامج الرعاية المنزلية التابع لفرع وزارة الموارد البشرية والتن مية االجتماعية بجدة )مرضى التأهيل الشامل(" },
  { value: "13", label: "مركز الرعاية الصحية المنزلية بمستشفى الملك عبدالعزيز بالطائف" },
  { value: "14", label: "المركز الرئيسي المؤسسة الخيرية الوطنية للرعاية الصحية المنزلية – نرعاك" },
  { value: "15", label: "مستشفى الملك فيصل مكة المكرمة – وزارة الصحة" },
  { value: "16", label: "إدارة الرعاية الصحية المنزلية – مستشفى شقراء العام – وزارة الصحة" },
  { value: "17", label: "مستشفى األمير محمد بن عبدالعزيز بالمدينة المنورة – الحرس الوطني" },
  { value: "18", label: "جمعية الرعاية الصحية بعقلة الصقور" }
];