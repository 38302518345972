import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Input, Popconfirm, Space, Table, Tabs, TabsProps, Tag, message } from 'antd';
import type { ColumnsType, } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, FileAddOutlined, HistoryOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { getFullDateTime } from "../../helper";
import { API_URL } from "../../constants";
import { LocationApi, PartyApi, PartyDto, Configuration, ConfigurationParameters } from "../../api/najd";
import { useAuth } from "react-oidc-context";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { OtpForm } from "./OtpForm";
import { UserAccountForm } from "./UserAccountForm";
import { PartyDocuments } from "./PartyDocuments";

interface DataGridProps {
    role: any;
    partyInfo: PartyDto;
    onClickRowEdit: (rowData: any) => void;
    refreshTrigger: boolean;
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const PartiesTable: React.FC<DataGridProps> = ({ role, onClickRowEdit, refreshTrigger, setRefreshTrigger, partyInfo }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<PartyDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectRowKey, setselectRowKey] = useState('');
    const [open, setOpen] = useState(false);
    const [openOtp, setOpenOtp] = useState(false);
    const [openUserAccount, setopenUserAccount] = useState(false);
    const [openUserDocument, setopenUserDocument] = useState(false);
    const [partyStatusFilter, setpartyStatusFilter] = useState('تسجيل جديد');

    const [searchText, setSearchText] = useState('');


    const auth = useAuth();

    const statusList = [
        { value: 'تسجيل جديد', label: t('تسجيل جديد') },
        { value: 'مفعل', label: t('مفعل') },
        { value: 'موافقة', label: t('موافقة') },
        { value: 'طلب تغير مركز صجي', label: t('طلب تغير مركز صجي') },
        { value: 'ايقاف', label: t('ايقاف') },
    ]

    const tab_items: TabsProps['items'] = [
        { key: 'تسجيل جديد', label: t('تسجيل جديد') },
        { key: 'مفعل', label: t('مفعل') },
        { key: 'موافقة', label: t('موافقة') },
        { key: 'طلب تغير مركز صجي', label: t('طلب تغير مركز صجي') },
        { key: 'ايقاف', label: t('ايقاف') },
    ];

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const locationApi = new LocationApi(configuration);
    const partyApi = new PartyApi(configuration);

    const columns: ColumnsType<PartyDto> = [
        { title: t('Serial'), dataIndex: 'serial', key: 'Serial', },
        {
            title: t('Name'), dataIndex: 'name', key: 'Name',
            // filteredValue: [searchText],
            render: (_, record) => <span>{record.name} {record.middleName} {record.lastName}</span>,
            // onFilter: (value, record) => {
            //     return (
            //         String(record.name)
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase()) ||
            //         String(record.phoneNumber)
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase()) ||
            //         String(record.identifier)
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase()) ||
            //         String(record.location?.name)
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase())
            //     );
            // }
        },
        { title: t('Phone Number'), dataIndex: 'phoneNumber', key: 'PhoneNumber' },
        {
            title: t('Care Center'), dataIndex: 'location_Id', key: 'location_Id',
            render: (_, record) => <span>{record.location !== null ? record.location?.name : ''}</span>,
        },
        {
            title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date))
        },
        {
            title: t('Active'),
            dataIndex: 'active',
            key: 'active',
            render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
        },
        {
            title: t('Action'),
            key: 'action',
            render: (_, record) => (
                (partyInfo.code === 'readonly') ? <></> : <Space size="middle">
                    <Button type="primary" icon={<EditOutlined />} title={t('Edit') ?? ''} onClick={() => { handleRowClick(record) }} />
                    {/* <Button type="primary" icon={<FileAddOutlined />} onClick={() => { handleRequestRowClick(record) }} title="فتح طلب" /> */}
                    <Popconfirm
                        title={t("Are you sure you want to delete this?")}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" icon={<DeleteOutlined />} title={t('Delete') ?? ''} danger key={'con-btn-' + record.id} />
                    </Popconfirm>
                    <Button type="default" icon={<LockOutlined />} title={t('Change password') ?? ''} key={'pass-btn-' + record.id} onClick={() => {
                        if (record.phoneNumber === null) {
                            message.error('رقم الهاتف ضروري لحساب الدخول');
                        } else if (record.code === 'patient' && record.status === 'تسجيل جديد') {
                            message.error('الحساب جديد ويحتاج تفعيل');
                            setselectRowKey(record.id ?? '');
                            setOpenOtp(true);
                        } else {
                            setselectRowKey(record.id ?? '');
                            setOpen(true);
                        }
                    }} />
                    <Button type="default" icon={<UserOutlined />} title={t('User Account') ?? ''} key={'user-btn-' + record.id} onClick={() => {
                        setselectRowKey(record.id ?? '');
                        setopenUserAccount(true);
                    }} hidden={(role === 'patient' ? true : false)} />
                    <Button type="default" icon={<HistoryOutlined />} title={t('Paient Requests') ?? ''} key={'party-doc-btn-' + record.id} onClick={() => {
                        setselectRowKey(record.id ?? '');
                        setopenUserDocument(true);
                    }} hidden={(role === 'patient' ? false : true)} />
                </Space>
            ),
        },
    ];
    if (role === 'employee') {
        columns.splice(4, 0, {
            title: t('Role'), dataIndex: 'category_Id', key: 'category_Id',
            render: (_, record) => <span>{record.category !== null ? record.category?.name : ''}</span>,
        });
    }
    if (role === 'patient') {
        columns.splice(3, 0, {
            title: t('Identifier'),
            dataIndex: 'identifier',
            key: 'identifier',
        });
        columns.splice(6, 0, {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                const statusResult = statusList?.find((status_item) => status_item.value === status);
                return statusResult ? statusResult.label : 'N/A';
            },
        });
    }

    // Use the onClickRowEdit function as needed
    const handleRowClick = (rowData: any) => {
        onClickRowEdit(rowData);
    };

    // Use the onClickRowEdit function as needed
    const handleRequestRowClick = (rowData: any) => {

    };

    const handleDelete = (id: any) => {
        console.log('Clicked cancel button');
        partyApi.partyDelete(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };

    const [totalPassengers, setTotalPassengers] = useState(1);
    const fetchRecords = async (page: any, pageSize: any) => {
        setLoading(true);
        try {
            var filterStatus = (role === 'patient') ? partyStatusFilter : undefined;
            console.log('===>', filterStatus);
            const skipRecord = (page - 1) * pageSize;
            const response = await partyApi.partyGetListLazy(searchText,
                role,
                undefined,
                undefined,
                undefined,
                undefined,
                partyInfo.location_Id ?? undefined,
                partyInfo.city_Id ?? undefined,
                partyInfo.subdivision_Id ?? undefined,
                filterStatus ?? undefined,
                undefined,
                skipRecord,
                pageSize);
            setUserData(response.data.items ?? []);
            setTotalPassengers(response.data.totalCount ?? 0);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchRecords(1, 10);
    }, [refreshTrigger, role, partyStatusFilter, searchText]);
    /*
        useEffect(() => {
            setLoading(true);
            const fetchUserData = async () => {
                try {
                    var filterStatus = (role === 'patient') ? partyStatusFilter : undefined;
                    console.log('===>', filterStatus);
                    const response = await partyApi.partyGetList('',
                        role,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        partyInfo.location_Id ?? undefined,
                        partyInfo.city_Id ?? undefined,
                        partyInfo.subdivision_Id ?? undefined,
                        filterStatus ?? undefined);
                    setUserData(response.data.items ?? []);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchUserData();
        }, [refreshTrigger, role, partyStatusFilter]); // Add refreshTrigger as a dependency
    */
    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };

    const onCreate = () => {
        setOpen(false);
    };
    const onCreateUserAccount = () => {
        setopenUserAccount(false);
    };

    const onSubmitOtp = () => {
        setOpenOtp(false);
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };
    const otpCom = () => {
        return <OtpForm
            party_id={selectRowKey}
            open={openOtp}
            onCreate={onSubmitOtp}
            onCancel={() => {
                setOpenOtp(false);
            }}
        />;
    }
    const onChangeTab = (key: string) => {
        setpartyStatusFilter(key);
    };
    const showtas = () => {
        return role === 'patient' ? <Tabs
            defaultActiveKey='تسجيل جديد'
            type="card"
            items={tab_items}
            onChange={onChangeTab} /> : <></>;
    }
    function removeSpacesFromString(str: string): string {
        return str.replace(/^\s+|\s+$/g, '');
    }
    return (<>
        {/* <button onClick={handleRefresh}>Refresh Data</button> */}
        {showtas()}
        <Input.Search
            placeholder={t('Search') ?? ''}
            onSearch={(value) => {
                setSearchText(removeSpacesFromString(value));
            }}
            onChange={(e) => {
                setSearchText(removeSpacesFromString(e.target.value));
            }}
        ></Input.Search>
        <Table<PartyDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={userData}
            columns={columns}
            loading={loading}
            pagination={{
                total: totalPassengers,
                onChange: (page, pageSize) => {
                    fetchRecords(page, pageSize);
                },
            }}
        />
        {role === 'patient' ? otpCom() : null}
        <ChangePasswordForm
            party_id={selectRowKey}
            open={open}
            onCreate={onCreate}
            onCancel={() => {
                setOpen(false);
            }}
        />
        <UserAccountForm
            party_id={selectRowKey}
            open={openUserAccount}
            onCreate={onCreateUserAccount}
            onCancel={() => {
                setopenUserAccount(false);
            }}
        />
        <PartyDocuments
            party_id={selectRowKey}
            open={openUserDocument}
            onCancel={() => {
                setopenUserDocument(false);
            }}
        />
    </>
    );
};

export default PartiesTable;