import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import FormDrawer from "../../components/FormDrawer";
import { API_URL } from "../../constants";
import { RolesForm } from "./RolesForm";
import RolesTable from "./RolesTable";
import { useAuth } from "react-oidc-context";
import { Configuration, RoleApi } from "../../api/najd";

export const Roles = () => {
    const auth = useAuth();
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openPermForm, setopenPermForm] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

    const token = auth.user?.access_token;
    const configuration = new Configuration({
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    });
    const roleApi = new RoleApi(configuration);

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState);
    };

    const onClickCreateNew = () => {
        // Handle create new action
        setEditMode(false);
        setSelectedRowData(null); // Set the selected row data in the state
        setDrawerOpen(true);
    };

    const onClickRowEdit = (rowData: any) => {
        // Handle the row edit action
        setEditMode(true);
        setSelectedRowData(rowData); // Set the selected row data in the state
        setDrawerOpen(true);
    };
    const onClickRowEditPerm = (rowData: any) => {
        // Handle the row edit action
        setSelectedRowData(rowData); // Set the selected row data in the state
        setopenPermForm(true);
    };

    const onClickSubmitButton = () => {
        // Handle the row edit action
        if (selectedRowData) {
            handleFormSubmit(selectedRowData);
        }
    };
    // const onSubmit = (formData: any) => {
    //     // Handle the row edit action
    //     console.log('On Submit:', formData);
    // };

    // Form Events
    const handleFormSubmit = (formData: any) => {
        // Handle form submission in the parent component
        console.log('Form Data:', formData);
        if (editMode == true) {
            roleApi.roleUpdate(formData.id, formData)
                .then(response => {
                    // Handle the API response
                    console.log(response.data);
                    closeDrawer();
                    handleRefresh();
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                });
        } else {
            roleApi.roleCreate(formData)
                .then(response => {
                    // Handle the API response
                    console.log(response.data);
                    closeDrawer();
                    handleRefresh();
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                });
        }
    };
    const handleFormChange = (formData: any) => {
        console.log('handleFormChange:', formData);
        setSelectedRowData(formData);
    };
    // End Form Events
    return (
        <PageContainer
            header={{
                title: t('Users'),
                ghost: true,
                breadcrumb: {
                    items: [
                        {
                            path: '/',
                            title: t("Home"),
                        },
                        {
                            path: '/',
                            title: t("Users"),
                        },
                    ],
                },
                extra: [
                    <Button key="3" type="primary" onClick={onClickCreateNew}>
                        {t("Add New")}
                    </Button>,
                ],
            }}
        // footer={[
        //     <Button key="3">خيار 14</Button>,
        //     <Button key="2" type="primary">
        //         خيار 15
        //     </Button>,
        // ]}
        >
            <ProCard
            // style={{
            //     height: '100vh',
            //     minHeight: 800,
            // }}
            >
                <RolesTable
                    onClickRowEdit={onClickRowEdit}
                    onClickRowEditPerm={onClickRowEditPerm}
                    refreshTrigger={refreshTrigger}
                    setRefreshTrigger={setRefreshTrigger}
                />
                <FormDrawer open={drawerOpen}
                    onClose={closeDrawer}
                    formData={selectedRowData}
                    title={((editMode) ? t("Edit") : t("Create new"))}
                    onSubmit={onClickSubmitButton}>
                    <RolesForm
                        editMode={editMode}
                        onClose={closeDrawer}
                        formData={selectedRowData}
                        onSubmit={handleFormSubmit}
                        onChange={handleFormChange} />
                </FormDrawer>
            </ProCard>
        </PageContainer>
    );
};
