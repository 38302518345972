/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { CreateItemDto } from '../../../com/najd/models';
// @ts-ignore
import { ItemDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfItemDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
// @ts-ignore
import { UpdateItemDto } from '../../../com/najd/models';
/**
 * ItemApi - axios parameter creator
 * @export
 */
export const ItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/items/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateItemDto} [createItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemCreate: async (createItemDto?: CreateItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemGet', 'id', id)
            const localVarPath = `/api/md/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [categoryId] 
         * @param {string} [itemTypeId] 
         * @param {string} [itemTypeCode] 
         * @param {string} [partyId] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemGetList: async (filter?: string, categoryId?: string, itemTypeId?: string, itemTypeCode?: string, partyId?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['Category_Id'] = categoryId;
            }

            if (itemTypeId !== undefined) {
                localVarQueryParameter['ItemType_Id'] = itemTypeId;
            }

            if (itemTypeCode !== undefined) {
                localVarQueryParameter['ItemTypeCode'] = itemTypeCode;
            }

            if (partyId !== undefined) {
                localVarQueryParameter['Party_Id'] = partyId;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateItemDto} [updateItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemUpdate: async (id: string, updateItemDto?: UpdateItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemUpdate', 'id', id)
            const localVarPath = `/api/md/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateItemDto} [createItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemCreate(createItemDto?: CreateItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemCreate(createItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [categoryId] 
         * @param {string} [itemTypeId] 
         * @param {string} [itemTypeCode] 
         * @param {string} [partyId] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemGetList(filter?: string, categoryId?: string, itemTypeId?: string, itemTypeCode?: string, partyId?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemGetList(filter, categoryId, itemTypeId, itemTypeCode, partyId, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateItemDto} [updateItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemUpdate(id: string, updateItemDto?: UpdateItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemUpdate(id, updateItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemCount(options?: any): AxiosPromise<number> {
            return localVarFp.itemCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateItemDto} [createItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemCreate(createItemDto?: CreateItemDto, options?: any): AxiosPromise<ItemDto> {
            return localVarFp.itemCreate(createItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.itemDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemGet(id: string, options?: any): AxiosPromise<ItemDto> {
            return localVarFp.itemGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [categoryId] 
         * @param {string} [itemTypeId] 
         * @param {string} [itemTypeCode] 
         * @param {string} [partyId] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemGetList(filter?: string, categoryId?: string, itemTypeId?: string, itemTypeCode?: string, partyId?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<ListResultDtoOfItemDto> {
            return localVarFp.itemGetList(filter, categoryId, itemTypeId, itemTypeCode, partyId, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateItemDto} [updateItemDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemUpdate(id: string, updateItemDto?: UpdateItemDto, options?: any): AxiosPromise<ItemDto> {
            return localVarFp.itemUpdate(id, updateItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemCount(options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateItemDto} [createItemDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemCreate(createItemDto?: CreateItemDto, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemCreate(createItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemDelete(id?: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemGet(id: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [categoryId] 
     * @param {string} [itemTypeId] 
     * @param {string} [itemTypeCode] 
     * @param {string} [partyId] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemGetList(filter?: string, categoryId?: string, itemTypeId?: string, itemTypeCode?: string, partyId?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemGetList(filter, categoryId, itemTypeId, itemTypeCode, partyId, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateItemDto} [updateItemDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemUpdate(id: string, updateItemDto?: UpdateItemDto, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemUpdate(id, updateItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}
