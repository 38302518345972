import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch } from 'antd';
import { CategoryApi, LocationGroupApi, Configuration, PartyApi } from "../../api/najd";
import { API_URL } from "../../constants";
interface FormDrawerProps {
  formData: any; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}

export const LocationGroupsForm: React.FC<FormDrawerProps> = ({ formData, onSubmit, onChange, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);

  const [options, setOptions] = useState<SelectProps['options']>([]);

  const configuration = new Configuration({
    basePath: API_URL,
  });
  const categoryApi = new CategoryApi(configuration);
  const locationGroupApi = new LocationGroupApi(configuration);
  const [locationGroupOptions, setLocationGroupOptions] = useState<SelectProps['options']>([]);
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
      fetchLocationGroups()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setLocationGroupOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

  }, []); // Run this effect only once when the component mounts

  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList('item')
      .then((response) => response.data)
      .then((data) => data.items);
  };
  
  const fetchLocationGroups = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationGroupApi.locationGroupGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      onSubmit(values);
    });
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    onChange(allValues);
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={formData}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="id" label="ID" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="code" label={t("Code")} rules={[{ required: true, message: t('Please enter required field') ?? '' }]}>
            <Input  />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label={t("Name")} rules={[{ required: true, message: t('Please enter required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form>);
};