import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import { AsnaniApi, AsnaniDto, AsnaniItemDto, Configuration, ConfigurationParameters, CreateAsnaniDto, DocumentStatusDetailsReportDto, DocumentSuppliersReportDto, ItemApi, LocationApi, LocationDto, PartyApi, PartyDto, ReportApi, ReportFilterDto, UpdateAsnaniItemDto } from "../../api/najd";
import { API_URL } from "../../constants";
import dayjs from "dayjs";
import { useAuth } from "react-oidc-context";
import { Button, Form, FormInstance, Input, Modal, Popconfirm, Radio, Select, SelectProps, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { getFullDateTime } from "../../helper";
import internal from "stream";
import { CheckCircleOutlined, EditOutlined, WarningOutlined } from "@ant-design/icons";


interface AsnaniPatientsProps {
    partyInfo: PartyDto;
}
export const AsnaniPatients: React.FC<AsnaniPatientsProps> = ({ partyInfo }) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<AsnaniDto>({});
    const [items, setItems] = useState<AsnaniItemDto[]>([]);
    const [selected_item, set_selected_item] = useState<AsnaniItemDto>({});
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const itemApi = new ItemApi(configuration);
    const locationApi = new LocationApi(configuration);
    const partyApi = new PartyApi(configuration);
    const asnaniApi = new AsnaniApi(configuration);

    // useEffect(() => {

    // }, []);
    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = () => {
        asnaniApi.asnaniGetItemList()
            .then((response) => {
                setItems(response.data.items ?? []);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    };

    const [open, setOpen] = useState(false);

    const onEdit = (values: AsnaniItemDto) => {
        console.log('Received values of form: ', values);
        // const updateAsnaniItemDto: UpdateAsnaniItemDto = {
        //     fullName:values.fullName,
        //     gender:values.gender,
        //     identifier:values.identifier,
        //     phoneNumber:values.phoneNumber,
        //     serviceStatus:values.serviceStatus,
        //     statusText:values.statusText,
        //     typeText:values.typeText,
        // };
        asnaniApi.asnaniUploadReportItem(values.id!, values)
            .then((response) => {
                setOpen(false);
                getDetails();
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    };
    const columns: ColumnsType<AsnaniItemDto> = [
        {
            title: t('Full Name'),
            dataIndex: 'fullName',
            key: 'fullName',
            render: (_, record) => <span>{record.fullName}</span>,
        },
        {
            title: t('Identifier'),
            dataIndex: 'identifier',
            key: 'identifier',
            render: (_, record) => <span> {record.identifier}</span>
        },
        {
            title: t('Phone Number'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        // {
        //     title: t('Location'),
        //     dataIndex: 'location',
        //     key: 'location',
        //     render: (_, record) => <span> {record.asnani?.location?.name!}</span>
        // },
        {
            title: t('Gender'),
            dataIndex: 'gender',
            key: 'gender',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return (
                    String(record.fullName)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.identifier)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.phoneNumber)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
                );
            },
        },
        {
            title: t('Service Status'),
            dataIndex: 'serviceStatus',
            key: 'serviceStatus',
        },
        {
            title: t('Status'),
            dataIndex: 'serviceStatus',
            key: 'serviceStatus',
        },
        {
            title: t('Type'),
            dataIndex: 'typeText',
            key: 'typeText',
        },
    ];

    return (
    <PageContainer
        header={{
            title: t('قائمة مرضى اسناني'),
            ghost: true,
            breadcrumb: {
                items: [
                    {
                        path: '/',
                        title: t("Asnani"),
                    },
                    {
                        path: '/patients',
                        title: t('Patients'),
                    },
                ],
            },
            extra: [
            ],
        }}
    >
        <ProCard>
            <div style={{ width: '100%', height: 600 }}>
                <div style={{ width: '100%' }}>
                    <Input.Search
                        placeholder={t('Search') ?? ''}
                        onSearch={(value) => {
                            setSearchText(value);
                        }}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                    ></Input.Search>
                </div>
                <br />
                <div style={{ width: '100%', height: 500 }}>
                    <Table
                        onRow={(r) => ({
                            onClick: () => console.log('###############', r),
                        })}
                        rowKey={(record) => record.id ?? ''}
                        direction={i18n.dir()} columns={columns} dataSource={items} />
                </div>
            </div>
        </ProCard>
    </PageContainer>
    );
};

