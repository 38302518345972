import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import { colorPrimary, colorSecondory } from "../constants";

const COLORS = [colorPrimary, colorSecondory, '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F02242', '#075242', '#375242'];

interface MyPieChartProps {
  data: any[];
  title?: string; // Prop to receive the rowData
  outerRadius?: number;
  localize?: boolean;
}
const MyPieChart: React.FC<MyPieChartProps> = ({ title, data, outerRadius,localize }) => {
  const { t } = useTranslation();

  function getlabel(label: string): string {
    if (localize == true) {
      return t(label);
    }
    return label;
  }
  return (
    <>
      {title !== null ? <h2 style={{ textAlign: "center" }}>{t(title ?? '')}</h2> : <></>}
      <ResponsiveContainer width="100%" height="100%">
        <PieChart
        // width={400} height={400}
        >
          <Pie
            data={data}
            // cx={200}
            // cy={200}
            // labelLine={false}
            // label={renderCustomizedLabel}
            label
            outerRadius={outerRadius ?? 75}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell name={getlabel(data[index].name)} key={`cell-${index}`} fill={data[index].color??(COLORS[index % COLORS.length])} />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};
export default MyPieChart;