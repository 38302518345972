/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { CountryDto } from '../../../com/najd/models';
// @ts-ignore
import { CreateCountryDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfCountryDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCountryDto} [createCountryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryCreate: async (createCountryDto?: CreateCountryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCountryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countryGet', 'id', id)
            const localVarPath = `/api/md/country/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGetList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CountryDto} [countryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryUpdate: async (id: string, countryDto?: CountryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countryUpdate', 'id', id)
            const localVarPath = `/api/md/country/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCountryDto} [createCountryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryCreate(createCountryDto?: CreateCountryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryCreate(createCountryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryGetList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfCountryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryGetList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CountryDto} [countryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryUpdate(id: string, countryDto?: CountryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryUpdate(id, countryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCountryDto} [createCountryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryCreate(createCountryDto?: CreateCountryDto, options?: any): AxiosPromise<CountryDto> {
            return localVarFp.countryCreate(createCountryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.countryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGet(id: string, options?: any): AxiosPromise<CountryDto> {
            return localVarFp.countryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGetList(options?: any): AxiosPromise<ListResultDtoOfCountryDto> {
            return localVarFp.countryGetList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CountryDto} [countryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryUpdate(id: string, countryDto?: CountryDto, options?: any): AxiosPromise<CountryDto> {
            return localVarFp.countryUpdate(id, countryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @param {CreateCountryDto} [createCountryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryCreate(createCountryDto?: CreateCountryDto, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryCreate(createCountryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryDelete(id?: string, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryGet(id: string, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryGetList(options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryGetList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CountryDto} [countryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryUpdate(id: string, countryDto?: CountryDto, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryUpdate(id, countryDto, options).then((request) => request(this.axios, this.basePath));
    }
}
