/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { CreateDocumentDto } from '../../../com/najd/models';
// @ts-ignore
import { CreateDraftDocumentDto } from '../../../com/najd/models';
// @ts-ignore
import { DocumentDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfDocumentStatusDto } from '../../../com/najd/models';
// @ts-ignore
import { PagedResultDtoOfDocumentDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
// @ts-ignore
import { UpdateDocumentDto } from '../../../com/najd/models';
// @ts-ignore
import { UpdateDocumentStatusDto } from '../../../com/najd/models';
// @ts-ignore
import { VerifyOtpDocumentDto } from '../../../com/najd/models';
/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentApprove: async (id: string, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentApprove', 'id', id)
            const localVarPath = `/api/md/documents/approve/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (comment !== undefined) {
                localVarQueryParameter['Comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCancel: async (id: string, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentCancel', 'id', id)
            const localVarPath = `/api/md/documents/cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (comment !== undefined) {
                localVarQueryParameter['Comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCount: async (filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (responsiblePartyId !== undefined) {
                localVarQueryParameter['ResponsibleParty_Id'] = responsiblePartyId;
            }

            if (partnerPartyId !== undefined) {
                localVarQueryParameter['PartnerParty_Id'] = partnerPartyId;
            }

            if (createdByPartyId !== undefined) {
                localVarQueryParameter['CreatedByParty_Id'] = createdByPartyId;
            }

            if (supplierPartyId !== undefined) {
                localVarQueryParameter['SupplierParty_Id'] = supplierPartyId;
            }

            if (salesPartyId !== undefined) {
                localVarQueryParameter['SalesParty_Id'] = salesPartyId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (documentTypeId !== undefined) {
                localVarQueryParameter['DocumentType_Id'] = documentTypeId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (locationGroup !== undefined) {
                localVarQueryParameter['LocationGroup'] = locationGroup;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['City_Id'] = cityId;
            }

            if (subdivisionId !== undefined) {
                localVarQueryParameter['Subdivision_Id'] = subdivisionId;
            }

            if (showCanceled !== undefined) {
                localVarQueryParameter['ShowCanceled'] = showCanceled;
            }

            if (currentUserDoc !== undefined) {
                localVarQueryParameter['CurrentUserDoc'] = currentUserDoc;
            }

            if (documentStatusCode !== undefined) {
                localVarQueryParameter['DocumentStatus_Code'] = documentStatusCode;
            }

            if (showRepeatTask !== undefined) {
                localVarQueryParameter['ShowRepeatTask'] = showRepeatTask;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDocumentDto} [createDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCreate: async (createDocumentDto?: CreateDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDraftDocumentDto} [createDraftDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCreateDraft: async (createDraftDocumentDto?: CreateDraftDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDraftDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGenerateRepeatedDocument: async (locationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/generate_repeated_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['location_id'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentGet', 'id', id)
            const localVarPath = `/api/md/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGetList: async (filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (responsiblePartyId !== undefined) {
                localVarQueryParameter['ResponsibleParty_Id'] = responsiblePartyId;
            }

            if (partnerPartyId !== undefined) {
                localVarQueryParameter['PartnerParty_Id'] = partnerPartyId;
            }

            if (createdByPartyId !== undefined) {
                localVarQueryParameter['CreatedByParty_Id'] = createdByPartyId;
            }

            if (supplierPartyId !== undefined) {
                localVarQueryParameter['SupplierParty_Id'] = supplierPartyId;
            }

            if (salesPartyId !== undefined) {
                localVarQueryParameter['SalesParty_Id'] = salesPartyId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (documentTypeId !== undefined) {
                localVarQueryParameter['DocumentType_Id'] = documentTypeId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (locationGroup !== undefined) {
                localVarQueryParameter['LocationGroup'] = locationGroup;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['City_Id'] = cityId;
            }

            if (subdivisionId !== undefined) {
                localVarQueryParameter['Subdivision_Id'] = subdivisionId;
            }

            if (showCanceled !== undefined) {
                localVarQueryParameter['ShowCanceled'] = showCanceled;
            }

            if (currentUserDoc !== undefined) {
                localVarQueryParameter['CurrentUserDoc'] = currentUserDoc;
            }

            if (documentStatusCode !== undefined) {
                localVarQueryParameter['DocumentStatus_Code'] = documentStatusCode;
            }

            if (showRepeatTask !== undefined) {
                localVarQueryParameter['ShowRepeatTask'] = showRepeatTask;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGetListLazy: async (filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/get-list-lazy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (responsiblePartyId !== undefined) {
                localVarQueryParameter['ResponsibleParty_Id'] = responsiblePartyId;
            }

            if (partnerPartyId !== undefined) {
                localVarQueryParameter['PartnerParty_Id'] = partnerPartyId;
            }

            if (createdByPartyId !== undefined) {
                localVarQueryParameter['CreatedByParty_Id'] = createdByPartyId;
            }

            if (supplierPartyId !== undefined) {
                localVarQueryParameter['SupplierParty_Id'] = supplierPartyId;
            }

            if (salesPartyId !== undefined) {
                localVarQueryParameter['SalesParty_Id'] = salesPartyId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (documentTypeId !== undefined) {
                localVarQueryParameter['DocumentType_Id'] = documentTypeId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (locationGroup !== undefined) {
                localVarQueryParameter['LocationGroup'] = locationGroup;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['City_Id'] = cityId;
            }

            if (subdivisionId !== undefined) {
                localVarQueryParameter['Subdivision_Id'] = subdivisionId;
            }

            if (showCanceled !== undefined) {
                localVarQueryParameter['ShowCanceled'] = showCanceled;
            }

            if (currentUserDoc !== undefined) {
                localVarQueryParameter['CurrentUserDoc'] = currentUserDoc;
            }

            if (documentStatusCode !== undefined) {
                localVarQueryParameter['DocumentStatus_Code'] = documentStatusCode;
            }

            if (showRepeatTask !== undefined) {
                localVarQueryParameter['ShowRepeatTask'] = showRepeatTask;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGetStatusList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/status-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentReject: async (id: string, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentReject', 'id', id)
            const localVarPath = `/api/md/documents/reject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (comment !== undefined) {
                localVarQueryParameter['Comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentReturnDoc: async (id: string, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentReturnDoc', 'id', id)
            const localVarPath = `/api/md/documents/return-doc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (comment !== undefined) {
                localVarQueryParameter['Comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentSentDocumentOtp: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentSentDocumentOtp', 'id', id)
            const localVarPath = `/api/md/documents/sent_document_otp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUnRepeatDocument: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentUnRepeatDocument', 'id', id)
            const localVarPath = `/api/md/documents/un_repeat_document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocumentDto} [updateDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUpdate: async (id: string, updateDocumentDto?: UpdateDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentUpdate', 'id', id)
            const localVarPath = `/api/md/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDocumentStatusDto} [updateDocumentStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUpdateDocumentStatus: async (updateDocumentStatusDto?: UpdateDocumentStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/update-document-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOtpDocumentDto} [verifyOtpDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentVerifyOtpAndApprove: async (verifyOtpDocumentDto?: VerifyOtpDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/verify_otp_and_approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyOtpDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentApprove(id: string, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentApprove(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCancel(id: string, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCancel(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCount(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCount(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDocumentDto} [createDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCreate(createDocumentDto?: CreateDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCreate(createDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDraftDocumentDto} [createDraftDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCreateDraft(createDraftDocumentDto?: CreateDraftDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCreateDraft(createDraftDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentGenerateRepeatedDocument(locationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentGenerateRepeatedDocument(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentGetList(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultDtoOfDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentGetList(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentGetListLazy(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultDtoOfDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentGetListLazy(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentGetStatusList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfDocumentStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentGetStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentReject(id: string, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentReject(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentReturnDoc(id: string, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentReturnDoc(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentSentDocumentOtp(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentSentDocumentOtp(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentUnRepeatDocument(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentUnRepeatDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocumentDto} [updateDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentUpdate(id: string, updateDocumentDto?: UpdateDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentUpdate(id, updateDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDocumentStatusDto} [updateDocumentStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentUpdateDocumentStatus(updateDocumentStatusDto?: UpdateDocumentStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentUpdateDocumentStatus(updateDocumentStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyOtpDocumentDto} [verifyOtpDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentVerifyOtpAndApprove(verifyOtpDocumentDto?: VerifyOtpDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentVerifyOtpAndApprove(verifyOtpDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentApprove(id: string, comment?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentApprove(id, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCancel(id: string, comment?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentCancel(id, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCount(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<number> {
            return localVarFp.documentCount(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDocumentDto} [createDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCreate(createDocumentDto?: CreateDocumentDto, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.documentCreate(createDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDraftDocumentDto} [createDraftDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCreateDraft(createDraftDocumentDto?: CreateDraftDocumentDto, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.documentCreateDraft(createDraftDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.documentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGenerateRepeatedDocument(locationId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentGenerateRepeatedDocument(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGet(id: string, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.documentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGetList(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<PagedResultDtoOfDocumentDto> {
            return localVarFp.documentGetList(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [responsiblePartyId] 
         * @param {string} [partnerPartyId] 
         * @param {string} [createdByPartyId] 
         * @param {string} [supplierPartyId] 
         * @param {string} [salesPartyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [documentTypeId] 
         * @param {string} [locationId] 
         * @param {string} [locationGroup] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {boolean} [showCanceled] 
         * @param {boolean} [currentUserDoc] 
         * @param {string} [documentStatusCode] 
         * @param {boolean} [showRepeatTask] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGetListLazy(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<PagedResultDtoOfDocumentDto> {
            return localVarFp.documentGetListLazy(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentGetStatusList(options?: any): AxiosPromise<ListResultDtoOfDocumentStatusDto> {
            return localVarFp.documentGetStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentReject(id: string, comment?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentReject(id, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentReturnDoc(id: string, comment?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentReturnDoc(id, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentSentDocumentOtp(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentSentDocumentOtp(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUnRepeatDocument(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentUnRepeatDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocumentDto} [updateDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUpdate(id: string, updateDocumentDto?: UpdateDocumentDto, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.documentUpdate(id, updateDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDocumentStatusDto} [updateDocumentStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentUpdateDocumentStatus(updateDocumentStatusDto?: UpdateDocumentStatusDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentUpdateDocumentStatus(updateDocumentStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyOtpDocumentDto} [verifyOtpDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentVerifyOtpAndApprove(verifyOtpDocumentDto?: VerifyOtpDocumentDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.documentVerifyOtpAndApprove(verifyOtpDocumentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentApprove(id: string, comment?: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentApprove(id, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentCancel(id: string, comment?: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentCancel(id, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [responsiblePartyId] 
     * @param {string} [partnerPartyId] 
     * @param {string} [createdByPartyId] 
     * @param {string} [supplierPartyId] 
     * @param {string} [salesPartyId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [documentTypeId] 
     * @param {string} [locationId] 
     * @param {string} [locationGroup] 
     * @param {string} [cityId] 
     * @param {string} [subdivisionId] 
     * @param {boolean} [showCanceled] 
     * @param {boolean} [currentUserDoc] 
     * @param {string} [documentStatusCode] 
     * @param {boolean} [showRepeatTask] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentCount(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentCount(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDocumentDto} [createDocumentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentCreate(createDocumentDto?: CreateDocumentDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentCreate(createDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDraftDocumentDto} [createDraftDocumentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentCreateDraft(createDraftDocumentDto?: CreateDraftDocumentDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentCreateDraft(createDraftDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentDelete(id?: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [locationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentGenerateRepeatedDocument(locationId?: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentGenerateRepeatedDocument(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentGet(id: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [responsiblePartyId] 
     * @param {string} [partnerPartyId] 
     * @param {string} [createdByPartyId] 
     * @param {string} [supplierPartyId] 
     * @param {string} [salesPartyId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [documentTypeId] 
     * @param {string} [locationId] 
     * @param {string} [locationGroup] 
     * @param {string} [cityId] 
     * @param {string} [subdivisionId] 
     * @param {boolean} [showCanceled] 
     * @param {boolean} [currentUserDoc] 
     * @param {string} [documentStatusCode] 
     * @param {boolean} [showRepeatTask] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentGetList(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentGetList(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [responsiblePartyId] 
     * @param {string} [partnerPartyId] 
     * @param {string} [createdByPartyId] 
     * @param {string} [supplierPartyId] 
     * @param {string} [salesPartyId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [documentTypeId] 
     * @param {string} [locationId] 
     * @param {string} [locationGroup] 
     * @param {string} [cityId] 
     * @param {string} [subdivisionId] 
     * @param {boolean} [showCanceled] 
     * @param {boolean} [currentUserDoc] 
     * @param {string} [documentStatusCode] 
     * @param {boolean} [showRepeatTask] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentGetListLazy(filter?: string, responsiblePartyId?: string, partnerPartyId?: string, createdByPartyId?: string, supplierPartyId?: string, salesPartyId?: string, fromDate?: string, toDate?: string, documentTypeId?: string, locationId?: string, locationGroup?: string, cityId?: string, subdivisionId?: string, showCanceled?: boolean, currentUserDoc?: boolean, documentStatusCode?: string, showRepeatTask?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentGetListLazy(filter, responsiblePartyId, partnerPartyId, createdByPartyId, supplierPartyId, salesPartyId, fromDate, toDate, documentTypeId, locationId, locationGroup, cityId, subdivisionId, showCanceled, currentUserDoc, documentStatusCode, showRepeatTask, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentGetStatusList(options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentGetStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentReject(id: string, comment?: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentReject(id, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentReturnDoc(id: string, comment?: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentReturnDoc(id, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentSentDocumentOtp(id: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentSentDocumentOtp(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentUnRepeatDocument(id: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentUnRepeatDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDocumentDto} [updateDocumentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentUpdate(id: string, updateDocumentDto?: UpdateDocumentDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentUpdate(id, updateDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDocumentStatusDto} [updateDocumentStatusDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentUpdateDocumentStatus(updateDocumentStatusDto?: UpdateDocumentStatusDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentUpdateDocumentStatus(updateDocumentStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyOtpDocumentDto} [verifyOtpDocumentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentVerifyOtpAndApprove(verifyOtpDocumentDto?: VerifyOtpDocumentDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentVerifyOtpAndApprove(verifyOtpDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }
}
