import { ProDescriptions } from '@ant-design/pro-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { API_URL, Rresource_Path } from '../../constants';
import { Checkbox, Form, Input, Modal, SelectProps } from 'antd';
import { ChangePasswordInput, Configuration, ConfigurationParameters, IdentityUserUpdateDto, PartyApi, PartyDto, UserApi } from '../../api/najd';


interface ChangePasswordFormProps {
  party_id: any;
  open: boolean;
  onCreate: () => void;
  onCancel: () => void;
}

interface Values {
  title: string;
  description: string;
  modifier: string;
}


export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ party_id, open, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const auth = useAuth();
  const { t } = useTranslation();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const partyApi = new PartyApi(configuration);
  const userApi = new UserApi(configuration);


  useEffect(() => {
    const fetchUserData = async () => {
      // userApi.userGet(party_id)
      // .then(response => {
      //   setUserData(response);
      // })
      // .catch(error => {
      //   // Handle the API error
      //   console.error(error);
      // });

    };
    fetchUserData();
  }, [party_id]); // Add refreshTrigger as a dependency


  return (
    <Modal
      open={open}
      title={t("Change password")}
      okText={t("Submit")}
      cancelText={t("Cancel")}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            partyApi.partyChangePassword(party_id, values)
              .then(response => {

                onCreate();
              })
              .catch(error => {
                // Handle the API error
                console.error(error);
              });
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name="password"
          label={t("Password")}
          rules={[{
            required: true,
            message: t('Please confirm your password!') ?? '',
          }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label={t("Confirm Password")}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please confirm your password!') ?? '',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('The new password that you entered do not match!') ?? ''));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="SendToParty"
          label={t("Send to user")}
          valuePropName="checked" 
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};