import React, { useEffect, useState } from "react";
import { API_URL, Report_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Button, Checkbox, DatePicker, Select, SelectProps, Space, message } from "antd";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportFilterDto, ItemApi, ReportApi, SubdivisionApi, CategoryApi, ItemDto } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CheckboxChangeEvent } from "antd/es/checkbox";
const { RangePicker } = DatePicker;

export default () => {

  const { t, i18n } = useTranslation();
  const [items, setitems] = useState<SelectProps['options']>([]);
  const [categories, setCategory] = useState<SelectProps['options']>([]);
  const auth = useAuth();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const itemApi = new ItemApi(configuration);
  const categoryApi = new CategoryApi(configuration);
  const subdivisionApi = new SubdivisionApi(configuration);
  const reportApi = new ReportApi(configuration);

  function generateStringList(objects: ItemDto[]): string[] {
    return objects.map((item) => (item.description == null || item.description === '' || item.description == undefined ) ? (item.name || '') : (item.description) );
  }
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        const response_normlize = generateStringList(response ?? []);
        const uniqueStringList: string[] = [];
        response_normlize.forEach((value) => {
          if (!uniqueStringList.includes(value)) {
            uniqueStringList.push(value);
          }
        });

        var mapedOptions: SelectProps['options'] = uniqueStringList?.map((item) => (
          {
            label: item,
            value: item
          }
        ));
        console.log("active item count : ", mapedOptions?.length)
        setitems(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []); // Run this effect only once when the component mounts
  useEffect(() => {
    // Fetch the select options from the API
    fetchCategoryOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id, active, code }) => (
          {
            label: `${code} - ${name} ${active == true ? t("") : "/ (" + t("InActive") + ")"} `,
            value: id
          }
        ));
        console.log("active item count : ", mapedOptions?.length)
        setCategory(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []);
  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return itemApi.itemGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchCategoryOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList('item')
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchOptions2 = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return subdivisionApi.subdivisionGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  const [selectedItem, setselectedItem] = useState<string | null>(null);
  const [selectedCategory, setselectedCategory] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);
  const [group_by_location, setgroup_by_location] = useState<boolean>(true);
  const [group_by_month, setgroup_by_month] = useState<boolean>(true);

  const handleItemChange = (value: string) => {
    setselectedCategory(null);
    setselectedItem(value);
  };
  const handleCategoryChange = (value: string) => {
    setselectedItem(null);
    setselectedCategory(value);
  };
  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };


  const onClickExport1 = () => {
    const url: string = `${Report_URL}/Report/ExportSalesCountWithPercentageChange?group_by_location=${group_by_location == true ? '1' : '0'}&location_code=${selectedLocation ?? ''}&&format=pdf&fileName=report`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. Please allow popups for this site.');
      }
  };
  const onClickExport2 = () => {
    const url: string = `${Report_URL}/Report/ExportTotalSalesCountWithPercentageChange?group_by_location=${group_by_location == true ? '1' : '0'}&location_code=${selectedLocation ?? ''}&&format=pdf&fileName=report`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. Please allow popups for this site.');
      }
  };
  const onClickExport3 = () => {
    const url: string = `${Report_URL}/Report/ExportSalesItemQuantityWithPercentageChange?group_by_location=${group_by_location == true ? '1' : '0'}&location_code=${selectedLocation ?? ''}&&format=pdf&fileName=report`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. Please allow popups for this site.');
      }
  };
  const onClickExport4 = () => {
    const url: string = `${Report_URL}/Report/ExportTotalSalesItemQuantityWithPercentageChange?group_by_location=${group_by_location == true ? '1' : '0'}&location_code=${selectedLocation ?? ''}&&format=pdf&fileName=report`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. Please allow popups for this site.');
      }
  };
  
  
  const ongroup_by_location = (e: CheckboxChangeEvent) => {
    setgroup_by_location(e.target.checked);
  };
  const onsetgroup_by_month = (e: CheckboxChangeEvent) => {
    setgroup_by_month(e.target.checked);
  };
  return (
    <div style={{ width: '100%', height: 700 }}>
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <h3>{t('تقرير نسبة الطلبات (طلبات المرضى المسلمة) بين السنوات')}</h3>
          <Button key="3" type="primary" onClick={onClickExport1}>
            {t("استخراج التقرير")}
          </Button>
          <h3>{t('تقرير أجمالي نسبة الطلبات (طلبات المرضى المسلمة) بين السنوات')}</h3>
          <Button key="3" type="primary" onClick={onClickExport2}>
            {t("استخراج التقرير")}
          </Button>
          <br/>
          <br/>
          <br/>
          <h3>{t('تقرير نسبة الخدمات (كمية الاصناف المسلمة) بين السنوات')}</h3>
          <Button key="3" type="primary" onClick={onClickExport3}>
            {t("استخراج التقرير")}
          </Button>
          <h3>{t('تقرير أجمالي نسبة الخدمات (كمية الاصناف المسلمة) بين السنوات')}</h3>
          <Button key="3" type="primary" onClick={onClickExport4}>
            {t("استخراج التقرير")}
          </Button>
        </Space>
      </div>
    </div>

  );

};