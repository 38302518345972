import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Modal, message } from "antd";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import { Configuration, ConfigurationParameters, DocumentApi, PartyDto } from "../../api/najd";
import { useAuth } from "react-oidc-context";
import RepatedDocumentsTable from "./RepatedDocumentsTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { API_URL } from "../../constants";

interface RepatedDocumentsProps {
    partyInfo: PartyDto;
}
export const RepatedDocuments: React.FC<RepatedDocumentsProps> = ({ partyInfo }) => {
    const { t } = useTranslation();
    const { type } = useParams(); // Access the "type" parameter
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modal, contextHolder] = Modal.useModal();

    const auth = useAuth();
    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const documentApi = new DocumentApi(configuration);

    const handleOk = () => {
        setConfirmLoading(true);
        documentApi.documentGenerateRepeatedDocument(partyInfo.location_Id??'')
            .then(response => {
                setOpen(false);
                setConfirmLoading(false);

            }).catch((e) => {
                setConfirmLoading(false);
            })
        setTimeout(() => {
        }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const onClickGenerateRequests = () => {
        setOpen(true);
    };
    // End Form Events
    return (
        <PageContainer
            header={{
                title: t('Repeat Requests'),
                ghost: true,
                breadcrumb: {
                    items: [
                        {
                            path: '/',
                            title: t("Home"),
                        },
                        {
                            path: '/repeat-request',
                            title: t('Repeat Requests'),
                        },
                    ],
                },
                extra: [
                    (partyInfo.code === 'readonly')? <></>:<Button key="3" type="primary" onClick={onClickGenerateRequests}>
                        {t("Generate Requests")}
                    </Button>,
                ],
            }}
        >
            <Modal
                title={t('Generate Requests')}
                open={open}
                onOk={handleOk}
                okText={t('Ok')}
                cancelText={t('Back')}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <strong>{t('Are you sure to generate repated request?')}</strong>
            </Modal>
            <ProCard>
                <RepatedDocumentsTable
                    type={type}
                    partyInfo={partyInfo}
                    refreshTrigger={refreshTrigger}
                    setRefreshTrigger={setRefreshTrigger}
                />
            </ProCard>
        </PageContainer>
    );
};
