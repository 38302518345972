import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { API_URL } from "../../constants";
import { useEffect, useState } from "react";
import { PageContainer, ProCard } from "@ant-design/pro-components";
import { Tabs } from "antd";
import { ChangePassword } from "./ChangePassword";
import { ProfileInfo } from "./ProfileInfo";
import { Configuration, ConfigurationParameters, PartyDto, ProfileApi, ProfileDto } from "../../api/najd";
import { ProfileParty } from "./ProfileParty";


interface ProfileProps {
  partyInfo: PartyDto;
}
export const Profile: React.FC<ProfileProps> = ({ partyInfo }) => {
  const auth = useAuth();
  const [userData, setUserData] = useState<ProfileDto>({});
  const { t } = useTranslation();

  const token = auth.user?.access_token;

  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const profileApi = new ProfileApi(configuration);
  useEffect(() => {
    console.log(token);
    // setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await profileApi.profileGet();
        setUserData(response.data);
        // setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []); // Add refreshTrigger as a dependency

  return (
    <PageContainer
      header={{
        title: t('profile'),
        ghost: true,
        // breadcrumb: {
        //     items: [
        //         {
        //             path: '',
        //             title: 'خيار 1',
        //         },
        //         {
        //             path: '',
        //             title: 'خيار2 ',
        //         },
        //         {
        //             path: '',
        //             title: 'خيار 3',
        //         },
        //     ],
        // },
      }}>
      <ProCard>
        <Tabs
          tabPosition='left'
          items={[
            {
              label: t('Change password'),
              key: '1',
              children: <ChangePassword />,
            },
            {
              label: t('Personal settings'),
              key: '2',
              disabled:partyInfo.code === 'patient' ? false : true,
              children: <ProfileParty party_id={auth.user?.profile.sub??''} />,
            }
          ]}
        />
      </ProCard>
    </PageContainer>
  );
};