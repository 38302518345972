import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Input, Popconfirm, SelectProps, Space, Table, Tag } from 'antd';
import type { ColumnsType, } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getFullDateTime } from "../../helper";
import { API_URL } from "../../constants";
import { LocationApi, LocationDto, Configuration, PartyDto, LocationGroupApi } from "../../api/najd";

interface DataGridProps {
    partyInfo: PartyDto;
    onClickRowEdit: (rowData: any) => void;
    refreshTrigger: boolean;
    type: string | undefined;
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationsTable: React.FC<DataGridProps> = ({ onClickRowEdit, refreshTrigger, setRefreshTrigger,partyInfo,type }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<LocationDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [locationGrouplist, setlocationGrouplist] = useState<SelectProps['options']>([]);

    const configuration = new Configuration({
        basePath: API_URL,
    });
    const locationApi = new LocationApi(configuration);
    const locationGroupApi = new LocationGroupApi(configuration);

    const columns: ColumnsType<LocationDto> = [
        {
            title: t('Care Center'), dataIndex: 'name', key: 'name',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return (
                    String(record.name)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.address)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.city?.name)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
                );
            },
        },
        // {
        //     title: t('Address'), dataIndex: 'address', key: 'address',
        //     width: 250,
        // },
        {
            title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date)),
            width: 150,
        },
        {
            title: t('Active'),
            width: 150,
            dataIndex: 'active',
            key: 'active',
            render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
        },
        // {
        //     title: t('Active'),
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
        // },
        {
            title: t('Action'),
            key: 'action',
            render: (_, record) => (
                (partyInfo.code === 'readonly') ? <></> :<Space size="middle">
                    <Button type="primary" icon={<EditOutlined />} title={t('Edit') ?? ''} onClick={() => { handleRowClick(record) }} />
                    <Popconfirm
                        title={t("Are you sure you want to delete this item?")}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" title={t('Delete') ?? ''} icon={<DeleteOutlined />} danger
                            key={'con-btn-' + record.id} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    if (type === undefined) {
        columns.splice(0, 0, {
            title: t('City'), dataIndex: 'city_Id', key: 'city',
            width: 150,
            render: (_, record) => <span>{record.city !== null ? record.city?.name : ''}</span>,
        });
        columns.splice(1, 0, {
            title: t('Location Group'), dataIndex: 'address', key: 'address',
            width: 300,
            // render: (_, record) => <span>{record.city !== null ? record.city?.name : ''}</span>,
            render: (address: string) => {
                const party = locationGrouplist?.find((party) => party.value === address);
                return party ? party.label : 'N/A';
            },
        });
    }
    // Use the onClickRowEdit function as needed
    const handleRowClick = (rowData: any) => {
        onClickRowEdit(rowData);
    };

    const handleDelete = (id: any) => {
        console.log('Clicked cancel button');
        locationApi.locationDelete(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };

    useEffect(() => {
        setLoading(true);
        const fetchUserData = async () => {
            try {
                const response = await locationApi.locationGetList(
                    undefined,
                    type
                );
                setUserData(response.data.items ?? []);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchUserData();
        
      fetchlocationGroups()
      .then((response) => {
          var mapedOptions: SelectProps['options'] = response?.map(({ name, code }) => (
            {
              label: name,
              value: code
            }
          ));
          setlocationGrouplist(mapedOptions);
        })
      .catch((error) => {
          console.error('Failed to fetch select options:', error);
          setlocationGrouplist([]);
      });

    }, [refreshTrigger,type]); // Add refreshTrigger as a dependency

  const fetchlocationGroups = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationGroupApi.locationGroupGetList()
        .then((response) => response.data)
        .then((data) => data.items);
};

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };

    return (<>
        {/* <button onClick={handleRefresh}>Refresh Data</button> */}
        <Input.Search
            placeholder={t('Search') ?? ''}
            onSearch={(value) => {
                setSearchText(value);
            }}
            onChange={(e) => {
                setSearchText(e.target.value);
            }}
        ></Input.Search>
        <Table<LocationDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={userData}
            columns={columns}
            loading={loading}
        />
    </>
    );
};

export default LocationsTable;