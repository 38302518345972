import { useTranslation } from "react-i18next";
import React, { useEffect, useState, } from 'react';
import { Button, Cascader, Input, Popconfirm, Select, SelectProps, Space, Table, Tabs, TabsProps, Tag, message } from 'antd';
import type { ColumnsType, } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, LockOutlined, RetweetOutlined, UserOutlined } from '@ant-design/icons';
import { getFullDateTime } from "../../helper";
import { API_URL, Report_URL } from "../../constants";
import { Configuration, ConfigurationParameters, LocationGroupApi, LocationGroupDto, ObjWithChildsDto, PartyDto, SubdivisionApi, SubdivisionDto, UpdateDocumentStatusDto } from "../../api/najd";
import { DocumentApi, DocumentDto, LocationApi, PartyApi } from "../../api/najd";
import { useAuth } from "react-oidc-context";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { TableProps } from "antd/lib/table/InternalTable";
import EditDocumentStatusFormModal from "./EditDocumentStatusFormModal";
import dayjs from "dayjs";
interface DataGridProps {
    type: any;
    partyInfo: PartyDto;
    onClickRowEdit: (rowData: any) => void;
    refreshTrigger: boolean;
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}


const DocumentsTable: React.FC<DataGridProps> = ({ type, onClickRowEdit, refreshTrigger, setRefreshTrigger, partyInfo }) => {
    const { t, i18n } = useTranslation();
    const [userData, setUserData] = useState<DocumentDto[]>([]);
    const [locationGroupFilter, setlocationGroupFilter] = useState<string | undefined | null>(null);
    const [loading, setLoading] = useState(false);
    const [location_IdFilter, setlocation_IdFilter] = useState(partyInfo.code === 'supplier' ? null : partyInfo.location_Id);
    const [cascade_ids, setcascade_ids] = useState<any[]>([]);

    const [city_IdFilter, setcity_IdFilter] = useState(partyInfo.city_Id);
    const [subdivision_IdFilter, setsubdivision_IdFilter] = useState(partyInfo.subdivision_Id);
    const [subdivisionList, setSubdivisionList] = useState<SubdivisionDto[]>([]);
    const [subdivisionListWithDetails, setsubdivisionListWithDetails] = useState<ObjWithChildsDto[]>([]);

    const [documentStatus_Code, setDocumentStatus_Code] = useState(partyInfo.code != 'patient' ? "my-approve" : "approve");
    const [statusOptions, setStatusOptions] = useState<SelectProps['options']>([]);
    const [locationOptions, setLocationOptions] = useState<SelectProps['options']>([]);
    const partnerParty_Id: string | null | undefined = partyInfo.code === 'patient' ? partyInfo.id : null;
    const supplier_Id: string | null | undefined = partyInfo.code === 'supplier' ? partyInfo.id : null;
    const consultant_Id: string | null | undefined = partyInfo.code === 'consultant' ? partyInfo.id : null;

    const [locationGrouplist, setlocationGrouplist] = useState<SelectProps['options']>([]);

    const auth = useAuth();
    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const locationApi = new LocationApi(configuration);
    const documentApi = new DocumentApi(configuration);
    const subdivisionApi = new SubdivisionApi(configuration);
    const locationGroupApi = new LocationGroupApi(configuration);

    const tab_items: TabsProps['items'] = [
        {
            key: 'approve',
            label: t(`Current Requests`),
        },
        {
            key: 'complete',
            label: t(`Complated Requests`),
        },
        {
            key: 'reject',
            label: t(`Rejected Requests`),
        },
    ];
    if (partyInfo.code !== 'patient') {
        tab_items.splice(0, 0, {
            key: 'my-approve',
            label: t(`My Requests`),
        });
    }

    const [searchText, setSearchText] = useState('');

    const columns: ColumnsType<DocumentDto> = [
        {
            title: t('Serial'), dataIndex: 'serial', key: 'serial', width: '40px',
            // filteredValue: [searchText],
            // onFilter: (value, record) => {
            //     return (
            //         String(record.serial)
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase()) ||
            //         String(record.remark)
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase()) ||
            //         String(record.partnerParty?.name)
            //             .toLowerCase()
            //             .includes(value.toString().toLowerCase())
            //     );
            // },
        },
        {
            title: t('Patient'),
            dataIndex: 'partnerParty_Id',
            key: 'partnerParty_Id',
            width: 250,
            render: (_, record) => <span>{record.partnerParty !== null ? `${record.partnerParty?.name} ${record.partnerParty?.middleName ?? ''} ${record.partnerParty?.lastName ?? ''}` : ''}</span>,
            // render: (partnerPartyId: string) => {
            //     const party = patientsOptions?.find((party) => party.value === partnerPartyId);
            //     return party ? party.label : 'N/A';
            // },
        },
        { title: t('Remark'), dataIndex: 'remark', key: 'remark', },
        // { title: t('Date'), dataIndex: 'docDate', key: 'docDate', },
        {
            title: t('Care Center'), dataIndex: 'location_Id', key: 'location_Id',
            width: 375,
            render: (_, record) => <span>{record.location !== null ? record.location?.name : ''}</span>,
        },
        {
            title: t('Status'),
            dataIndex: 'documentStatus_Id',
            key: 'documentStatus_Id',
            width: 150,
            render: (documentStatusId: string) => {
                const party = statusOptions?.find((party) => party.value === documentStatusId);
                return party ? party.label : 'N/A';
            },
            // filters: statusOptions?.map(({ label, value }) => (
            //     {
            //         text: label?.toString(),
            //         value: value?.toString()
            //     }
            // ) as ColumnFilterItem),
            // onFilter: (value, record) => record.documentStatus_Id!.includes(value.toString()),
        },
        {
            title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date)),
            width: 150,
        },
        {
            title: t('Action'),
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" icon={<EditOutlined />} title={t('Details') ?? ''} onClick={() => { handleRowClick(record) }} />
                    <Button hidden={partyInfo.code !== 'admin'} icon={<RetweetOutlined />} title={t('Change Status') ?? ''} onClick={() => { handleChangeStatus(record) }} />
                    <Popconfirm
                        title={t("Are you sure you want to delete this?")}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" icon={<DeleteOutlined />} danger hidden={!(record.allowDelete ?? false)}
                            key={'con-btn-' + record.id} />
                    </Popconfirm>
                    <Button type="default" title={t('PDF') ?? ''} icon={<FilePdfOutlined />}
                        key={'pdf-btn-' + record.id} onClick={() => {
                            const url: string = `${Report_URL}/report/ExportDoc?document_id=${record.id ?? ''}&format=pdf&fileName=Request-${record.serial ?? 0}`;
                            const newTab = window.open(url, '_blank');
                            if (newTab) {
                                newTab.focus();
                            } else {
                                // If the new tab was blocked by the browser's popup blocker,
                                // you can provide a fallback or display an error message.
                                console.error('Failed to open new tab. Please allow popups for this site.');
                            }

                        }} />
                    <Popconfirm
                        title={t("سوف يتم ارسال كود اقفال للطلب على جوال المريض المسجل في بطاقته؟")}
                        onConfirm={() => {
                            handlSentDocumentOtp(record.id ?? '');
                        }}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    > <Button type="default" icon={<LockOutlined />}
                        title={record.enableOtp == true ? "OTP مرسل" : "ارسال OTP"}
                        key={'user-btn-otp-' + record.id} 
                        danger={record.enableOtp == true ? true : false}
                        hidden={(partyInfo.code === 'admin' && record.documentStatus_Id === "3a0c0b5b-a676-36e7-68cc-35fb1b330fbf" ? false : true)} />
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    const handlSentDocumentOtp = (id: any) => {
        console.log('Clicked SentDocumentOtp button');
        documentApi.documentSentDocumentOtp(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
                message.success(t('تم ارسال رمز OTP للمريض'));
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
                message.error(t('خطاء في الارسال'));
            });
    };

    // Use the onClickRowEdit function as needed
    const handleRowClick = (rowData: any) => {
        onClickRowEdit(rowData);
    };
    
    const handleDelete = (id: any) => {
        console.log('Clicked cancel button');
        documentApi.documentDelete(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };
    const fetchLocationOptions = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return locationApi.locationGetList()
            .then((response) => response.data)
            .then((data) => data.items);
    };
    const fetchlocationGroups = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return locationGroupApi.locationGroupGetListByParty()
            .then((response) => response.data)
            .then((data) => data.items);
    };
    
    const fetchSubdivisions = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return subdivisionApi.subdivisionGetList()
            .then((response) => response.data)
            .then((data) => data.items);
    };
    const fetchsubdivisionGetListWithDetails = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return subdivisionApi.subdivisionGetListWithDetails()
            .then((response) => response.data)
            .then((data) => data.items);
    };

    useEffect(() => {

        fetchSubdivisions()
            .then((response) => {
                setSubdivisionList(response ?? []);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
                setSubdivisionList([]);
            });

            fetchlocationGroups()
            .then((response) => {
                var mapedOptions: SelectProps['options'] = response?.map(({ name, code }) => (
                  {
                    label: name,
                    value: code
                  }
                ));
                setlocationGrouplist(mapedOptions);
              })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
                setlocationGrouplist([]);
            });
            
        fetchsubdivisionGetListWithDetails()
            .then((response) => {
                var filter_list: ObjWithChildsDto[] = response ?? [];
                if (partyInfo.subdivision_Id !== null && partyInfo.subdivision_Id !== undefined) {
                    filter_list = filter_list?.filter(x => x.id === partyInfo.subdivision_Id);
                    if (partyInfo.city_Id !== null && partyInfo.city_Id !== undefined) {
                        filter_list[0].childs = filter_list[0].childs?.filter(x => x.id === partyInfo.city_Id);
                    }
                }
                setsubdivisionListWithDetails(filter_list ?? []);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
                setsubdivisionListWithDetails([]);
            });

        fetchLocationOptions()
            .then((response) => {
                if (partyInfo.subdivision_Id != null || partyInfo.city_Id != null || partyInfo.location_Id != null) {
                    console.log('==>filter by location');
                    setLocationOptions(response?.filter(x =>
                        (partyInfo.city_Id == null || x.city_Id == partyInfo.city_Id) &&
                        (partyInfo.location_Id == null || x.id == partyInfo.location_Id) &&
                        (partyInfo.subdivision_Id == null || x.city?.subdivision_Id == partyInfo.subdivision_Id)
                    )
                        .map(({ name, id }) => (
                            {
                                label: name,
                                value: id
                            }
                        )));
                } else {
                    console.log('==>no filter by location');
                    setLocationOptions(response?.map(({ name, id }) => (
                        {
                            label: name,
                            value: id
                        }
                    )));

                }
                // if (partyInfo.subdivision_Id == null) {
                //     setsubdivision_IdFilter(response![0].city?.subdivision_Id);
                // }
                // if (partyInfo.city_Id == null) {
                //     setcity_IdFilter(response![0].city_Id);
                // }
                if (partyInfo.location_Id == null && partyInfo.code !== 'supplier') {
                    setlocation_IdFilter(response![0].id);
                }
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });

        fetchStatus()
            .then((response) => {
                var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
                    {
                        label: name,
                        value: id
                    }
                ));
                setStatusOptions(mapedOptions);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });


    }, []); // Add refreshTrigger as a dependency
    const [totalCount, setTotalCount] = useState(1);
    const fetchRecords = async (page: any, pageSize: any) => {
        setLoading(true);
        if (location_IdFilter != null || locationGroupFilter != null) {
            const doc_code = documentStatus_Code === 'my-approve' ? 'approve' : documentStatus_Code;
            const currentUserDoc = documentStatus_Code === 'my-approve' ? true : undefined;
            try {
                const skipRecord = (page - 1) * pageSize;
                const response = await documentApi.documentGetListLazy(
                    searchText,
                    undefined,
                    partnerParty_Id ?? undefined,
                    undefined,
                    supplier_Id ?? undefined,
                    consultant_Id ?? undefined,
                    undefined,
                    undefined,
                    undefined,
                    location_IdFilter ?? undefined,
                    locationGroupFilter ?? undefined,
                    city_IdFilter ?? undefined,
                    subdivision_IdFilter ?? undefined,
                    undefined,
                    currentUserDoc,
                    doc_code,
                    undefined,
                    undefined,
                    skipRecord,
                    pageSize

                );
                setUserData(response.data.items ?? []);
                setTotalCount(response.data.totalCount ?? 0);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        const page = 1;
        fetchRecords(page, 10);
    }, [type, documentStatus_Code, location_IdFilter, searchText, locationGroupFilter]);

    const [paginationPage, setPaginationPage] = useState(1);
    useEffect(() => {
        const page = paginationPage;
        fetchRecords(page, 10);
    }, [refreshTrigger]);
    /*
    useEffect(() => {

        setLoading(true);
        const fetchUserData = async () => {
            if (location_IdFilter != null) {
                const doc_code = documentStatus_Code === 'my-approve' ? 'approve' : documentStatus_Code;
                try {
                    const response = await documentApi.documentGetList(
                        undefined,
                        undefined,
                        partnerParty_Id ?? undefined,
                        undefined,
                        supplier_Id ?? undefined,
                        consultant_Id ?? undefined,
                        undefined,
                        undefined,
                        undefined,
                        location_IdFilter ?? undefined,
                        city_IdFilter ?? undefined,
                        subdivision_IdFilter ?? undefined,
                        undefined,
                        undefined,
                        doc_code,
                    );
                    if (documentStatus_Code === 'my-approve') {
                        var filted_data = response.data.items ?? [];
                        // if(partyInfo.code === 'po' || partyInfo.code === 'director'|| partyInfo.code === 'social' ){
                        //     filted_data = filted_data?.filter((x) => x.allowApprove == true && (subdivision_IdFilter == null || x.location?.city?.subdivision_Id == subdivision_IdFilter));
                        // }else{
                        //     filted_data = filted_data?.filter((x) => x.allowApprove == true);
                        // }
                        filted_data = filted_data?.filter((x) => x.allowApprove == true);
                        setUserData(filted_data);
                    } else {
                        setUserData(response.data.items ?? []);
                    }
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchUserData();

    }, [refreshTrigger, type, documentStatus_Code, location_IdFilter]); // Add refreshTrigger as a dependency
*/
    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };

    const fetchStatus = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return documentApi.documentGetStatusList(
        )
            .then((response) => response.data)
            .then((data) => data.items);
    };
    const onChangeTab = (key: string) => {
        setDocumentStatus_Code(key);
    };
    const handleLocationChange = (value: string) => {
        setlocation_IdFilter(value);
    };
    const handleLocationGroupChange = (value: string) => {
        setlocation_IdFilter(null);
        setcascade_ids([]);
        setlocationGroupFilter(value);
    };
    // const operations = <Select
    //     defaultValue={location_IdFilter}
    //     style={{ width: 375 }}
    //     onChange={handleLocationChange}
    //     options={locationOptions}
    // />;
    const onChangeCascader = (value: any[]) => {
        console.log(value);
        if (value == null || value == undefined) {
            setlocation_IdFilter(null);
            setcascade_ids([]);
        } else {
            setlocationGroupFilter(null);
            setlocation_IdFilter(value[2]);
            setcascade_ids(value);
        }
    };
    const operations = <>
        <Select
            showSearch={true}
            style={{ width: 400 }}
            onChange={handleLocationGroupChange}
            direction={i18n.dir()}
            allowClear={false}
            options={locationGrouplist}
            value={locationGroupFilter}
            placeholder={"اختر المركز الرئيسي"}
        />
        <Cascader
            // defaultValue={[location_IdFilter]}
            style={{ width: 400 }}
            onChange={onChangeCascader}
            direction={i18n.dir()}
            fieldNames={{ label: 'label', value: 'id', children: 'childs' }}
            allowClear={false}
            options={subdivisionListWithDetails}
            value={cascade_ids}
            placeholder={"اختر مركز محدد"}
        // dropdownStyle={{height:800}}
        // changeOnSelect
        /></>;

    function removeSpacesFromString(str: string): string {
        return str.replace(/^\s+|\s+$/g, '');
    }

    const [open, setOpen] = useState(false);
    const [documentStatusDto, setDocumentStatusDto] = useState<UpdateDocumentStatusDto>({});

    const handleChangeStatus = (rowData: any) => {
        const data_item : UpdateDocumentStatusDto = {
document_Id : rowData.id,
documentStatus_Id : rowData.documentStatus_Id
        };
        setDocumentStatusDto(data_item);
        setOpen(true);
       
    };
    const onEdit = (values: UpdateDocumentStatusDto) => {
        console.log('Received values of form: ', values);
        try {
            if (values.stateDateTime != null)
                values.stateDateTime = dayjs(values?.stateDateTime).format("YYYY-MM-DDTHH:mm:ss");

            documentApi.documentUpdateDocumentStatus(values)
                .then((response) => {
                    setOpen(false);
                    handleRefresh();
                })
                .catch((error) => {
                    console.error('Failed to fetch select options:', error);
                });
            
        } catch (error) {
            
        }
    };
    return (<>
        {/* <button onClick={handleRefresh}>Refresh Data</button> */}
        <Tabs
            tabBarExtraContent={partyInfo.location_Id != null || partyInfo.code === 'supplier' ? undefined : operations}
            defaultActiveKey={partyInfo.code != 'patient' ? "my-approve" : "approve"}
            type="card"
            items={tab_items}
            onChange={onChangeTab} />
        <Input.Search
            placeholder={t('Search') ?? ''}
            onSearch={(value) => {
                setSearchText(removeSpacesFromString(value));
            }}
            onChange={(e) => {
                setSearchText(removeSpacesFromString(e.target.value));
            }}
        ></Input.Search>
        
         <EditDocumentStatusFormModal
                // key={'edit-model-' + selected_item.id}
                open={open}
                onEdit={onEdit}
                onCancel={() => setOpen(false)}
                initialValues={documentStatusDto}
            /> 
        <Table<DocumentDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={userData}
            columns={columns}
            loading={loading}
            pagination={{
                total: totalCount,
                onChange: (page, pageSize) => {
                    setPaginationPage(page);
                    fetchRecords(page, pageSize);
                },
            }}
        />
    </>
    );
};

export default DocumentsTable;