import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch, Upload } from 'antd';
import { CategoryApi, CityApi, Configuration, LocationApi, LocationGroupApi, PartyApi } from "../../api/najd";
import { API_URL, API_URL_Upload, Rresource_Path } from "../../constants";
import dayjs from "dayjs";
import { UploadOutlined } from "@ant-design/icons";
interface FormDrawerProps {
  formData: any; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
  type: string | undefined;
}

export const LocationsForm: React.FC<FormDrawerProps> = ({ formData, onSubmit, onChange, onClose,type }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook
  const [locationGrouplist, setlocationGrouplist] = useState<SelectProps['options']>([]);

  useEffect(() => {
    form.setFieldsValue(formData); // Set initial form values when rowData changes
    console.log('changes => ', formData);
  }, [form, formData]);

  const [options, setOptions] = useState<SelectProps['options']>([]);

  const configuration = new Configuration({
    basePath: API_URL,
  });
  const categoryApi = new CategoryApi(configuration);
  const partyApi = new PartyApi(new Configuration({ basePath: API_URL, }));
  const [cityOptions, setCityOptions] = useState<SelectProps['options']>([]);
  const locationApi = new LocationApi(new Configuration({ basePath: API_URL, }));
  const cityApi = new CityApi(new Configuration({ basePath: API_URL, }));
  const locationGroupApi = new LocationGroupApi(configuration);
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
    fetchCities()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setCityOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

      fetchlocationGroups()
      .then((response) => {
          var mapedOptions: SelectProps['options'] = response?.map(({ name, code }) => (
            {
              label: name,
              value: code
            }
          ));
          setlocationGrouplist(mapedOptions);
        })
      .catch((error) => {
          console.error('Failed to fetch select options:', error);
          setlocationGrouplist([]);
      });
  }, []); // Run this effect only once when the component mounts

  const fetchlocationGroups = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationGroupApi.locationGroupGetList()
        .then((response) => response.data)
        .then((data) => data.items);
};

  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList('item')
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchCities = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return cityApi.cityGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      values.logo = normlizeFileVariable(values.logo);
      
      // if (values.startDate != null)
      //   values.startDate = dayjs(values?.startDate).format("YYYY-MM-DD")
      

      // if (values.endDate != null)
      //   values.endDate = dayjs(values?.endDate).format("YYYY-MM-DD")
      
      onSubmit(values);
    });
  };

  function normlizeFileVariable(value: null | string | any): string | null {
    if (value === null) {
      return null;
    } else if (typeof value === "string") {
      // Variable is a string
      return value;
    } else if (typeof value === "object") {
      // Variable is an object
      return value.name;
    } else {
      return value;
    }
  }
  const handleValuesChange = (changedValues: any, allValues: any) => {
    onChange(allValues);
  };

  const getValueFromEvent = (e: any) => {
    if (e && e.file) {
      return e.file;
    }
    return e && e.target && e.target.value;
  };
  
  const beforeUpload = (file: File) => {
    // Generate a timestamp for the file name
    const timestamp = Date.now();
    const newFileName = `${timestamp}${file.name.substring(file.name.lastIndexOf('.'))}`;

    // Modify the file object to use the new file name
    Object.defineProperty(file, 'name', {
      writable: true,
      value: newFileName,
    });

    // Add the timestamp to the form values
    const formValues = {
      file,
      timestamp,
    };

    // Update the form values before submitting
    form.setFieldsValue(formValues);

    return true;
  };
  
  const renderFileLink = (filePath: any) => {
    if (filePath != null && filePath != '') {
      return (
        <img src={Rresource_Path + filePath} height={100} width={100}></img>
      );
    }
    return null;
  };
  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={formData} onValuesChange={handleValuesChange} >
    <Form.Item name="type" label={t("type")} hidden={true}>
      <Input disabled />
    </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="id" label="ID" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label={t("Name")} rules={[{ required: true, message: t('Please enter required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="city_Id"
            label={t("City")}
            hidden={type == undefined ? false : true}
            rules={[{ required: type == undefined ? true : false, message: 'Please enter required field' }]}>
            <Select options={cityOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="address"
            label={t("Location Group")}
            hidden={type == undefined ? false : true}
            rules={[{ required: type == undefined ? true : false, message: 'Please enter required field' }]}>
            <Select options={locationGrouplist} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="address"
            label={t("Address")}
            rules={[{ required: true, message: 'Please enter required field' }]}>
            <Input.TextArea rows={2} placeholder={t("please enter description") ?? ''} />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="registerPartyByPassportId" 
            hidden={type == undefined ? false : true}
            label={t("Register Patient By Passport ID")} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
        <Col >
          <Form.Item name="supllierContractDays"
            hidden={type == undefined ? false : true}
             label={t("Supllier Contratc Days")}>
            <InputNumber min={0} max={99}
             />
          </Form.Item>
        </Col>
        
      <Col span={8}>
        <Form.Item
          name="startDate"
          label={t("Start Date")}
          hidden={type == undefined ? true : false}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="endDate"
          label={t("End Date")}
          hidden={type == undefined ? true : false}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
      </Col>
      <Col span={6}>
          <Form.Item
            name='logo'
            label={t('Logo')}
            getValueFromEvent={getValueFromEvent}
            hidden={type == undefined ? true : false}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.logo)}
          </Form.Item>
        </Col>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="active" label={t("Active")} valuePropName="checked" hidden={(formData.id == null) ? true : false}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form>);
};