import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Header } from 'antd/es/layout/layout';

interface FormDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  formData: any; // Prop to receive the rowData
  title: string; // Prop to receive the rowData
  children: React.ReactNode; // Prop to receive children
}
const FormDrawer: React.FC<FormDrawerProps> = ({ open, onClose, onSubmit,title, formData, children }) => {
  const { t } = useTranslation();
  
  // const editMode = (formData.id!= null)? true : false;
  // const editMode = ((editMode)? t("Edit") : t("Create new") );
  const drawerStyles = {
    header: {
      backgroundColor: '#397551', // Set the desired header background color
    },
  };
  return (
    <Drawer
      title={title}
      width={720}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      drawerStyle={drawerStyles as React.CSSProperties}
      
      // extra={
      //   <Space>
      //     <Button onClick={onClose}>{t("Cancel")}</Button>
      //     <Button onClick={onSubmit} type="primary">
      //       {t("Submit")}
      //     </Button>
      //   </Space>
      // }
    >
      {children}
    </Drawer>
  );
};

export default FormDrawer;