import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form, Space, message } from "antd";
import { LoginForm, PageContainer, ProCard, ProConfigProvider, ProFormCaptcha, ProFormCheckbox, ProFormText, } from '@ant-design/pro-components';

import 'antd/dist/reset.css';
import { Dashboard } from "./dashboard/Dashboard";
import { CSSProperties, useEffect, useState } from "react";
import { LockOutlined, MobileOutlined } from "@ant-design/icons";
import { Configuration, ConfigurationParameters, PartyApi, ProfileApi, ProfileDto } from "../api/najd";
import { API_URL } from "../constants";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

export const SigninCallback = () => {
  const auth = useAuth();
  const [formData, setUserData] = useState<ProfileDto>({});
  const [form] = Form.useForm(); // Create a form instance using the useForm hook
  const { t } = useTranslation();

  const token = auth.user?.access_token;

  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const profileApi = new ProfileApi(configuration);
  const partyApi = new PartyApi(configuration);

  const [secrectCode, setSecrectCode] = useState<string>("0000");
  const generateRandomNumber = (): number => {
    return Math.floor(Math.random() * 9000) + 1000;
  };

  useEffect(() => {
    // setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await profileApi.profileGet();
        setUserData(response.data);
        // setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []); // Add refr

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);

  const iconStyles: CSSProperties = {
    marginInlineStart: '16px',
    color: 'rgba(0, 0, 0, 0.2)',
    fontSize: '24px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  };

  const saveBooleanToLocalStorage = (value: boolean) => {
    localStorage.setItem('otpAuth', JSON.stringify(value));
  };

  const navigate = useNavigate();
  const handleSubmit = async (values: any) => {
    console.log('Form values:', values);
    if (values.captcha === secrectCode) {
      saveBooleanToLocalStorage(true);
      message.success(t('Login Successfully'));
      navigate('/');
      window.location.reload();
      // Perform your submit logic here
      // Return a Promise that resolves to a boolean or void
      return true; // Replace with your own logic
    } else {
      message.error(t('Not Correct Code!'));
      return false; // Replace with your own logic
    }
  };
  return (
    <ProConfigProvider hashed={false}>
      <div style={{ backgroundColor: 'white' }}>
        <LoginForm
          form={form}
          logo={<img src="/FullLogo.png" className="login-logo" alt="Logo" />}
          // title={t('AppName')}
          subTitle={t('AppName')}
          onFinish={handleSubmit}
        // actions={
        //   <Space>
        //     2222
        //      <AlipayCircleOutlined style={iconStyles} />
        //     <TaobaoCircleOutlined style={iconStyles} />
        //     <WeiboCircleOutlined style={iconStyles} /> 
        //   </Space>
        // }
        >
          <>
            <ProFormText
              fieldProps={{
                size: 'large',
                prefix: <MobileOutlined className={'prefixIcon'} />,
              }}
              name="phoneNumber"
              placeholder={'mobile'}
              disabled={true}
              rules={[
                {
                  required: true,
                  message: t('Required field') ?? '',
                },
                // {
                //   pattern: /^1\d{10}$/,
                //   message: t('Phone number not correct!')??'',
                // },
              ]}
            />
            <ProFormCaptcha
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              captchaProps={{
                size: 'large',
              }}
              placeholder={t('Please enter verification code!') ?? ''}
              captchaTextRender={(timing, count) => {
                if (timing) {
                  if (count == 1) {
                    console.log('xxxx');
                    setSecrectCode('xxxx');
                  }
                  return `${count} ${t('get verification code') ?? ''}`;
                }
                return t('get verification code') ?? '';
              }}
              name="captcha"
              rules={[
                {
                  required: true,
                  message: t('Please enter verification code!') ?? '',
                },
              ]}
              onGetCaptcha={async () => {
                const randomFourDigitNumber: number = generateRandomNumber();
                console.log(randomFourDigitNumber.toString());

                partyApi.partySetndVerficationCode({phone:formData.phoneNumber,text: `كود الدخول : ${randomFourDigitNumber}`})
                .then(response => {
                    // Handle the API response
                    message.success(t('Get the verification code successfully!'));
                    setSecrectCode(randomFourDigitNumber.toString());
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                    message.error(error);
                });
              }}
            />
          </>

          <div
            style={{
              marginBlockEnd: 24,
            }}
          >
            {/* <ProFormCheckbox noStyle name="autoLogin">
              333
            </ProFormCheckbox>
            <a
              style={{
                float: 'right',
              }}
            >
              4444
            </a> */}
          </div>
        </LoginForm>
      </div>
    </ProConfigProvider>
  );
};
