import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { DatePicker, Radio, Select, SelectProps, Space } from "antd";
import { AsnaniReportFilterDto, CategoryApi, ChartStaticsDto, Configuration, ConfigurationParameters, DocumentApi, ItemApi, PartyApi, PartyDto, ReportApi, ReportFilterDto, } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import MyPieChart from "../../components/PieChart";
const { RangePicker } = DatePicker;

export default () => {

  const { t,i18n } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const auth = useAuth();
  const [groupby, set_groupby] = useState<string | null>(null);
  const [categories, setCategory] = useState<SelectProps['options']>([]);
  const [selectedCategory, setselectedCategory] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);
  const categoryApi = new CategoryApi(configuration);

  /***end get options */


  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };

  useEffect(() => {
    if (selectedDateRange != null) {
      getData();
    }
  }, [selectedDateRange,selectedCategory,groupby]); // Run this effect only once when the component mounts
  useEffect(() => {
    // Fetch the select options from the API
    fetchCategoryOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id, active, code }) => (
          {
            label: `${code} - ${name} ${active == true ? t("") : "/ (" + t("InActive") + ")"} `,
            value: name
          }
        ));
        console.log("active item count : ", mapedOptions?.length)
        setCategory(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []);
  const fetchCategoryOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList('asnani_services')
      .then((response) => response.data)
      .then((data) => data.items);
  };
  
  const [dataGrouped, setdataGrouped] = useState<GroupedDataItem[]>([]);
  type GroupedDataItem = {
    date: string,
    data: {
      [subdivision: string]: number
    }
  };

  const getData = () => {
    const reportFilterDto: AsnaniReportFilterDto = {
      fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
      // toDate: dayjs(selectedDateRange![1]).endOf('month').format('YYYY-MM-DD')
      toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD'),
      serviceStatus:groupby,
      typeText:selectedCategory
    };
    reportApi.reportAsnaniSttisticReport(
      reportFilterDto
    )
      .then((response) => {
        setData(response.data ?? []);
        var dd = groupByDateAndSubdivision(response.data);
        console.log(dd);
        setdataGrouped(dd);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };
  
  type GroupedData = GroupedDataItem[];
  function groupByDateAndSubdivision(records: ChartStaticsDto[]): GroupedData {
    const tempData: {
      [date: string]: {
        [subdivision: string]: number
      }
    } = {};

    for (const record of records) {
      const date = record.date;
      const subdivision = record.name || 'Unknown';  // Fallback to 'Unknown' if subdivision_Name is null or undefined

      if (!tempData[date!]) {
        tempData[date!] = {};
      }

      if (!tempData[date!][subdivision]) {
        tempData[date!][subdivision] = 0;
      }

      tempData[date!][subdivision] += record.value!;
    }

    const groupedData: GroupedData = Object.entries(tempData).map(([date, data]) => ({
      date,
      data
    }));

    return groupedData;
  }

  const COLORS = [colorPrimary, colorSecondory, '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F02242', '#075242', '#375242'];

  const handleCategoryChange = (value: string) => {
    setselectedCategory(value);
  };
  return (
    <div style={{ width: '100%', height: 700 }}>
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            direction={i18n.dir()}
            style={{ minWidth: '300px' }}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
            picker="month"
          />
          <Title level={5}>{t('الخدمة')}</Title>
          <Select
            showSearch
            allowClear={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleCategoryChange}
            options={categories}
            placeholder={t("اختر الخدمة")}
            style={{ minWidth: '600px' }}
            value={selectedCategory}
          />
          <Title level={5}>{t('حالة الخدمة')}</Title>
          <Radio.Group defaultValue={groupby} buttonStyle="solid" >
            <Radio.Button value={null} onClick={() => { set_groupby(null); }}>{t("الكل")}</Radio.Button>
            <Radio.Button value="تم" onClick={() => { set_groupby("تم"); }}>{t("تم")}</Radio.Button>
            <Radio.Button value="لا" onClick={() => { set_groupby("لا"); }}>{t("لا")}</Radio.Button>
          </Radio.Group>
        </Space>
      </div>
      <div style={{ width: '100%', height: 300 }}>
        <h2 style={{ textAlign: "center" }}>احصائي بعدد المرضى اسناني</h2>
        
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={dataGrouped}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dateStr" />
              <YAxis />
              <Tooltip />
              {data.map((entry, index) => (
                <Bar name={`${entry.name}`} dataKey={`data.${entry.name}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <Legend />
            </BarChart>
          </ResponsiveContainer>
      </div>
    </div>

  );

};