import { useTranslation } from "react-i18next";
import { Button, Dropdown } from "antd";
import { PageContainer, ProCard, } from '@ant-design/pro-components';

import 'antd/dist/reset.css';
import { PartyDto } from "../../api/najd";
import { useParams } from "react-router-dom";
import ReportsItems from "./ReportsItems";
import ReportsPatients from "./ReportsPatients";
import ReportsRequest from "./ReportsRequest";
import ReportsGender from "./ReportsGender";
import ReportsRequestDetails from "./ReportsRequestDetails";
import ReportsPatientsByYear from "./ReportsPatientsByYear";
import { PrinterOutlined } from "@ant-design/icons";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import ReportsItemsStatics from "./ReportsItemsStatics";
import ReportsItemsStatics2 from "./ReportsItemsStatics2";
import ReportsPatientsPercByYear from "./ReportsPatientsPercByYear";
import ReportsRequestGender from "./ReportsRequestGender";
import AsnaniReports from "../ReportUpload/AsnaniReports";
import AsnaniGenderReport from "../ReportUpload/AsnaniGenderReport";
import AsnaniStatisticReport from "../ReportUpload/AsnaniStatisticReport";

interface ReportsProps {
    partyInfo: PartyDto;
}
export const Reports: React.FC<ReportsProps> = ({ partyInfo }) => {
    const { t, i18n } = useTranslation();
    const { report } = useParams(); // Access the "type" parameter
    const ref = useRef<HTMLDivElement>();

    return (
        <PageContainer
            header={{
                title: t('Home'),
                ghost: true,
                // breadcrumb: {
                //     items: [
                //         {
                //             path: '',
                //             title: 'خيار 1',
                //         },
                //         {
                //             path: '',
                //             title: 'خيار2 ',
                //         },
                //         {
                //             path: '',
                //             title: 'خيار 3',
                //         },
                //     ],
                // },
                extra: [
                    /*<ReactToPrint
                        bodyClass="print-agreement"
                        content={() => ref.current!}
                        trigger={() => (<Button type="primary" icon={<PrinterOutlined />}>
                            {t('Print')}
                        </Button>)}
                    />*/
                ]
            }}>
            <ProCard ref={ref} style={{ direction: i18n.dir() }}>
                {report === 'items' ? <ReportsItems /> : <></>}
                {report === 'ItemsStatics' ? <ReportsItemsStatics /> : <></>}
                {report === 'ItemsStatics2' ? <ReportsItemsStatics2 /> : <></>}
                {report === 'patient' ? <ReportsPatients /> : <></>}
                {report === 'patient-by-year' ? <ReportsPatientsByYear /> : <></>}
                {report === 'request' ? <ReportsRequest /> : <></>}
                {report === 'gender' ? <ReportsGender /> : <></>}
                {report === 'request-gender' ? <ReportsRequestGender /> : <></>}
                {report === 'request-details' ? <ReportsRequestDetails /> : <></>}
                {report === 'patient-perc-by-year' ? <ReportsPatientsPercByYear /> : <></>}
                {report === 'asnani-reports' ? <AsnaniReports /> : <></>}
                {report === 'asnani-gender' ? <AsnaniGenderReport /> : <></>}
                {report === 'asnani-statistic' ? <AsnaniStatisticReport /> : <></>}
                
            </ProCard>


        </PageContainer>
    );
};
