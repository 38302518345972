import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Checkbox, DatePicker, Radio, Select, SelectProps, Space } from "antd";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportFilterDto, ItemApi, ReportApi, SubdivisionApi, CategoryApi, LocationGroupApi } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CheckboxChangeEvent } from "antd/es/checkbox";
const { RangePicker } = DatePicker;

export default () => {

  const { t, i18n } = useTranslation();
  const [items, setitems] = useState<SelectProps['options']>([]);
  const [categories, setCategory] = useState<SelectProps['options']>([]);
  const [showOnlyActiveItem, setShowOnlyActiveItem] = useState<boolean>(true);
  const [subdivisionList, setsubdivisionList] = useState<SelectProps['options']>([]);
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const auth = useAuth();
  const [date_range_by, setdate_range_by] = useState<string>('post_to_supplier_time');
  const [locationGrouplist, setlocationGrouplist] = useState<SelectProps['options']>([]);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const itemApi = new ItemApi(configuration);
  const categoryApi = new CategoryApi(configuration);
  const subdivisionApi = new SubdivisionApi(configuration);
  const reportApi = new ReportApi(configuration);
  const locationGroupApi = new LocationGroupApi(configuration);
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.filter(x =>
          showOnlyActiveItem == false || (showOnlyActiveItem == true && x.active == true)
        ).map(({ name, id, active, code }) => (
          {
            label: `${code} - ${name} ${active == true ? t("") : "/ (" + t("InActive") + ")"} `,
            value: id
          }
        ));
        console.log("active item count : ", mapedOptions?.length)
        setitems(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, [showOnlyActiveItem]); // Run this effect only once when the component mounts
  useEffect(() => {
    fetchOptions2()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        console.log("active item count : ", mapedOptions?.length)
        setsubdivisionList(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
      
    // Fetch the select options from the API
    fetchCategoryOptions()
    .then((response) => {
      var mapedOptions: SelectProps['options'] = response?.map(({ name, id, active, code }) => (
        {
          label: `${code} - ${name} ${active == true ? t("") : "/ (" + t("InActive") + ")"} `,
          value: id
        }
      ));
      console.log("active item count : ", mapedOptions?.length)
      setCategory(mapedOptions);
    })
    .catch((error) => {
      console.error('Failed to fetch select options:', error);
    });
    
    fetchlocationGroups()
    .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, code }) => (
          {
            label: name,
            value: code
          }
        ));
        setlocationGrouplist(mapedOptions);
      })
    .catch((error) => {
        console.error('Failed to fetch select options:', error);
        setlocationGrouplist([]);
    });


  }, []);
  
  const fetchlocationGroups = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationGroupApi.locationGroupGetList()
        .then((response) => response.data)
        .then((data) => data.items);
};

  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return itemApi.itemGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchCategoryOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList('item')
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchOptions2 = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return subdivisionApi.subdivisionGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  const [selectedItem, setselectedItem] = useState<string | null>(null);
  const [selectedCategory, setselectedCategory] = useState<string | null>(null);
  const [selectedsubdivision, setselectedsubdivision] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const handleItemChange = (value: string) => {
    setselectedCategory(null);
    setselectedItem(value);
  };
  const handleCategoryChange = (value: string) => {
    setselectedItem(null);
    setselectedCategory(value);
  };
  const handlesubdivisionChange = (value: string) => {
    setselectedsubdivision(value);
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  useEffect(() => {
    if (selectedDateRange != null && (selectedItem != null || selectedCategory != null)) {
      getData();
    }
  }, [selectedItem, selectedDateRange, selectedLocation,selectedCategory,date_range_by]); // Run this effect only once when the component mounts

  const getData = () => {
    const reportFilterDto: ReportFilterDto = {
      item_Id: selectedItem,
      category_Id:selectedCategory,
      locationGroup: selectedLocation,
      dateRangeBy:date_range_by,
      fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
      toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD')
    };
    reportApi.reportItemCountStaticsV2(
      reportFilterDto
    )
      .then((response) => {
        if(selectedLocation != null){
          setData(response.data);

        }else{
          setData(sumValuesByGroup(response.data));
        }
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };
  const sumValuesByGroup = (items: ChartStaticsDto[]): ChartStaticsDto[] => {
    const grouped: { [key: string]: ChartStaticsDto } = {};
  
    items.forEach(item => {
      const groupKey = `${item.key}-${item.name}-${item.dateStr}`;
  
      if (!grouped[groupKey]) {
        grouped[groupKey] = {
          key: item.key,
          name: item.name,
          dateStr: item.dateStr,
          color:null,
          date:null,
          value: 0,
        };
      }
  
      grouped[groupKey].value = (grouped[groupKey].value??0) + (item.value??0);
    });
  
    return Object.values(grouped);
  };
  // const renderCustomizedLabel = (props : any) => {
  //   const { x, y, width, height, value } = props;
  //   const radius = 10;

  //   return (
  //     <g>
  //       <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
  //       <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
  //         {value.split(' ')[1]}
  //       </text>
  //     </g>
  //   );
  // };
  const onChangeShowOnlyActiveItem = (e: CheckboxChangeEvent) => {
    setShowOnlyActiveItem(e.target.checked);
  };
  return (
    <div style={{ width: '100%', height: 700 }}>
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Item')}</Title>
          <Select
            showSearch
            allowClear={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleItemChange}
            options={items}
            placeholder={t("اختر صنف")}
            style={{ minWidth: '600px' }}
            value={selectedItem}
          />
          <Checkbox value={showOnlyActiveItem} checked={showOnlyActiveItem} onChange={onChangeShowOnlyActiveItem}>{t("عرض الاصناف الفعالة فقط")}</Checkbox>
          <Title level={5}>{t('الفئة')}</Title>
          <Select
            showSearch
            allowClear={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleCategoryChange}
            options={categories}
            placeholder={t("اختر الفئة")}
            style={{ minWidth: '600px' }}
            value={selectedCategory}
          />
          <Title level={5}>{t('المركز')}</Title>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleLocationChange}
            options={locationGrouplist}
            placeholder={t("اختر المركز")}
            style={{ minWidth: '600px' }}
            allowClear
          />

          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            direction={i18n.dir()}
            style={{ minWidth: '300px' }}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />
          <Radio.Group defaultValue={date_range_by} buttonStyle="solid" >
            <Radio.Button value="post_to_supplier_time" onClick={() => { setdate_range_by("post_to_supplier_time"); }}>{t("حسب تاريخ امر الشراء")}</Radio.Button>
            <Radio.Button value="status_time" onClick={() => { setdate_range_by("status_time"); }}>{t("حسب تاريخ التسليم")}</Radio.Button>
          </Radio.Group>
        </Space>
      </div>
      <div style={{ width: '100%', height: 400 }}>
        <h3 style={{ textAlign: "center" }}>أحصائية الصنف</h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            // height={400}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dateStr" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar name={data.length > 0 ? data[0].name! : ""} dataKey="value" fill={colorPrimary} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>

  );

};