import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { colorPrimary, colorSecondory } from "../../constants";
import { useEffect, useState } from "react";
import { API_URL } from "../../constants";
import MyPieChart from "../../components/PieChart";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportApi, TowMonthChartStaticsDto } from "../../api/najd";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import dayjs from "dayjs";

export default () => {
  const { t } = useTranslation();
  const [data, setData] = useState<TowMonthChartStaticsDto[]>([]);
  const auth = useAuth();
  
  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);


  useEffect(() => {
    getData();
  }, []); // Run this effect only once when the component mounts

  const getData = () => {
    reportApi.reportTwoMonthRequestReport()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };
  const currentDate = new Date();
  const previousDate = new Date(dayjs().subtract(1, 'month').format('YYY-MM-DD'));
  const monthName = currentDate.toLocaleString('ar-EG', { month: 'long' });
  const previousMonthName= previousDate.toLocaleString('ar-EG', { month: 'long' });
  
  console.log(monthName); // Output: نوفمبر
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        // width={600}
        // height={400}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar name={previousMonthName} dataKey="previousMonth" fill={colorPrimary} />
        <Bar name={monthName} dataKey="currentMonth" fill={colorSecondory} />
      </BarChart>
    </ResponsiveContainer>
  );

};