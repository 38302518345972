import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Table, Tag } from 'antd';
import type { ColumnsType, } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, KeyOutlined } from '@ant-design/icons';
import { getFullDateTime } from "../../helper";
import { API_URL } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Configuration, IdentityUserDto, ItemDto, UserApi } from "../../api/najd";

interface DataGridProps {
    onClickRowEdit: (rowData: any) => void;
    onClickRowEditPerm: (rowData: any) => void;
    refreshTrigger: boolean;
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const UsersTable: React.FC<DataGridProps> = ({ onClickRowEdit,onClickRowEditPerm, refreshTrigger, setRefreshTrigger }) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const [userData, setUserData] = useState<IdentityUserDto[]>([]);
    const [loading, setLoading] = useState(false);

    const token = auth.user?.access_token;
    const configuration = new Configuration({
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    });
    const userApi = new UserApi(configuration);

    const columns: ColumnsType<IdentityUserDto> = [
        { title: t('User Name'), dataIndex: 'userName', key: 'userName', },
        { title: t('Name'), dataIndex: 'name', key: 'name',},
        { title: t('Email'), dataIndex: 'email', key: 'email',},
        { title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date))},
        {
            title: t('Active'),
            dataIndex: 'isActive',
            key: 'isActive',
            render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
        },
        // {
        //     title: t('Active'),
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
        // },
        {
            title: t('Action'),
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" icon={<EditOutlined />} onClick={() => { handleRowClick(record) }} />
                    <Button type="primary" icon={<KeyOutlined />} onClick={() => { handleRowClickPerm(record) }} />
                    <Popconfirm
                        title={t("Are you sure you want to delete this item?")}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" icon={<DeleteOutlined />} danger
                            key={'con-btn-' + record.id} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    // Use the onClickRowEdit function as needed
    const handleRowClick = (rowData: any) => {
        onClickRowEdit(rowData);
    };
    // Use the onClickRowEdit function as needed
    const handleRowClickPerm = (rowData: any) => {
        onClickRowEditPerm(rowData);
    };

    const handleDelete = (id: any) => {
        console.log('Clicked cancel button');
        userApi.userDelete(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };

    useEffect(() => {
        setLoading(true);
        const fetchUserData = async () => {
            try {
                const response = await userApi.userGetList();
                setUserData(response.data.items ?? []);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchUserData();
    }, [refreshTrigger]); // Add refreshTrigger as a dependency

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };

    return (<>
        {/* <button onClick={handleRefresh}>Refresh Data</button> */}
        <Table<ItemDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={userData}
            columns={columns}
            loading={loading}
        />
    </>
    );
};

export default UsersTable;