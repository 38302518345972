import React, { useEffect, useState } from "react";
import { API_URL, Report_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Button, Checkbox, DatePicker, Radio, Select, SelectProps, Space, message } from "antd";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportFilterDto, ItemApi, ReportApi, SubdivisionApi, CategoryApi, ItemDto, LocationGroupApi, LocationApi, AsnaniApi } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CheckboxChangeEvent } from "antd/es/checkbox";
const { RangePicker } = DatePicker;

export default () => {

  const { t, i18n } = useTranslation();
  const [report_type, setreport_type] = useState<string>('simple');
  const auth = useAuth();
  const [locationslist, setlocationslist] = useState<SelectProps['options']>([]);
  const [asnaniList, setasnaniList] = useState<SelectProps['options']>([]);

  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedAsnani, setselectedAsnani] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const locationApi = new LocationApi(configuration);
  const asnaniApi = new AsnaniApi(configuration);

  function generateStringList(objects: ItemDto[]): string[] {
    return objects.map((item) => (item.description == null || item.description === '' || item.description == undefined) ? (item.name || '') : (item.description));
  }
  /***start get options */
  useEffect(() => {
    fetchlocations()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setlocationslist(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
        setlocationslist([]);
      });

  }, []);

  const fetchlocations = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationApi.locationGetList(undefined, 'asnani')
      .then((response) => response.data)
      .then((data) => data.items);
  };

  /***start get options */
  useEffect(() => {
    featchAsnani()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ month,status, id }) => (
          {
            label: `الشهر: ${month} | الحالة: ${status??'لم يرفع'}`,
            value: id,
            disabled:id == null? true:false
          }
        ));
        setasnaniList(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
        setlocationslist([]);
      });

  }, [selectedLocation]);
  const featchAsnani = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return asnaniApi.asnaniGetList(selectedLocation!, 2024)
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  const handleAsnaniChange = (value: string) => {
    setselectedAsnani(value);
  };
  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };


  const onClickExport = () => {
    if (selectedAsnani != null) {

      var report_name = 'تقرير اسناني';
      const url: string = `${Report_URL}/Report/ExportAsnaniExport?report_name=${report_name}&location_id=${selectedLocation ?? ''}&asnani_id=${selectedAsnani ?? ''}&format=pdf&fileName=report`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        // If the new tab was blocked by the browser's popup blocker,
        // you can provide a fallback or display an error message.
        console.error('Failed to open new tab. Please allow popups for this site.');
      }

    } else {
      message.error(t(' الشهر حقل اجباري'));
    }
  };


  const ongroup_by_location = (e: CheckboxChangeEvent) => {
    if (e.target.checked == false) {
      setSelectedLocation(null);
    }
  };
  return (
    <div style={{ width: '100%', height: 700 }}>
      <div style={{ width: '100%' }}>
        <h3>{('تقرير خدمات اسناني')}</h3>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleLocationChange}
            options={locationslist}
            placeholder={t("الكل")}
            style={{ minWidth: '600px' }}
            value={selectedLocation}
            allowClear
          />
<Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleAsnaniChange}
            options={asnaniList}
            placeholder={t("الكل")}
            style={{ minWidth: '600px' }}
            value={selectedAsnani}
            allowClear
          />
          {/* <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            direction={i18n.dir()}
            style={{ minWidth: '300px' }}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
            picker="month"
          /> */}
          <Radio.Group defaultValue={report_type} buttonStyle="solid" >
            <Radio.Button value="simple" onClick={() => { setreport_type("simple"); }}>{t("تقرير مبسط")}</Radio.Button>
            <Radio.Button value="details" onClick={() => { setreport_type("details"); }}>{t("تقرير تفصيلي")}</Radio.Button>
          </Radio.Group>
          {/* <Checkbox value={group_by_month} checked={group_by_month} onChange={onsetgroup_by_month}>{t("التجميع حسب الشهر")}</Checkbox> */}
          <Button key="3" type="primary" onClick={onClickExport}>
            {t("استخراج التقرير")}
          </Button>
        </Space>
      </div>
    </div>

  );

};