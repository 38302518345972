import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../constants/index";
import { Button, Dropdown, MenuProps } from "antd";

export const ChangeLanguage = () => {
  const { i18n, t } = useTranslation();

  const onClick: MenuProps['onClick'] = ({ key }) => {
    i18n.changeLanguage(key);
  };

  const items: MenuProps['items'] = LANGUAGES.map(({ code, label }) => (
    {
      label: label,
      key: code
    }
  ));

  return (<Dropdown menu={{ items, onClick }}>
    <a onClick={(e) => e.preventDefault()}>
      <Button>{t("language")}</Button>
    </a>
  </Dropdown>);
};