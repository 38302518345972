import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch, message } from 'antd';
import { API_URL } from "../../constants";
import { useAuth } from "react-oidc-context";
import { DrawerForm, ProForm, ProFormCheckbox, ProFormDateRangePicker, ProFormDependency, ProFormGroup, ProFormList, ProFormSelect, ProFormText } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";
import { Configuration, GetPermissionListResultDto, PermissionsApi } from "../../api/najd";

interface UsersPermDrawerProps {
  userId?: any; // Prop to receive the rowData
  toggleIsToggled: () => void;
  open:boolean;
  // onClose: () => void;
  // onSubmit: (formData: any) => void;
  // onChange: (formData: any) => void;
}

export const UsersPerm: React.FC<UsersPermDrawerProps> = ({ userId,toggleIsToggled,open }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<GetPermissionListResultDto>({});

  // useEffect(() => {
  //   form.resetFields();
  //   form.setFieldsValue(formData); // Set initial form values when rowData changes
  // }, [form, userId,formData]);
  const handlesetDrawerVisit = () => {
    toggleIsToggled();
  };
  

  const token = auth.user?.access_token;
  const configuration = new Configuration({
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  });
  const permissionsApi = new PermissionsApi(configuration);
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        setFormData(response);
        form.setFieldsValue(response);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, [userId]); // Run this effect only once when the component mounts

  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return permissionsApi.permissionsGet('U',userId)
      .then((response) => response.data)
      .then((data) => data);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      //onSubmit(values);
    });
  };

  return (
    <DrawerForm<{
      name: string;
      company: string;
    }>
      title="العنوان"
      form={form}
      // trigger={open}
      open={open}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
      }}
      submitTimeout={2000}
      // onOpenChange={setDrawerVisit}
      onFinish={async (values) => {
        // await waitTime(2000);
        // console.log(values.name);
        // message.success('提交成功');
        // // 不返回不会关闭弹框
        // setDrawerVisit(false);
        
        handlesetDrawerVisit();
        message.success('نجاح');
        console.log(values);
        return true;
      }}
      initialValues={formData}>
      <ProFormGroup>
        {/* <ProFormText name="entityDisplayName" label="entityDisplayName" /> */}
        <ProFormGroup >
          <ProFormList name={['groups']} creatorButtonProps={false} copyIconProps={false} deleteIconProps={false}>
            <ProFormGroup key="name">
            
            <ProFormText name="name" label="المجموعة" readonly={true} />
            
              <ProFormGroup >
                <ProFormList name={['permissions']} creatorButtonProps={false} copyIconProps={false} deleteIconProps={false}>
                  <ProFormGroup key="name">
                    <ProFormCheckbox name="isGranted" label="القيمة" valuePropName="checked"/>
                    <ProFormText name="name" label="التصنيف" readonly={true}/>
                  </ProFormGroup>
                </ProFormList>
              </ProFormGroup>


            </ProFormGroup>
          </ProFormList>
        </ProFormGroup>
      </ProFormGroup>
    </DrawerForm>
  );
};