import { Dropdown, Menu, MenuProps } from 'antd';
import { ProLayoutProps } from '@ant-design/pro-layout';
import { MenuDataItem, ProLayout } from '@ant-design/pro-components';
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { ChangeLanguage } from './ChangeLanguage';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import { AppstoreOutlined, AreaChartOutlined, CopyOutlined, FileAddOutlined, HomeOutlined, InfoCircleFilled, LogoutOutlined, MinusOutlined, SettingOutlined, SmileFilled } from '@ant-design/icons';
// import ProfileButtom from './ProfileButtom';
import { useNavigate } from 'react-router-dom';
import { CategoryApi, CategoryDto, Configuration, ConfigurationParameters, PartyApi, PartyDto } from '../api/najd';
import { API_URL } from '../constants';

// import styles from './AppLayout.module.css';


const AppLayout: React.FC<ProLayoutProps> = ({ children }) => {
  const [userRole, setUserRole] = useState<string>('');
  const [partyInfo, setPartyInfo] = useState<PartyDto>();
  // const [userSubRole, setuserSubRole] = useState<string>('');
  // const [categories, setCtegories] = useState<string[]>([]);
  const { t } = useTranslation();
  const auth = useAuth();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const partyApi = new PartyApi(configuration);
  // const categoryApi = new CategoryApi(configuration);

  useEffect(() => {
    // setLoading(true);
    if (auth.user?.profile.preferred_username === 'admin') {
      setUserRole('admin');
    } else {

      // const fetchCategoryData = async (ids: string[]) => {
      //   try {
      //     const response = await categoryApi.categoryGetList();
      //     const filteredItems = response.data.items!.filter((item) => ids.includes(item.id!));
      //     setCtegories(filteredItems.map((item) => item.code ?? ''));
      //     // setLoading(false);
      //   } catch (error) {
      //     console.error(error);
      //   }
      // };

      const fetchUserData = async () => {
        try {
          const response = await partyApi.partyGetMy();
          setUserRole(response.data.code ?? '');
          setPartyInfo(response.data);
          // if (response.data.code === 'employee') {
          //   setuserSubRole(response.data.category?.code ?? '');
          // }
          // if (response.data.category_Ids != null) {
          //   fetchCategoryData(response.data.category_Ids!);
          // }
          // setLoading(false);
        } catch (error) {
          console.error(error);
        }
      };
      fetchUserData();

    }

  }, []); // Add refreshTrigger as a dependency

  const navigate = useNavigate();
  const menuData = [
    {
      path: '/',
      name: t('Home'),
      icon: <HomeOutlined style={{ color: '#397551' }} />,
      permission: '',
      key: 'home'
    },
    {
      path: '/documents/request',
      name: t('Requests'),
      icon: <FileAddOutlined style={{ color: '#397551' }} />,
      children: [],
      permission: 'requests',
    },
    {
      path: '/repeat-request',
      name: t('Repeat Requests'),
      icon: <FileAddOutlined style={{ color: '#397551' }} />,
      children: [],
      permission: 'repeat_requests',
    },
    /*{
      path: '/asnani/report_upload',
      name: t('Report Upload'),
      icon: <MinusOutlined style={{ color: '#397551' }} />,
      permission: 'asnani.report_upload',
    },
    {
      path: '/Asnani',
      name: t('Asnani'),
      icon: <CopyOutlined style={{ color: '#397551' }} />,
      permission: 'cards',
      children: [
        {
          path: '/reports/asnani-reports',
          name: t('Reports'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.reports',
        },
        {
          path: '/reports/asnani-gender',
          name: t('أحصائي الجنس'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.reports',
        },
        {
          path: '/reports/asnani-statistic',
          name: t('أحصائي عدد المرضى'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.reports',
        },
        {
          path: '/asnani/locations',
          name: t('Asnani Locations'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.locations',
        },
        {
          path: '/asnani/users/asnani_users',
          name: t('Users'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.users',
        },
        {
          path: '/asnani/refreance/asnani_services',
          name: t('Services'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.services',
        },
        {
          path: '/asnani/refreance/asnani_status',
          name: t('Status'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.status',
        },
        {
          path: '/asnani/patients',
          name: t('Patients'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'asnani.patients',
        },
      ],
    },*/
    {
      path: '/cards',
      name: t('Cards'),
      icon: <CopyOutlined style={{ color: '#397551' }} />,
      permission: 'cards',
      children: [
        {
          path: '/parties/patient',
          name: t('Patients'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'patient',
        },
        {
          path: '/parties/employee',
          name: t('Employees'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'employee',
        },
        {
          path: '/parties/supplier',
          name: t('Suppliers'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'supplier',
        },
        {
          path: '/parties/admin',
          name: t('Administrators'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'admin',
        },
        {
          path: '/others/items',
          name: t('Products'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'items',
        },
      ],
    },
    {
      path: '/others',
      name: t('Configrations'),
      icon: <SettingOutlined style={{ color: '#397551' }} />,
      permission: 'configrations',
      children: [
        {
          path: '/others/subdivisions',
          name: t('Subdivisions'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
        {
          path: '/others/cities',
          name: t('Cities'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
        {
          path: '/locations',
          name: t('Care Centers'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
        {
          path: '/locationGroups',
          name: t('Location Groups'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
        {
          path: '/others/nationalities',
          name: t('Nationalities'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
        {
          path: '/others/categories/item',
          name: t('Categories'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
        {
          path: '/others/categories/document',
          name: t('Requests Types'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
        {
          path: '/others/categories/party',
          name: t('Roles'),
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'configrations',
        },
      ],
    },
    
    {
      path: '/supplier-monitor',
      name: t('Supplier Monitor'),
      icon: <FileAddOutlined style={{ color: '#397551' }} />,
      children: [],
      permission: 'supplier_monitor',
    },
    
    {
      path: '/reports',
      name: t('Reports'),
      icon: <AreaChartOutlined style={{ color: '#397551' }} />,
      permission: 'reports',
      children: [
        {
          path: '/reports/items',
          name: 'أحصائية الصنف',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/ItemsStatics',
          name: 'أحصائي  متقدم',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/ItemsStatics2',
          name: 'أحصائي متقدم (سنوات)',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/patient',
          name: 'المرضى المستلمين لفترة',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/patient-by-year',
          name: 'المرضى المستلمين',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/patient-perc-by-year',
          name: 'تقارير النسب',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/request',
          name: 'إحصائية بعدد الطلبات',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/gender',
          name: 'إحصائي حسب الجنس',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/request-gender',
          name: 'عدد الطلبات حسب الجنس',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
        {
          path: '/reports/request-details',
          name: 'تفصيلي الطلبيات المسلمة',
          icon: <MinusOutlined style={{ color: '#397551' }} />,
          permission: 'reports',
        },
      ],
    }
  ];

  const actionRef = useRef<{
    reload: () => void;
  }>();
  const hasPermission = (permission: string) => {
    if (userRole === 'admin' || userRole == 'readonly') {
      return true;
    }
    if (permission === 'patient' || permission === 'cards') {
      if (userRole === 'stuff' || userRole === 'social'|| userRole === 'dataentry')
        return true;
    }
    if (permission === 'items') {
      if (userRole === 'supplier' && partyInfo?.allowCreatePartyItem == true) {
        return true;
      }
    }
    if (permission === 'items') {
      if (userRole === 'po')
        return true;
    }
    if (permission === 'requests') {
      if (userRole !== 'dataentry'&& userRole !== 'asnani_users')
        return true;
    }
    if (permission === 'repeat_requests') {
      if (userRole === 'social' && partyInfo?.location_Id == '3a0c5d37-5dea-2b25-8a90-314e9c908c9b')
        return true;
    }
    if (permission === 'reports') {
      if (userRole === 'admin')
        return true;
    }
    if (permission === 'supplier_monitor') {
      if (userRole === 'admin')
        return true;
    }
    if (permission === 'asnani.report_upload') {
      if (userRole === 'asnani_users')
        return true;
    }
    
    return false;
  };
  const filterMenuItems = (items: MenuDataItem[]): MenuDataItem[] => {
    return items.map((item) => {
      if (item.children) {
        const children = filterMenuItems(item.children);
        const hasVisibleChildren = children.some((child) => !child.hideInMenu);
        if (hasVisibleChildren) {
          return { ...item, children };
        }
      }
      const hasPermissionToView = !item.permission || (hasPermission(item.permission));
      return hasPermissionToView ? item : { ...item, hideInMenu: true };
    }).filter((item) => !item.hideInMenu);
  };
  return (
    <ProLayout
      style={{
        height: '100vh',
      }}
      // className={styles.customLayout}
      actionRef={actionRef}
      actionsRender={(props) => {
        if (props.isMobile) return [];
        if (typeof window === 'undefined') return [];
        return [
          <ChangeLanguage />,
          // <Button key="1">زر1</Button>,
          // <Button key="2">زر2</Button>,
        ];
      }}
      // title="المؤسسة الخيرية الوطنية"
      title={false}
      // logo="/FullLogo.png"
      logo={<img src="/FullLogo.png" className="customLogo" alt="Logo" />}
      avatarProps={{
        src: 'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
        size: 'small',
        title: auth.user?.profile.preferred_username ?? ' ',
        render: (props, dom) => {

          const handleMenuClick: MenuProps['onClick'] = (e) => {
            auth.signoutRedirect();
          };

          const handleMenuClickInfo: MenuProps['onClick'] = (e) => {
            navigate('/profile');
          };
          const dropdownItems: MenuProps['items'] = [
            {
              label: t("logout"),
              key: '1',
              icon: <LogoutOutlined />,
              onClick: handleMenuClick,
            },
            {
              label: t("My Profile"),
              key: '2',
              icon: <InfoCircleFilled />,
              onClick: handleMenuClickInfo,
            },
          ];

          const menuProps = {
            dropdownItems,
            // onClick: handleMenuClick,
          };

          return (
            <Dropdown
              menu={{
                items: dropdownItems,
              }}
            >
              {dom}
            </Dropdown>
          );

        },
      }}
      location={{
        pathname: '/welcome/welcome',
      }}
      // layout='mix'
      menuDataRender={() => filterMenuItems(menuData)}
      menuItemRender={(item) => (
        <Link to={item.path ?? ''}>{item.icon} {item.name}</Link>
      )}
    >
      {children}
    </ProLayout>
  );
};

export default AppLayout