import { useTranslation } from "react-i18next";

import 'antd/dist/reset.css';
// import MyStatisticCard2 from "./MyStatisticCard2";
import MyStatisticCard3 from "./MyStatisticCard3";
import MyStatisticCard4 from "./MyStatisticCard4";
import MyStatisticCard5 from "./MyStatisticCard5";
import MyStatisticCard2 from "./MyStatisticCard2";
import { Configuration, ConfigurationParameters, PartyApi, PartyDto } from "../../api/najd";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { API_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "antd";
import { MyStatisticCard } from "./MyStatisticCard";
import MyStatisticCard11 from "./MyStatisticCard11";
import MyStatisticCard12 from "./MyStatisticCard12";
import SubdivisionPieChart from "./SubdivisionPieChart";
import GenderPieChart from "./GenderPieChart";
import MyStatisticCard15 from "./MyStatisticCard15";
import { ProCard } from "@ant-design/pro-components";
import MyStatisticCard2Year from "./MyStatisticCard2Year";

interface DashboardProps {
    partyInfo: PartyDto;
}
export const Dashboard: React.FC<DashboardProps> = ({ partyInfo }) => {
    const auth = useAuth();
    const [showAlert, setshowAlert] = useState<boolean>(false);
    const { t } = useTranslation();

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    // const partyApi = new PartyApi(configuration);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (partyInfo.file1 == null || partyInfo.file2 == null || partyInfo.file3 == null || partyInfo.file4 == null) {
                    if (partyInfo.code === 'patient') {
                        setshowAlert(true);

                    }
                }
            } catch (error) {
                console.log('no party found')
            }
        }
        fetchData();
    }, []); // Add refreshTrigger as a dependency

    const navigate = useNavigate();
    const handleMenuClickInfo = () => {
        navigate('/profile');
    };
    const alert = <Alert
        message={t("Alert")}
        showIcon
        description={t('Profile not complate, Access your profile page and uplaod all missing files.')}
        type="error"
        action={
            <Button size="small" danger onClick={handleMenuClickInfo}>
                {t("Press here")}
            </Button>
        }
    />;
    const emptyTag = <></>;
    return (
        <>
            {showAlert == true ? alert : emptyTag}
            {/* <MyStatisticCard partyInfo={partyInfo} /> */}
            <div style={{ width: '100%', height: 320, display: "inline-flex" }}>
                <div style={{ width: '35%', height: 225 }}>
                    <h2 style={{ textAlign: "center" }}></h2>
                    <SubdivisionPieChart />
                </div>
                <div style={{ width: '35%', height: 225 }}>
                    <h2 style={{ textAlign: "center" }}></h2>
                    <GenderPieChart />
                </div>
                <div style={{ width: '35%', height: 225 }}>
                    <h2 style={{ textAlign: "center" }}></h2>
                    <MyStatisticCard15 />
                </div>
            </div>
            <div style={{ width: '100%', height: 320, display: "inline-flex" }}>
                <div style={{ width: '50%', height: 225 }}>
                    <h2 style={{ textAlign: "center" }}></h2>
                    <MyStatisticCard11 />
                </div>
                <div style={{ width: '50%', height: 225 }}>
                    <h2 style={{ textAlign: "center" }}></h2>
                    <MyStatisticCard12 />
                </div>
            </div>
            <div style={{ width: '100%', height: 250, display: "inline-flex" }}>
                <div style={{ width: '50%', height: 225 }}>
                    <MyStatisticCard3 />
                </div>
                <div style={{ width: '50%', height: 225 }}>
                    <MyStatisticCard5 />
                </div>
            </div>
            <div style={{ width: '100%', height: 450, display: "inline-flex" }}>
                <div style={{ width: '100%', height: 400 }}>
                    <h2 style={{ textAlign: "center" }}>عدد الطلبات المسجلة حسب النوع - شهري</h2>
                    <MyStatisticCard2 />
                </div>
            </div>
            <div style={{ width: '100%', height: 450, display: "inline-flex" }}>
                <div style={{ width: '100%', height: 400 }}>
                    <h2 style={{ textAlign: "center" }}>عدد الطلبات المسجلة حسب النوع - سنوي</h2>
                    <MyStatisticCard2Year />
                </div>
            </div>
        </>
    );
};