import { useEffect, useState } from "react";
import { API_URL } from "../../constants";
import MyPieChart from "../../components/PieChart";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportApi } from "../../api/najd";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

export default () => {
  const { t } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const auth = useAuth();
  
  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);


  useEffect(() => {
    getData();
  }, []); // Run this effect only once when the component mounts

  const getData = () => {
    reportApi.reportSubdivisionReport()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };
  
  return (
    <MyPieChart
    data={data}
    title="احصائي المناطق"/>
  );
};