import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import { AsnaniApi, AsnaniDto, AsnaniItemDto, CategoryApi, Configuration, ConfigurationParameters, CreateAsnaniDto, DocumentStatusDetailsReportDto, DocumentSuppliersReportDto, ItemApi, LocationApi, LocationDto, PartyApi, PartyDto, ReportApi, ReportFilterDto, UpdateAsnaniItemDto } from "../../api/najd";
import { API_URL } from "../../constants";
import dayjs from "dayjs";
import { useAuth } from "react-oidc-context";
import { Button, Form, FormInstance, Input, Modal, Popconfirm, Radio, Select, SelectProps, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { getFullDateTime } from "../../helper";
import internal from "stream";
import { CheckCircleOutlined, EditOutlined, WarningOutlined } from "@ant-design/icons";
import { t } from "i18next";


interface ReportUploadItemsProps {
    partyInfo: PartyDto;
    id: string;
}
export const ReportUploadItems: React.FC<ReportUploadItemsProps> = ({ partyInfo, id }) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<AsnaniDto>({});
    const [items, setItems] = useState<AsnaniItemDto[]>([]);
    const [selected_item, set_selected_item] = useState<AsnaniItemDto>({});
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const itemApi = new ItemApi(configuration);
    const locationApi = new LocationApi(configuration);
    const partyApi = new PartyApi(configuration);
    const asnaniApi = new AsnaniApi(configuration);

    // useEffect(() => {

    // }, []);
    useEffect(() => {
        getHeader();
        getDetails();
    }, [id]);

    const getHeader = () => {

        asnaniApi.asnaniGet(id)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    };

    const getDetails = () => {
        asnaniApi.asnaniGetItemList(undefined, undefined, undefined, id)
            .then((response) => {
                setItems(response.data.items ?? []);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    };

    const [open, setOpen] = useState(false);

    const onEdit = (values: AsnaniItemDto) => {
        console.log('Received values of form: ', values);
        // const updateAsnaniItemDto: UpdateAsnaniItemDto = {
        //     fullName:values.fullName,
        //     gender:values.gender,
        //     identifier:values.identifier,
        //     phoneNumber:values.phoneNumber,
        //     serviceStatus:values.serviceStatus,
        //     statusText:values.statusText,
        //     typeText:values.typeText,
        // };
        asnaniApi.asnaniUploadReportItem(values.id!, values)
            .then((response) => {
                setOpen(false);
                getDetails();
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    };
    const columns: ColumnsType<AsnaniItemDto> = [
        {
            title: t('Full Name'),
            dataIndex: 'fullName',
            key: 'fullName',
            render: (_, record) => <span>{record.fullName}</span>,
        },
        {
            title: t('Identifier'),
            dataIndex: 'identifier',
            key: 'identifier',
            render: (_, record) => <span> {record.identifier}</span>
        },
        {
            title: t('Phone Number'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: t('Gender'),
            dataIndex: 'gender',
            key: 'gender',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return (
                    String(record.fullName)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.identifier)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.phoneNumber)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
                );
            },
        },
        {
            title: t('Type'),
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: t('Status'),
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: t('Service Status'),
            dataIndex: 'serviceStatus',
            key: 'serviceStatus',
        },
        {
            title: t('Action'),
            key: 'id',
            width: 100,
            render: (_, record, index) => (
                <Space size="middle">
                    <Button
                        key={'edit-btn-' + record.id}
                        type="primary" onClick={() => {
                            set_selected_item(record);
                            setOpen(true);
                        }}
                    >
                        {t('Edit')}
                    </Button>
                    {
                        record.isValid == true ? <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} /> : <WarningOutlined style={{ fontSize: '16px', color: '#eb2f96' }} />
                    }
                </Space>
            ),
        },
    ];

    return (
        <ProCard>
            <div style={{ width: '100%', height: 600 }}>
                <div style={{ width: '100%' }}>
                    <Input.Search
                        placeholder={t('Search') ?? ''}
                        onSearch={(value) => {
                            setSearchText(value);
                        }}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                    ></Input.Search>
                </div>
                <br />
                <div style={{ width: '100%', height: 500 }}>
                    <Table
                        onRow={(r) => ({
                            onClick: () => console.log('###############', r),
                        })}
                        rowKey={(record) => record.id ?? ''}
                        direction={i18n.dir()} columns={columns} dataSource={items} />
                </div>
            </div>

            <EditItemFormModal
                key={'edit-model-' + selected_item.id}
                open={open}
                onEdit={onEdit}
                onCancel={() => setOpen(false)}
                initialValues={selected_item}
            />
        </ProCard>
    );
};



interface EditItemFormModalProps {
    open: boolean;
    onEdit: (values: AsnaniItemDto) => void;
    onCancel: () => void;
    initialValues: AsnaniItemDto;
}

const EditItemFormModal: React.FC<EditItemFormModalProps> = ({
    open,
    onEdit,
    onCancel,
    initialValues,
}) => {
    const [formInstance, setFormInstance] = useState<FormInstance>();
    console.log('edit => ', initialValues);
    return (
        <Modal
            open={open}
            // title={t('Edit')}
            okText="Save"
            cancelText="Cancel"
            okButtonProps={{ autoFocus: true }}
            onCancel={onCancel}
            destroyOnClose
            onOk={async () => {
                try {
                    const values = await formInstance?.validateFields();
                    formInstance?.resetFields();
                    onEdit(values);
                } catch (error) {
                    console.log('Failed:', error);
                }
            }}
        >
            <EditItemForm
                initialValues={initialValues}
                onFormInstanceReady={(instance) => {
                    setFormInstance(instance);
                }}
            />

            {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
        </Modal>
    );
};


interface EditItemFormFormProps {
    initialValues: AsnaniItemDto;
    onFormInstanceReady: (instance: FormInstance<AsnaniItemDto>) => void;
}

const EditItemForm: React.FC<EditItemFormFormProps> = ({
    initialValues,
    onFormInstanceReady,
}) => {
    const [form] = Form.useForm();
    const [serviceCategoryOptions, set_serviceCategoryOptions] = useState<SelectProps['options']>([]);
    const [statusCategoryOptions, set_statusCategoryOptions] = useState<SelectProps['options']>([]);

    const auth = useAuth();

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);

    const categoryApi = new CategoryApi(configuration);

    useEffect(() => {
        onFormInstanceReady(form);

        fetchOptions('asnani_services')
            .then((response) => {
                var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
                    {
                        label: name,
                        value: name
                    }
                ));
                set_serviceCategoryOptions(mapedOptions);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
        fetchOptions('asnani_status')
            .then((response) => {
                var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
                    {
                        label: name,
                        value: name
                    }
                ));
                set_statusCategoryOptions(mapedOptions);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });

    }, [initialValues.id]);
    const fetchOptions = (cattype: string) => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return categoryApi.categoryGetList(cattype)
            .then((response) => response.data)
            .then((data) => data.items);
    };


    return (
        <Form layout="vertical" form={form} name="form_in_modal" initialValues={initialValues}>
            <Form.Item name="id" label="id" hidden={true}><Input /></Form.Item>
            <Form.Item
                name="fullName"
                label={t("Full Name")}
                rules={[{ required: true, message: 'Please input the fullName of collection!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item name="identifier" label={t("Identifier")}><Input /></Form.Item>
            <Form.Item name="phoneNumber" label={t("Phone Number")}><Input /></Form.Item>
            <Form.Item name="gender" label={t("Gender")} className="collection-create-form_last-form-item">
                <Radio.Group key='gender-key'>
                    <Radio value="ذكر">ذكر</Radio>
                    <Radio value="انثى">انثى</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="typeText" label={t("Type")}>
                <Select
                    options={serviceCategoryOptions} />
            </Form.Item>
            <Form.Item name="statusText" label={t("Status")}>
                <Select
                    options={statusCategoryOptions} />
            </Form.Item>
            <Form.Item name="serviceStatus" label={t("Service Status")}>
                <Radio.Group key='serviceStatus=key'>
                    <Radio value="تم">تم</Radio>
                    <Radio value="لا">لا</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    );
};