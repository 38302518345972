import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch } from 'antd';
import { API_URL } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Configuration, IdentityRoleDto, RoleApi } from "../../api/najd";

interface UserFormDrawerProps {
  formData?: IdentityRoleDto; // Prop to receive the rowData
  editMode: boolean; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}

export const RolesForm: React.FC<UserFormDrawerProps> = ({ formData, onSubmit, onChange, onClose, editMode }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);

  const token = auth.user?.access_token;
  const configuration = new Configuration({
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  });
  const roleApi = new RoleApi(configuration);
  
  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      onSubmit(values);
    });
  };

  return (
    <Form layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={formData}
    >
      <Form.Item name="id" hidden={true}><Input /></Form.Item>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="name"
            label={t("Name")}
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="isDefault" label={t("Default")} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="isPublic" label={t("Public")} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form>);
};