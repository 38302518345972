import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, ConfigProvider, DatePicker, Divider, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch, Upload } from 'antd';
import { CatalogApi, CategoryApi, CityApi, Configuration, CountryApi, DocumentApi, ItemApi, LocationApi, PartyApi, PartyDto } from "../../api/najd";
import { API_URL } from "../../constants";
import locale from 'antd/es/locale/en_US'; // Import the desired locale
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/es/upload";

interface CreateFormDrawerProps {
  type: any;
  partyInfo: PartyDto;
  formData: any; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}
export const DocumentsCreateForm: React.FC<CreateFormDrawerProps> = ({ type, formData, onSubmit, onChange, onClose, partyInfo }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
    console.log('changes => ', formData);
  }, [form, formData]);

  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [documentCategoryOptions, setDocumentCategoryOptions] = useState<SelectProps['options']>([]);
  const [cityOptions, setCityOptions] = useState<SelectProps['options']>([]);
  const [countryOptions, setCountryOptions] = useState<SelectProps['options']>([]);
  const [locationOptions, setLocationOptions] = useState<SelectProps['options']>([]);
  const [itemsOptions, setItemsOptions] = useState<SelectProps['options']>([]);
  const [patientsOptions, setPatientsOptions] = useState<SelectProps['options']>([]);

  const configuration = new Configuration({
    basePath: API_URL,
  });
  const categoryApi = new CategoryApi(configuration);
  const itemApi = new ItemApi(configuration);
  const locationApi = new LocationApi(configuration);
  const partyApi = new PartyApi(configuration);
  const documentApi = new DocumentApi(configuration);
  const countryApi = new CountryApi(configuration);
  const cityApi = new CityApi(configuration);

  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions('party')
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchOptions('document')
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.filter(x=>x.active == true)?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setDocumentCategoryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });


    fetchCities()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setCityOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchCountries()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ fullName, id }) => (
          {
            label: fullName,
            value: id
          }
        ));
        setCountryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchItems()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setItemsOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchPateients()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, lastName, middleName, id }) => (
          {
            label: `${name} ${middleName ?? ''} ${lastName ?? ''}`,
            value: id
          }
        ));
        setPatientsOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

  }, []); // Run this effect only once when the component mounts

  const fetchPateients = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return partyApi.partyGetList('',
      'patient',
      undefined,
      undefined,
      undefined,
      undefined,
      partyInfo.location_Id ?? undefined,
      partyInfo.city_Id ?? undefined,
      partyInfo.subdivision_Id ?? undefined,
      'موافقة',
      undefined)
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchOptions = (cattype: string) => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList(cattype)
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchCities = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return cityApi.cityGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchCountries = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return countryApi.countryGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchItems = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return itemApi.itemGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchLocationOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setLocationOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []); // Run this effect only once when the component mounts

  const fetchLocationOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationApi.locationGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    console.log('=>handleSubmit')
    form.validateFields().then(values => {
      if (values.documentAttachments != null) {
        if (values.documentAttachments.length > 0) {
          for (let index = 0; index < values.documentAttachments.length; index++) {
            // values.documentAttachments[index].append('path', values.documentAttachments[index].file[0].name); // Pass the filename as a separate field
            values.documentAttachments[index].path = values.documentAttachments[index].file[0].name;
          }
        }
      }
      onSubmit(values);
    });
  };

  const hide = (name: string) => {
    return false;
  };

  const beforeUpload = (file: File) => {
    // Generate a timestamp for the file name
    const timestamp = Date.now();
    const newFileName = `${timestamp}${file.name.substring(file.name.lastIndexOf('.'))}`;

    // Modify the file object to use the new file name
    Object.defineProperty(file, 'name', {
      writable: true,
      value: newFileName,
    });

    // Add the timestamp to the form values
    const formValues = {
      file,
      timestamp,
    };

    // Update the form values before submitting
    form.setFieldsValue(formValues);

    return true;
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={formData} >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="code" label={t("Code")} hidden={true}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="partnerParty_Id"
            label={t("Patient")}
            hidden={formData.partnerParty_Id == null ? false : true}
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
              }
              options={patientsOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="category_Id"
            label={t("Request Type")}
            hidden={hide('category_Ids')}
            rules={[{ required: true, message: t('Required field') ?? '' }]}
          >
            <Select options={documentCategoryOptions} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="category_Ids"
            label={t("Requests Types")}
            rules={[{ required: true, message: t('Required field') ?? '', type: 'array' }]}
          >
            <Select mode="multiple"
              options={documentCategoryOptions} />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="remark" label={t("Remark")} hidden={hide('remark')}>
            <Input.TextArea rows={2} placeholder={t("please enter requests details") ?? ''} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={24}>
          {<Divider orientation="left" orientationMargin={1}>
            {t('Attachments')}
          </Divider>}
          <Form.List name="documentAttachments">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'file']}
                          label="File"
                          valuePropName="fileList"
                          getValueFromEvent={(e) => e.fileList}>
                          <Upload name="file" action="https://localhost:44325/FileUpload/upload" beforeUpload={beforeUpload} maxCount={1} method="post">
                            <Button icon={<UploadOutlined />}>Select File</Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item name="path" hidden>
                          <Input type="hidden" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Upload"
                          name={[name, 'type']}
                          rules={[{ required: true, message: 'Missing first item_Id' }]}
                        >
                          <Select placeholder="Please select categories"
                            options={[
                              { value: 'others', label: t('Others') },
                              { value: 'salary-statement', label: t('Salary Statement') },
                              { value: 'rental-contract', label: t('Rental Contract') },
                              { value: 'patient-identity', label: t('Patient Identity') },
                              { value: 'medical-record', label: t('Medical Record') },
                            ]} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('Add file')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="repeatTask"
            label={t("Repeat Request")}
            valuePropName="checked"
            hidden={!((partyInfo.code === 'admin') || (partyInfo.code == 'social' && (partyInfo?.location_Id == '3a0c5d37-5dea-2b25-8a90-314e9c908c9b')))}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="repeatTaskEvery"
            label={t("Repeat Request Every")}
            hidden={!((partyInfo.code === 'admin') || (partyInfo.code == 'social' && (partyInfo?.location_Id == '3a0c5d37-5dea-2b25-8a90-314e9c908c9b')))}>
            <Select options={[
              // { value: 'Daily', label: t('Daily') },
              // { value: 'Weekly', label: t('Weekly') },
              { value: 'Monthly', label: t('Monthly') },
              { value: '2Month', label: t('2Month') },
              { value: '3Month', label: t('3Month') },
            ]} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form >);
};