/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { CategoryDto } from '../../../com/najd/models';
// @ts-ignore
import { CreateCategoryDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfCategoryDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
// @ts-ignore
import { UpdateCategoryDto } from '../../../com/najd/models';
/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCategoryDto} [createCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCreate: async (createCategoryDto?: CreateCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCreateJson: async (values?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories/json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (values !== undefined) {
                localVarQueryParameter['Values'] = values;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryDelete', 'id', id)
            const localVarPath = `/api/categories/{id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryGet', 'id', id)
            const localVarPath = `/api/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [type] 
         * @param {string} [filterKey] 
         * @param {string} [rootId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetList: async (type?: string, filterKey?: string, rootId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (filterKey !== undefined) {
                localVarQueryParameter['FilterKey'] = filterKey;
            }

            if (rootId !== undefined) {
                localVarQueryParameter['Root_Id'] = rootId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDto} [updateCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdate: async (id: string, updateCategoryDto?: UpdateCategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryUpdate', 'id', id)
            const localVarPath = `/api/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdateJson: async (id: string, values?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryUpdateJson', 'id', id)
            const localVarPath = `/api/categories/json/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (values !== undefined) {
                localVarQueryParameter['values'] = values;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCategoryDto} [createCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryCreate(createCategoryDto?: CreateCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryCreate(createCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryCreateJson(values?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryCreateJson(values, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [type] 
         * @param {string} [filterKey] 
         * @param {string} [rootId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryGetList(type?: string, filterKey?: string, rootId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryGetList(type, filterKey, rootId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDto} [updateCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryUpdate(id: string, updateCategoryDto?: UpdateCategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryUpdate(id, updateCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryUpdateJson(id: string, values?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryUpdateJson(id, values, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCategoryDto} [createCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCreate(createCategoryDto?: CreateCategoryDto, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.categoryCreate(createCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCreateJson(values?: string, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.categoryCreateJson(values, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.categoryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGet(id: string, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.categoryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [type] 
         * @param {string} [filterKey] 
         * @param {string} [rootId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetList(type?: string, filterKey?: string, rootId?: string, options?: any): AxiosPromise<ListResultDtoOfCategoryDto> {
            return localVarFp.categoryGetList(type, filterKey, rootId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDto} [updateCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdate(id: string, updateCategoryDto?: UpdateCategoryDto, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.categoryUpdate(id, updateCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdateJson(id: string, values?: string, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.categoryUpdateJson(id, values, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateCategoryDto} [createCategoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryCreate(createCategoryDto?: CreateCategoryDto, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryCreate(createCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [values] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryCreateJson(values?: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryCreateJson(values, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryDelete(id: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryGet(id: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [type] 
     * @param {string} [filterKey] 
     * @param {string} [rootId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryGetList(type?: string, filterKey?: string, rootId?: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryGetList(type, filterKey, rootId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCategoryDto} [updateCategoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryUpdate(id: string, updateCategoryDto?: UpdateCategoryDto, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryUpdate(id, updateCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [values] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryUpdateJson(id: string, values?: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryUpdateJson(id, values, options).then((request) => request(this.axios, this.basePath));
    }
}
