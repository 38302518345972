import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import { Configuration, ConfigurationParameters, DocumentStatusDetailsReportDto, DocumentSuppliersReportDto, ItemApi, LocationApi, LocationDto, PartyApi, PartyDto, ReportApi, ReportFilterDto } from "../../api/najd";
import { API_URL } from "../../constants";
import dayjs from "dayjs";
import { useAuth } from "react-oidc-context";
import { Input, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { getFullDateTime } from "../../helper";
import internal from "stream";


interface SupplierMonitorProps {
    partyInfo: PartyDto;
}
export const SupplierMonitor: React.FC<SupplierMonitorProps> = ({ partyInfo }) => {
    const { t, i18n } = useTranslation();
    const [data_table, setdata_table] = useState<DocumentSuppliersReportDto[]>([]);
    const [locations, setlocations] = useState<LocationDto[]>([]);
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const itemApi = new ItemApi(configuration);
    const locationApi = new LocationApi(configuration);
    const partyApi = new PartyApi(configuration);
    const reportApi = new ReportApi(configuration);

    useEffect(() => {
        locationApi.locationGetList()
            .then((response) => {
                setlocations(response.data.items ?? []);
                getDataTable();
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    }, []);


    const getDataTable = () => {
        const reportFilterDto: ReportFilterDto = {
            // fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
            // toDate: dayjs(selectedDateRange![1]).endOf('month').format('YYYY-MM-DD')
        };
        reportApi.reportDocumentSuppliersReport(
            reportFilterDto
        )
            .then((response) => {
                setdata_table(response.data);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    };
    function calculateDuration(startDate: Date, endDate: Date, location_id: string): any {
        const timeDifference = endDate.getTime() - startDate.getTime();
        const days_allow = getDays(location_id);
        if (days_allow > 0) {
            console.log(location_id);
        }
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let color = days >= days_allow ? 'red' : 'green';
        return <Tag color={color} >
            {` ${days} يوم : ${hours} ساعة`}
        </Tag>;
    }
    function getDays(location_id: string): number {
        const filteredItems = locations.filter(x => x.id === location_id);
        if (filteredItems.length > 0)
            return filteredItems[0].supllierContractDays ?? 0;
        return 0;
    }
    const columns: ColumnsType<DocumentSuppliersReportDto> = [
        {
            title: 'رقم الطلب',
            dataIndex: 'key',
            key: 'key',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'المريض',
            dataIndex: 'patient',
            key: 'patient',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return (
                    String(record.key)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.patient)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.supplier)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
                );
            },
        },
        {
            title: 'المورد',
            dataIndex: 'supplier',
            key: 'supplier',
        },
        {
            title: 'تاريخ فتح الطلب',
            dataIndex: 'requestDate',
            key: 'requestDate', render: ((date: string) => getFullDateTime(date))
        },
        {
            title: 'تاريخ التحويل للمورد',
            dataIndex: 'supplierRecive_Datetime',
            key: 'supplierRecive_Datetime', render: ((date: string) => getFullDateTime(date))
        },
        // {
        //   title: 'تاريخ التعديل',
        //   dataIndex: 'documentStatus_Datetime',
        //   key: 'documentStatus_Datetime', render: ((date: string) => getFullDateTime(date))
        // },
        {
            title: 'مدة التاخير',
            dataIndex: 'supplierRecive_Datetime',
            key: 'duration', render: ((_, record) => calculateDuration(new Date(record.supplierRecive_Datetime ?? ''), new Date(), record.location_Id ?? '')),
        },
        {
            title: 'الحالة',
            dataIndex: 'documentStatus_Name',
            key: 'documentStatus_Name',
        },
        // {
        //     title: 'حالة التسليم',
        //     key: 'status',
        //     dataIndex: 'status',
        //     render: (_, { status }) => {
        //         let color = status !== 'Delivered' ? 'red' : 'green';

        //         return (
        //             <Tag color={color} key={status}>
        //                 {t(status ?? '')}
        //             </Tag>
        //         );
        //     },
        // },
    ];

    return (
        <PageContainer
            header={{
                title: t('Supplier Monitor'),
                ghost: true,
                breadcrumb: {
                    items: [
                        {
                            path: '/',
                            title: t("Cards"),
                        },
                        {
                            path: '/supplier-monitor/',
                            title: t('Supplier Monitor'),
                        },
                    ],
                },
                extra: [
                    // <Button key="3" type="primary" onClick={onClickCreateNew}>
                    //     {t("Add New")}
                    // </Button>,
                ],
            }}
        >
            <ProCard
            >

                <div style={{ width: '100%', height: 1100 }}>
                    <div style={{ width: '100%' }}>

                        {/* <Tabs
             defaultActiveKey='تسجيل جديد'
             type="card"
             items={tab_items}
             onChange={onChangeTab} /> */}
                        <Input.Search
                            placeholder={t('Search') ?? ''}
                            onSearch={(value) => {
                                setSearchText(value);
                            }}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                        ></Input.Search>
                        {/* <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            style={{ minWidth: '300px' }}
            direction={i18n.dir()}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />
        </Space> */}
                    </div>
                    <br />
                    <br />
                    <br />
                    <div style={{ width: '100%', height: 500 }}>
                        <Table
                            direction={i18n.dir()} columns={columns} dataSource={data_table} />
                    </div>
                </div>

            </ProCard>
        </PageContainer>
    );
};
