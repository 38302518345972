import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { DatePicker, Radio, Select, SelectProps, Space } from "antd";
import { ChartStaticsDto, CityApi, CityDto, Configuration, ConfigurationParameters, DocumentApi, ItemApi, LocationApi, LocationDto, LocationGroupApi, ReportApi, ReportFilterDto, SubdivisionApi, SubdivisionDto } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

export default () => {

  const { t } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const [LocationData, setLocationData] = useState<CityDto[]>([]);
  const auth = useAuth();
  const [locationGrouplist, setlocationGrouplist] = useState<SelectProps['options']>([]);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);
  const subdivisionApi = new CityApi(configuration);
  const locationGroupApi = new LocationGroupApi(configuration);

  const [groupBy, setGroupBy] = useState<string>('Subdivision');

  const options: SelectProps['options'] = [];

  const currentYear = new Date().getFullYear();
  const [selectedYears, setselectedYears] = useState<string[]>([currentYear.toString(), (currentYear - 1).toString()]);
  for (let i = 2015; i <= currentYear; i++) {
    options.push({
      label: i.toString(),
      value: i.toString(),
    });
  }
  /***end get options */

  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };

  const [dataGrouped, setdataGrouped] = useState<TargetObject[]>();
  type GroupedDataItem = {
    date: string,
    data: {
      [city: string]: number
    }
  };

  type GroupedData = GroupedDataItem[];
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        setLocationData(response ?? [])
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
      
      fetchlocationGroups()
      .then((response) => {
          var mapedOptions: SelectProps['options'] = response?.map(({ name, code }) => (
            {
              label: name,
              value: code
            }
          ));
          setlocationGrouplist(mapedOptions);
        })
      .catch((error) => {
          console.error('Failed to fetch select options:', error);
          setlocationGrouplist([]);
      });

  }, []); // Run this effect only once when the component mounts

  const fetchlocationGroups = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationGroupApi.locationGroupGetList()
        .then((response) => response.data)
        .then((data) => data.items);
};

  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return subdivisionApi.cityGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  useEffect(() => {
    getData();
  }, [groupBy]); // Run this effect only once when the component mounts

  const getData = () => {

    const reportFilterDto: ReportFilterDto = {
      groupBy: groupBy
    };
    reportApi.reportPatientSalesByCityForYearReport(
      reportFilterDto
    )
      .then((response) => {
        setData(response.data);
        // var dd = groupByDateAndSubdivision(response.data); 
        const targetList = convertList(response.data);
        console.log("==>", targetList);
        setdataGrouped(targetList);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };


  function groupByDateAndSubdivision(records: ChartStaticsDto[]): GroupedData {
    const tempData: {
      [date: string]: {
        [subdivision: string]: number
      }
    } = {};

    for (const record of records) {
      const date = record.date;
      const subdivision = record.name || 'Unknown';  // Fallback to 'Unknown' if subdivision_Name is null or undefined

      if (!tempData[date!]) {
        tempData[date!] = {};
      }

      if (!tempData[date!][subdivision]) {
        tempData[date!][subdivision] = 0;
      }

      tempData[date!][subdivision] += record.value!;
    }

    const groupedData: GroupedData = Object.entries(tempData).map(([date, data]) => ({
      date,
      data
    }));
    // console.log('==>', groupedData);
    // const targetList = convertList(records);
    // console.log(JSON.stringify(targetList, null, 2));
    return groupedData;
  }

  const COLORS = [colorPrimary, colorSecondory, '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F02242', '#075242', '#375242'];

  interface TargetObject {
    [key: string]: number;
  }

  function convertList(sourceList: ChartStaticsDto[]): TargetObject[] {
    const result: TargetObject[] = [];

    sourceList.forEach((item: any) => {
      const existingItem = result.find((targetItem: any) => targetItem.name === getName(item.name));

      if (existingItem) {
        existingItem[item.dateStr] = item.value;
      } else {
        const newItem: TargetObject = {
          name: getName(item.name),
          [item.dateStr]: item.value
        };
        result.push(newItem);
      }
    });

    return result;
  }
  const handleChange = (value: string[]) => {
    setselectedYears(value);
  };
  function getName(name: string): any {
    if (groupBy === 'LocationGroup') {
      const filteredItems = locationGrouplist!.filter(x => x.value === name);
      if(filteredItems.length > 0)
        return filteredItems[0].label;
      return '';
    } else {
      return name;
    }
  }
  return (
    <div style={{ width: '100%', height: 600 }}>
      <div style={{ width: '100%', height: 500 }}>
        <h2 style={{ textAlign: "center" }}> المرضى المستلمين لكل مركز حسب السنة</h2>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={[currentYear.toString(), (currentYear - 1).toString()]}
          onChange={handleChange}
          options={options}
        />

        <Radio.Group defaultValue={groupBy} buttonStyle="solid" >
          <Radio.Button value="Subdivision" onClick={() => { setGroupBy("Subdivision"); }}>{t("Subdivision")}</Radio.Button>
          <Radio.Button value="City" onClick={() => { setGroupBy("City"); }}>{t("City")}</Radio.Button>
          <Radio.Button value="LocationGroup" onClick={() => { setGroupBy("LocationGroup"); }}>{t("Location")}</Radio.Button>
          {/* <Radio.Button value="Location" onClick={() => { setGroupBy("Location"); }}>{t("Location")}</Radio.Button> */}
        </Radio.Group>

        {LocationData.length > 0 ?
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={dataGrouped}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {/* <Bar dataKey={`2022`} fill={COLORS[0]} />
              <Bar dataKey={`2023`} fill={COLORS[1]} /> */}
              {selectedYears.map((entry, index) => (
                <Bar dataKey={`${entry}`} fill={COLORS[index]} />
              ))}
              {/* {LocationData.map((entry, index) => (
              <Bar name={`${entry.name}`} dataKey={`2023`} fill={COLORS[1]} />
              ))} */}
              <Legend />
            </BarChart>
          </ResponsiveContainer>
          : <></>
        }
      </div>
    </div>

  );

};