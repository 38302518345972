import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import MyPieChart from "../../components/PieChart";
import { ChartStaticsDto, Configuration, ConfigurationParameters, ReportApi, ReportFilterDto } from "../../api/najd";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import dayjs from "dayjs";

export default () => {
  const { t } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const auth = useAuth();
  
  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);


  useEffect(() => {
    getData();
  }, []); // Run this effect only once when the component mounts
  
  function getFirstDayOfYear(year: number): Date {
    return new Date(year, 0, 1);
  }

  function getLastDayOfYear(year: number): Date {
    return new Date(year, 11, 31);
  }

  const getData = () => {
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = getFirstDayOfYear(currentYear);
    const lastDayOfYear = getLastDayOfYear(currentYear);
    const reportFilterDto: ReportFilterDto = {
      fromDate:dayjs(firstDayOfYear).format('YYYY-MM-DD'),
      toDate: dayjs(lastDayOfYear).format('YYYY-MM-DD')
    };
    reportApi.reportDocumentStatusReport(
      reportFilterDto
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };
  
  return (
    <MyPieChart
    data={data}
    localize={true}
    title="عدد الطلبة المسجلة مسلمة للسنة الحالية 2024" />
  );
};