import { ProDescriptions } from '@ant-design/pro-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { API_URL, Rresource_Path } from '../../constants';
import { SelectProps } from 'antd';
import { Configuration, ConfigurationParameters, CountryApi, PartyApi, PartyDto } from '../../api/najd';


interface PartyInfoCardProps {
  party_id: any;
}
export const PartyInfoCard: React.FC<PartyInfoCardProps> = ({ party_id }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [party, setParty] = useState<PartyDto>({});

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const partyApi = new PartyApi(new Configuration({ basePath: API_URL, }));
  const countryApi = new CountryApi(new Configuration({ basePath: API_URL, }));
  const [countryOptions, setCountryOptions] = useState<SelectProps['options']>([]);

  useEffect(() => {
    // setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await partyApi.partyGet(party_id);
        setParty(response.data ?? {});
        // setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();

    fetchCountries()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ fullName, id }) => (
          {
            label: fullName,
            value: id
          }
        ));
        setCountryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, [party_id]); // Add refreshTrigger as a dependency

  const fetchCountries = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return countryApi.countryGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const renderFileLink = (filePath: any) => {
    if (filePath != null && filePath != '') {
      return (
        <a href={Rresource_Path + filePath} target="_blank" rel="noopener noreferrer">
          {t('Download File')}
        </a>
      );
    }
    return 'N/A';
  };
  return (
    <ProDescriptions
      // title="dataSource and columns"
      column={4}
      dataSource={party}
      columns={[
        {
          title: t('Name'),
          key: 'name',
          dataIndex: 'name',
          render: (_,record) => (<strong>{record.name} {record.middleName??''} {record.lastName??''}</strong>),
        },
        {
          title: t('Identifier'),
          key: 'identifier',
          dataIndex: 'identifier',
          // ellipsis: true,
          // copyable: true,
        },
        {
          title: t('Birth Date'),
          key: 'birthDate',
          dataIndex: 'birthDate',
          valueType: 'date',
        },
        {
          title: t('Nationality'),
          key: 'country_Id',
          dataIndex: 'country_Id',
          valueType: 'select',
          valueEnum: countryOptions?.reduce((enumObj: any, option: any) => {
            enumObj[option.value] = { text: option.label };
            return enumObj;
          }, {}),
        },
        {
          title: t('Relative'),
          key: 'relative',
          dataIndex: 'relative',
        },
        {
          title: t('Relative Phone Number'),
          key: 'relativePhoneNumber',
          dataIndex: 'relativePhoneNumber',
        },
        {
          title: t('Relative Identifier'),
          key: 'relativeIdentifier',
          dataIndex: 'relativeIdentifier',
          span:2
        },
        {
          title: t('Salary Statement'),
          key: 'file1',
          dataIndex: 'file1',
          render: (fileUrl) => (renderFileLink(fileUrl)),
        },
        {
          title: t('Rental Contract'),
          key: 'file2',
          dataIndex: 'file2',
          render: (fileUrl) => (renderFileLink(fileUrl)),
        },
        {
          title: t('Patient Identity'),
          key: 'file3',
          dataIndex: 'file3',
          render: (fileUrl) => (renderFileLink(fileUrl)),
        },
        {
          title: t('Medical Record'),
          key: 'file4',
          dataIndex: 'file4',
          render: (fileUrl) => (renderFileLink(fileUrl)),
        },
        // {
        //   title: t('Attachments'),
        //   valueType: 'option',
        //   render: () => [
        //     <a target="_blank" rel="noopener noreferrer" key="link">
        //       {t('Salary Statement')}
        //     </a>,
        //     <a target="_blank" rel="noopener noreferrer" key="warning">
        //       报警
        //     </a>,
        //     <a target="_blank" rel="noopener noreferrer" key="view">
        //       查看
        //     </a>,
        //     <a target="_blank" rel="noopener noreferrer" key="view">
        //       查看
        //     </a>,
        //   ],
        // },
      ]}
    >
      {/* <ProDescriptions.Item label="百分比" valueType="percent">
        100
      </ProDescriptions.Item> */}
    </ProDescriptions>
  );
};