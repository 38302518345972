import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch, message } from 'antd';
import { API_URL } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Configuration, ConfigurationParameters, ProfileApi } from "../../api/najd";

interface FormDrawerProps {
}

export const ChangePassword: React.FC<FormDrawerProps> = ({ }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const profileApi = new ProfileApi(configuration);

  useEffect(() => {
    form.resetFields();
  }, [form]);
  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      profileApi.profileChangePassword(values)
      .then(response => {
          // Handle the API response
          message.success(t('Submit Successfully'));
          form.resetFields();
          console.log(response.data);
      })
      .catch(error => {
          // Handle the API error
          console.error(error);
          message.error(error);
      });

    });
  };
  return (
    <Form layout="vertical"
      form={form}
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="currentPassword"
            label={t("Current Password")}
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="newPassword"
            label={t("New Password")} 
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="newPasswordConfirm"
            label={t("New Password Confirm")} 
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};