/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { CreateSubdivisionDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfObjWithChildsDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfSubdivisionDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
// @ts-ignore
import { SubdivisionDto } from '../../../com/najd/models';
/**
 * SubdivisionApi - axios parameter creator
 * @export
 */
export const SubdivisionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSubdivisionDto} [createSubdivisionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionCreate: async (createSubdivisionDto?: CreateSubdivisionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/subdivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubdivisionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/subdivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subdivisionGet', 'id', id)
            const localVarPath = `/api/md/subdivision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionGetList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/subdivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionGetListWithDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/subdivision/get-list-with-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SubdivisionDto} [subdivisionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionUpdate: async (id: string, subdivisionDto?: SubdivisionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subdivisionUpdate', 'id', id)
            const localVarPath = `/api/md/subdivision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subdivisionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubdivisionApi - functional programming interface
 * @export
 */
export const SubdivisionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubdivisionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSubdivisionDto} [createSubdivisionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subdivisionCreate(createSubdivisionDto?: CreateSubdivisionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubdivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subdivisionCreate(createSubdivisionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subdivisionDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subdivisionDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subdivisionGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubdivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subdivisionGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subdivisionGetList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfSubdivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subdivisionGetList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subdivisionGetListWithDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfObjWithChildsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subdivisionGetListWithDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SubdivisionDto} [subdivisionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subdivisionUpdate(id: string, subdivisionDto?: SubdivisionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubdivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subdivisionUpdate(id, subdivisionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubdivisionApi - factory interface
 * @export
 */
export const SubdivisionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubdivisionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSubdivisionDto} [createSubdivisionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionCreate(createSubdivisionDto?: CreateSubdivisionDto, options?: any): AxiosPromise<SubdivisionDto> {
            return localVarFp.subdivisionCreate(createSubdivisionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.subdivisionDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionGet(id: string, options?: any): AxiosPromise<SubdivisionDto> {
            return localVarFp.subdivisionGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionGetList(options?: any): AxiosPromise<ListResultDtoOfSubdivisionDto> {
            return localVarFp.subdivisionGetList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionGetListWithDetails(options?: any): AxiosPromise<ListResultDtoOfObjWithChildsDto> {
            return localVarFp.subdivisionGetListWithDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SubdivisionDto} [subdivisionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisionUpdate(id: string, subdivisionDto?: SubdivisionDto, options?: any): AxiosPromise<SubdivisionDto> {
            return localVarFp.subdivisionUpdate(id, subdivisionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubdivisionApi - object-oriented interface
 * @export
 * @class SubdivisionApi
 * @extends {BaseAPI}
 */
export class SubdivisionApi extends BaseAPI {
    /**
     * 
     * @param {CreateSubdivisionDto} [createSubdivisionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdivisionApi
     */
    public subdivisionCreate(createSubdivisionDto?: CreateSubdivisionDto, options?: AxiosRequestConfig) {
        return SubdivisionApiFp(this.configuration).subdivisionCreate(createSubdivisionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdivisionApi
     */
    public subdivisionDelete(id?: string, options?: AxiosRequestConfig) {
        return SubdivisionApiFp(this.configuration).subdivisionDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdivisionApi
     */
    public subdivisionGet(id: string, options?: AxiosRequestConfig) {
        return SubdivisionApiFp(this.configuration).subdivisionGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdivisionApi
     */
    public subdivisionGetList(options?: AxiosRequestConfig) {
        return SubdivisionApiFp(this.configuration).subdivisionGetList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdivisionApi
     */
    public subdivisionGetListWithDetails(options?: AxiosRequestConfig) {
        return SubdivisionApiFp(this.configuration).subdivisionGetListWithDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SubdivisionDto} [subdivisionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdivisionApi
     */
    public subdivisionUpdate(id: string, subdivisionDto?: SubdivisionDto, options?: AxiosRequestConfig) {
        return SubdivisionApiFp(this.configuration).subdivisionUpdate(id, subdivisionDto, options).then((request) => request(this.axios, this.basePath));
    }
}
