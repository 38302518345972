import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { DatePicker, Space } from "antd";
import { ChartStaticsDto, Configuration, ConfigurationParameters, DocumentApi, ItemApi, PartyApi, PartyDto, ReportApi, ReportFilterDto, } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import MyPieChart from "../../components/PieChart";
const { RangePicker } = DatePicker;

export default () => {

  const { t,i18n } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const auth = useAuth();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);

  /***end get options */

  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };

  useEffect(() => {
    if (selectedDateRange != null) {
      getData();
    }
  }, [selectedDateRange]); // Run this effect only once when the component mounts

  const getData = () => {
    const reportFilterDto: ReportFilterDto = {
      fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
      // toDate: dayjs(selectedDateRange![1]).endOf('month').format('YYYY-MM-DD')
      toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD'),
      dateRangeBy:'status_time'
    };
    reportApi.reportPatientSalesByGender(
      reportFilterDto
    )
      .then((response) => {
        setData(response.data ?? []);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };

  const COLORS = [colorPrimary, colorSecondory, '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F02242', '#075242', '#375242'];

  return (
    <div style={{ width: '100%', height: 700 }}>
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            direction={i18n.dir()}
            style={{ minWidth: '300px' }}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />
        </Space>
      </div>
      <div style={{ width: '100%', height: 600 }}>
        <h2 style={{ textAlign: "center" }}>عدد الطلبات حسب الجنس</h2>

        <MyPieChart
          data={data}
          localize={true} />
      </div>
    </div>

  );

};