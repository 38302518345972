import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from "recharts";
import { API_URL, colorPrimary, colorSecondory } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Checkbox, DatePicker, Space, Table, Tag } from "antd";
import { ChartStaticsDto, Configuration, ConfigurationParameters, DocumentApi, DocumentStatusDetailsReportDto, ItemApi, PartyApi, PartyDto, ReportApi, ReportFilterDto } from "../../api/najd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ColumnsType } from "antd/lib/table/interface";
import MyPieChart from "../../components/PieChart";
import { getFullDateTime } from "../../helper";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
const { RangePicker } = DatePicker;

export default () => {

  const { t, i18n } = useTranslation();
  const [data, setData] = useState<ChartStaticsDto[]>([]);
  const [data_table, setdata_table] = useState<DocumentStatusDetailsReportDto[]>([]);
  const auth = useAuth();
  const [showOnlyActiveItem, setShowOnlyActiveItem] = useState<boolean>(false);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const reportApi = new ReportApi(configuration);

  /***end get options */

  const columns: ColumnsType<DocumentStatusDetailsReportDto> = [
    {
      title: 'رقم الطلب',
      dataIndex: 'key',
      key: 'key',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'المريض',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'المورد',
      dataIndex: 'supplier',
      key: 'supplier',
    },
    {
      title: 'تاريخ فتح الطلب',
      dataIndex: 'requestDate',
      key: 'requestDate', render: ((date: string) => getFullDateTime(date))
    },
    {
      title: 'تاريخ اخر تعديل',
      dataIndex: 'requestUpdateDate',
      key: 'requestUpdateDate', render: ((date: string) => getFullDateTime(date))
    },
    {
      title: 'الحالة',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'حالة التسليم',
      key: 'statusCode',
      dataIndex: 'statusCode',
      render: (_, { statusCode }) => {
        let color = statusCode !== 'Delivered' ? 'red' : 'green';

        return (
          <Tag color={color} key={statusCode}>
            {t(statusCode ?? '')}
          </Tag>
        );
      },
    },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | null>(null);

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings);
  };

  useEffect(() => {
    if (selectedDateRange != null) {
      getData();
      getDataTable();
    }
  }, [selectedDateRange, showOnlyActiveItem]); // Run this effect only once when the component mounts

  const getData = () => {
    const reportFilterDto: ReportFilterDto = {
      fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
      toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD')
    };
    reportApi.reportDocumentStatusReport(
      reportFilterDto
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };
  const getDataTable = () => {
    const reportFilterDto: ReportFilterDto = {
      fromDate: dayjs(selectedDateRange![0]).format('YYYY-MM-DD'),
      toDate: dayjs(selectedDateRange![1]).format('YYYY-MM-DD')
    };
    reportApi.reportDocumentStatusDetailsReport(
      reportFilterDto
    )
      .then((response) => {
        setdata_table(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  };

  const onChangeShowOnlyActiveItem = (e: CheckboxChangeEvent) => {
    setShowOnlyActiveItem(e.target.checked);
  };
  return (
    <div style={{ width: '100%', height: 1100 }}>
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={5}>{t('Date Range')}</Title>
          <RangePicker
            style={{ minWidth: '300px' }}
            direction={i18n.dir()}
            // picker="month"
            // value={[dayjs('2023-01-01', 'YYYY-MM-DD'), dayjs('2023-12-31', 'YYYY-MM-DD')]}
            placeholder={[t("From Date"), t("To Date")]}
            onChange={handleDateRangeChange}
          />
          {/* <Checkbox value={showOnlyActiveItem} checked={showOnlyActiveItem} onChange={onChangeShowOnlyActiveItem}>{t("عرض الموقوف و المرفوض")}</Checkbox> */}

        </Space>
      </div>
      <div style={{ width: '100%', height: 300 }}>
        <h2 style={{ textAlign: "center" }}>تفصيلي حالة الطلبات المسجلة</h2>
        <MyPieChart
          localize={true}
          data={data} />
      </div>
      <br />
      <br />
      <br />
      <div style={{ width: '100%', height: 500 }}>
        <Table
          direction={i18n.dir()} columns={columns} dataSource={data_table} />
      </div>
    </div>
  );

};