import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Table, Tag } from 'antd';
import type { ColumnsType,} from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getFullDateTime } from "../../helper";
import { API_URL } from "../../constants";
import { Configuration, PartyDto } from "../../api/najd";
import { CityApi, CityDto } from "../../api/najd";

interface DataGridProps {
    partyInfo: PartyDto;
    onClickRowEdit: (rowData: any) => void;
    refreshTrigger: boolean;
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const CitiesTable: React.FC<DataGridProps> = ({ onClickRowEdit, refreshTrigger, setRefreshTrigger,partyInfo }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<CityDto[]>([]);
    const [loading, setLoading] = useState(false);

    const cityApi = new CityApi(new Configuration({basePath: API_URL,}));
    
    const columns: ColumnsType<CityDto> = [
        {
            title: t('Code'),dataIndex: 'code',key: 'code',
            width: 150,
        },
        {
            title: t('Subdivision'),dataIndex: 'subdivision_Id',key: 'subdivision',
            width: 150,
            render: (_, record) => <span>{record.subdivision !== null ? record.subdivision?.name : ''}</span>,
        },
        {
            title: t('City'),dataIndex: 'name',key: 'name',
        },
        {
            title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date)),
            width: 150,
        },
        {
            title: t('Action'),
            key: 'action',
            width: 150,
            render: (_, record) => (
                (partyInfo.code === 'readonly') ? <></> :<Space size="middle">
                    <Button type="primary" icon={<EditOutlined />} title={t('Edit')??''} onClick={() => { handleRowClick(record) }} />
                    <Popconfirm
                        title={t("Are you sure you want to delete this item?")}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" title={t('Delete')??''} icon={<DeleteOutlined />} danger
                            key={'con-btn-' + record.id} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    // Use the onClickRowEdit function as needed
    const handleRowClick = (rowData: any) => {
        onClickRowEdit(rowData);
    };

    const handleDelete = (id: any) => {
        console.log('Clicked cancel button');
        cityApi.cityDelete(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };
    
    useEffect(() => {
        setLoading(true);
        const fetchUserData = async () => {
            try {
                const response = await cityApi.cityGetList();
                setUserData(response.data.items ?? []);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchUserData();
    }, [refreshTrigger]); // Add refreshTrigger as a dependency

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };

    return (<>
        {/* <button onClick={handleRefresh}>Refresh Data</button> */}
        <Table<CityDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={userData}
            columns={columns}
            loading={loading}
        />
    </>
    );
};

export default CitiesTable;