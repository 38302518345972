import { DatePicker, Form, FormInstance, Input, Modal, Select, SelectProps } from "antd";
import { Configuration, ConfigurationParameters, DocumentApi, UpdateDocumentStatusDto } from "../../api/najd";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { API_URL } from "../../constants";
import { t } from "i18next";


interface EditDocumentStatusFormModalProps {
    open: boolean;
    onEdit: (values: UpdateDocumentStatusDto) => void;
    onCancel: () => void;
    initialValues: UpdateDocumentStatusDto | null;
}

const EditDocumentStatusFormModal: React.FC<EditDocumentStatusFormModalProps> = ({
    open,
    onEdit,
    onCancel,
    initialValues,
}) => {
    const [formInstance, setFormInstance] = useState<FormInstance>();
    console.log('edit => ', initialValues);
    return (
        <Modal
            open={open}
            // title={t('Edit')}
            okText="Save"
            cancelText="Cancel"
            okButtonProps={{ autoFocus: true }}
            onCancel={onCancel}
            destroyOnClose
            onOk={async () => {
                try {
                    const values = await formInstance?.validateFields();
                    formInstance?.resetFields();
                    onEdit(values);
                } catch (error) {
                    console.log('Failed:', error);
                }
            }}
        >
            <EditDocumentStatusForm
                initialValues={initialValues ?? {}}
                onFormInstanceReady={(instance) => {
                    setFormInstance(instance);
                }}
            />

            {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
        </Modal>
    );
};
export default EditDocumentStatusFormModal;

interface EditDocumentStatusFormProps {
    initialValues: UpdateDocumentStatusDto;
    onFormInstanceReady: (instance: FormInstance<UpdateDocumentStatusDto>) => void;
}

const EditDocumentStatusForm: React.FC<EditDocumentStatusFormProps> = ({
    initialValues,
    onFormInstanceReady,
}) => {
    const [form] = Form.useForm();
    const [statusesOptions, set_statusesOptions] = useState<SelectProps['options']>([]);

    const auth = useAuth();

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);

    const documentApi = new DocumentApi(configuration);

    useEffect(() => {
        onFormInstanceReady(form);

        fetchOptions()
            .then((response) => {
                var mapedOptions: SelectProps['options'] = response?.map(({ name, type, id }) => (
                    {
                        label: `العملية: ${t(type ?? '')} | الحالة : ${name}`,
                        value: id,
                    }
                ));
                set_statusesOptions(mapedOptions);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });
    }, [initialValues.document_Id, initialValues.documentStatus_Id, initialValues.comment]);
    const fetchOptions = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return documentApi.documentGetStatusList()
            .then((response) => response.data)
            .then((data) => data.items);
    };


    return (
        <Form layout="vertical" form={form} name="form_in_modal" initialValues={initialValues}>
            <Form.Item name="document_Id" label="document_Id" hidden={true}><Input /></Form.Item>
            <Form.Item
                name="documentStatus_Id"
                label={t("Status")}
                rules={[{ required: true, message: t('Required field') ?? '' }]}
            >
                <Select
                    options={statusesOptions} />
            </Form.Item>
            <Form.Item name="comment" label={t("Comment")}
                rules={[{ required: true, message: t('Required field') ?? '' }]}><Input /></Form.Item>
            <Form.Item
                name="stateDateTime"
                label={t("Date")}
                rules={[{ required: true, message: t('Required field') ?? '' }]}
            >
                <DatePicker format={"YYYY-MM-DD HH:mm:ss"} showTime />
            </Form.Item>
        </Form>
    );
};