import { useTranslation } from "react-i18next";
import React, { useEffect } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';

interface FormDrawerProps {
  formData: any; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}

export const CategoryForm: React.FC<FormDrawerProps> = ({ formData, onSubmit, onChange, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  useEffect(() => {
    form.setFieldsValue(formData); // Set initial form values when rowData changes
    console.log('changes => ', formData);
  }, [form, formData]);


  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      onSubmit(values);
    });
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    onChange(allValues);
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={formData} onValuesChange={handleValuesChange} >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="id" label="ID" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="code"
            label={t("Code")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label={t("Name")} rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="shortDescription" label={t("Short Description")}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="lineOrder" label={t("Line Order")}>
            <InputNumber/>
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="type" label={t("Type")} hidden={true}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="active" label={t("Active")} valuePropName="checked" hidden={(formData.id == null) ? true : false}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form>);
};